import "core-js/features/string/includes";
import "core-js/features/string/starts-with";
import "core-js/features/string/repeat";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/array/some";
import "core-js/features/object/values";
import "core-js/features/object/get-own-property-descriptors";
import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";

import {
  UserProvider,
  ApiProvider,
  MessagesProvider,
} from "./components/Context";
import Root from "./screens/Root";

import "semantic-ui-css/semantic.min.css";

ReactDOM.render(
  <UserProvider>
    <ApiProvider>
      <MessagesProvider>
        <Root />
      </MessagesProvider>
    </ApiProvider>
  </UserProvider>,
  document.getElementById("root")
);
