import React, { Component } from "react";
import { List } from "semantic-ui-react";

class HogarReale extends Component {
  render() {
    return (
      <div>
        <strong>PRODUCTO DESCENTRALIZADO</strong>: Multirriesgo Hogar 817.
        <br />
        <br />
        <strong>OPERATIVA DE CONTRATACIÓN</strong>: On-line, a través de la
        página web de la compañía. Solo vivienda principal o vivienda secundario
        habilitada mas de 6 meses al año siempre que la vivienda principal este
        asegurada con Reale.
        <br />
        <br />
        <strong>IMPORTANTE</strong>:
        <List bulleted>
          <List.Item>
            Hay que marcar la opción Todo Riesgo Accidental y daños estéticos
            del contenido.
          </List.Item>
          <List.Item>
            NO marcar ni filtraciones de lluvia ni la opción de lluvia con
            reparación ya viene por defecto en el condicionado especial de
            Espabrok de mejora de garantías.
          </List.Item>
        </List>
      </div>
    );
  }
}

export default HogarReale;
