import React, { Component } from "react";
import { List } from "semantic-ui-react";

class ComercioLiberty extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>Acuerdo descentralizado</strong>.
        </List.Item>
        <List.Item>
          <strong>Mejoras</strong>: En el producto de comercio espabrok tiene
          mejoras sustanciales con respecto al producto de la compañía, os
          animamos a que veías el pdf donde se reflejan las mismas.
        </List.Item>
      </List>
    );
  }
}

export default ComercioLiberty;
