import React, { Component } from "react";

class Seguropordias extends Component {
  render() {
    return (
      <div>
        <strong>EMISIÓN</strong>: Producto descentralizado.
        <br />
        <br />
        <strong>CONTRATACIÓN</strong>:
        <br />
        <br />
        <p>
          Para poder gestionar el alta, es preciso que os registréis a través
          del siguiente enlace:{" "}
          <a
            href="https://seguropordias.com/alta-colaborador"
            title="Alta en Seguro Por Días"
            rel="external"
          >
            https://seguropordias.com/alta-colaborador
          </a>
          .
        </p>
        <p>
          Es importante rellenar los campos correcta y completamente, puesto que
          se emplearán para la elaboración del contrato. Tened en cuenta las
          siguientes consideraciones al rellenar el formulario:
        </p>
        <ul>
          <li>Seleccionad en el listado que pertenecéis a Espabrok.</li>
          <li>
            El primer correo que se indique será el que se emplee como usuario.
          </li>
          <li>La dirección que se solicita es la fiscal.</li>
          <li>
            El administrador que solicitan es el de la empresa. Si queréis
            indicar una persona de contacto hacedlo por mail para que tomen nota
            internamente.
          </li>
        </ul>
        <p>
          Una vez cumplimentados los datos, se os solicitará adjuntar
          documentación.
        </p>
        <ul>
          <li>Autorización DGS</li>
          <li>CIF de la empresa</li>
          <li>DNI del administrador por ambas caras.</li>
          <li>Certificado cuenta bancaria para el abono de comisiones</li>
        </ul>
        <p>
          En caso de que la subida de documentación por la web de error, podéis
          remitir la misma a la direccion{" "}
          <a href="mailto:elvira.talasac@nibw.es" title="Dirección de contacto">
            elvira.talasac@nibw.es
          </a>
          .
        </p>
        <p>
          Cuando hayan comprobado que todo es correcto, os remitirán un correo
          informando de que han procedido al envío del contrato para su firma
          digital. Una vez firmado, tramitarán el alta y os confirmarán que ya
          podéis contratar con un correo de bienvenida.
        </p>
        <br />
        <strong>COMISIÓN</strong>: 11%.
      </div>
    );
  }
}

export default Seguropordias;
