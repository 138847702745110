import React, { PureComponent, createContext } from "react";
import axios from "axios";

import { APIBASEURL } from "../../globals";

export const UserContext = createContext();

export class UserProvider extends PureComponent {
  state = {
    userStatus: "checking",
    userId: null,
    userEmail: null,
    userName: null,
    userPosition: null,
    userFlags: [],
    userIsAdminFlag: false,
    userIsActive: false,
    slackToken: null,
    userTarifUser: null,
    userSegEleviaLink: null,
    userAvantLink: null,
    setUserInfo: (responseData) => {
      const {
        user: {
          id: userId,
          email: userEmail,
          name: userName,
          position: userPosition,
          flags: userFlags,
          admin: userIsAdminFlag,
          active: userIsActive,
          passwords: userPasswords,
          tarif_user: userTarifUser,
          seg_elevia: userSegEleviaLink,
          avant: userAvantLink,
        },
      } = responseData;

      const slackAuth = userPasswords.find((v) => v.app === "Slack");

      this.setState({
        userStatus: "logged-in",
        userId,
        userEmail,
        userName,
        userPosition,
        userFlags,
        userIsAdminFlag,
        userIsActive,
        slackToken: slackAuth && slackAuth.username ? slackAuth.username : null,
        userTarifUser,
        userSegEleviaLink,
        userAvantLink,
      });
    },
    updateUserInfo: (userData) => {
      this.setState({ ...userData });
    },
    checkUserStatus: () => {
      axios({
        method: "GET",
        url: `${APIBASEURL}/logged`,
        responseType: "json",
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data.logged) {
            this.state.setUserInfo(response.data);
          } else {
            this.setUserLoggedOut();
          }
        })
        .catch((error) => {
          console.error(error);
          this.setUserLoggedOut();
        });
    },
    userIsAdmin: () => {
      return this.state.userIsAdminFlag;
    },
    logoutUser: () => {
      axios({
        method: "GET",
        url: `${APIBASEURL}/logout`,
        responseType: "json",
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.setUserLoggedOut();
        })
        .catch((error) => {
          console.error(error);
          this.setUserLoggedOut();
        });
    },
  };

  setUserLoggedOut = () => {
    this.setState({
      userStatus: "logged-out",
      userId: null,
      userEmail: null,
      userName: null,
      userPosition: null,
      userFlags: [],
      userIsAdminFlag: false,
      userIsActive: false,
      slackToken: null,
      userTarifUser: null,
      userSegEleviaLink: null,
      userAvantLink: null,
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;

export function withUserContext(Component) {
  return function WithUserContextComponent(props) {
    return (
      <UserContext.Consumer>
        {(user) => <Component {...props} user={user} />}
      </UserContext.Consumer>
    );
  };
}
