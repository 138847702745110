import React, { Component } from "react";
import { List } from "semantic-ui-react";

class SaludGenerali extends Component {
  render() {
    return (
      <div>
        <strong>PRODUCTO CENTRALIZADO</strong>:
        <p>
          Colectivo cerrado, clientes con procedencia de cualquier otra compañía
          de salud. Obligatorio aportar procedencia a la emisión.
        </p>
        <p>Generali Salud Opción Premium con Dental incluido.</p>
        <List bulleted>
          <List.Item>
            Ver en los documentos información sobre las primas.
          </List.Item>
        </List>
        <br />
        <br />
        <strong>CONDICIONES DE CONTRATACIÓN</strong>
        <List bulleted>
          <List.Item>
            Solo se admiten personas que aporten procedencia de otra compañía.
          </List.Item>
          <List.Item>Edad máxima de contratación: 64 años.</List.Item>
          <List.Item>No se admiten menores solos.</List.Item>
        </List>
        <strong>OPERATIVA DE CONTRATACIÓN</strong>: Remitir a Pedro Torres{" "}
        <a href="mailto:salud@espabrok.es">salud@espabrok.es</a> los siguientes
        documentos:
        <List bulleted>
          <List.Item>
            Solicitud y cuestionario médico por ambas caras.
          </List.Item>
          <List.Item>Fotocopia DNI.</List.Item>
          <List.Item>
            Para justificar procedencia: último recibo y tarjeta de la compañía
            actual.
          </List.Item>
          <List.Item>IGP firmado por el cliente</List.Item>
        </List>
        <br />
        <strong>IMPORTANTE:</strong>
        <p>
          Rellenar todos los campos de la toma de datos, siendo muy importante{" "}
          <u>el teléfono móvil y email</u>.
        </p>
        <p>Marcar con una X el consentimiento antes de la firma.</p>
        <p>
          El cuestionario se la hará llegar al cliente vía email para que
          continúe con la gestión.
        </p>
      </div>
    );
  }
}

export default SaludGenerali;
