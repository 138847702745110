import React, { Component, lazy, Suspense } from "react";
import { Router, Location } from "@reach/router";
import ReactGA from "react-ga";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

import { withUserContext } from "../components/Context";
import Messages from "../components/Messages";
import HeaderLogo from "../components/HeaderLogo";
import HeaderSearcher from "../components/HeaderSearcher";
import HeaderMenu from "../components/HeaderMenu";
import Footer from "../components/Footer";
import ScreensLogin from "./Login";
import ScreensDashboard from "./Dashboard";
import GlobalStyle from "../components/styled/GlobalStyle";
import { Container, FlexRow } from "../components/styled";

import { extractUrlParameters } from "../utils";

// Code Splitting.
const SectionLoader = () => (
  <Loader active inline="centered" style={{ marginTop: "3rem" }} />
);
const ScreensProfile = lazy(() => import("./Profile"));
const EventSummary = lazy(() => import("../components/Event/UserView"));
const ScreensBrokers = lazy(() => import("./Broker"));
const ScreensUsers = lazy(() => import("./User"));
const ScreensEvents = lazy(() => import("./Event"));

// Local CSS Styles.
const Header = styled.header`
  background: #ffffff;
  color: #333333;
  font-size: 1.1rem;
  padding: 1rem 0;
  @media (min-width: 768px) {
    padding: 0.4rem 0;
  }
`;

// Initialize Google Analytics.
ReactGA.initialize("UA-51615852-2");
const setGAUrlParameters = (gaHash) => {
  if (!gaHash) return;
  const { utm_campaign, utm_medium, utm_source } = extractUrlParameters(gaHash);
  ReactGA.set({
    ...(utm_campaign ? { campaignName: utm_campaign } : {}),
    ...(utm_medium ? { campaignMedium: utm_medium } : {}),
    ...(utm_source ? { campaignSource: utm_source } : {}),
  });
};

class Root extends Component {
  componentDidMount() {
    this.props.user.checkUserStatus();
  }

  render() {
    const { userStatus, userIsAdmin } = this.props.user;

    if (userStatus === "checking") return null;
    if (userStatus !== "logged-in")
      return (
        <Location>
          {({ location }) => {
            const { hash } = location;
            // Google Analytics.
            setGAUrlParameters(hash);
            ReactGA.pageview("/login");
            return (
              <>
                <GlobalStyle />
                <ScreensLogin location={location} />
              </>
            );
          }}
        </Location>
      );

    return (
      <Location>
        {({ location: { pathname, hash } }) => {
          // Google Analytics.
          setGAUrlParameters(hash);
          ReactGA.pageview(pathname);

          return (
            <>
              <GlobalStyle />
              <Header>
                <Container>
                  <FlexRow justifyContent="space-between">
                    <HeaderLogo />
                    <HeaderSearcher />
                    <HeaderMenu />
                  </FlexRow>
                </Container>
              </Header>
              <main>
                <Container style={{ padding: "1rem 0 4rem 0" }}>
                  <Suspense fallback={<SectionLoader />}>
                    <Router primary={false}>
                      <ScreensProfile path="/perfil/*" />
                      <EventSummary path="/evento/:id" />
                      <ScreensBrokers path="/corredurias/*" />
                      <ScreensUsers path="/usuarios/*" />
                      {userIsAdmin() && <ScreensEvents path="/eventos/*" />}
                      <ScreensDashboard default />
                    </Router>
                  </Suspense>
                </Container>
              </main>
              <Footer />
              <Messages />
            </>
          );
        }}
      </Location>
    );
  }
}

export default withUserContext(Root);
