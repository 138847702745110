import React, { Component } from 'react'
import { List, Table } from 'semantic-ui-react'

class VidaSurne extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO EXCLUSIVO ESPABROK</strong>, con las siguientes
          características:
          <List.List>
            <List.Item>
              CONDICIONADO PROPIO (se incluyen la garantía de parciales de
              accidentes, anticipo por detección cáncer de mama para mujeres,
              posibilidad de testamento on line y gran invalidez plus por
              accidente).
            </List.Item>
            <List.Item>
              COMISIONAMIENTO MEJORADO: 
              <strong>40% Nueva Producción y 40% Cartera</strong>.
            </List.Item>
            <List.Item>POSIBILIDAD DE TELESELECCIÓN MÉDICA.</List.Item>
            <List.Item>
              POSIBILIDAD CONTRATACIÓN DE IPT (Invalidez Profesional) Para
              Actividades Grupo 1 (Capital Máximo De 100.000 Euros). Para resto
              de actividades tb. Se da la posibilidad de contratación con un
              capital máximo para la IPT del 50% de fallecimiento con máximo de
              60.000 euros.
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          <strong>REQUISITOS MÉDICOS CONTRATACIÓN</strong>.
        </List.Item>
        <List.Item>
          <strong>REQUISITOS FINANCIEROS CONTRATACIÓN</strong>: A PARTIR DE
          300.000 EUROS CUESTIONARIO FINANCIERO.
          <Table
            unstackable
            compact
            celled
            structured
            style={{ margin: '1rem 0' }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Suma asegurada en €</Table.HeaderCell>
                <Table.HeaderCell>Edad</Table.HeaderCell>
                <Table.HeaderCell>Pruebas a realizar</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell rowSpan="2">De 1 a 100.000 €</Table.Cell>
                <Table.Cell>Hasta 60 años</Table.Cell>
                <Table.Cell>C.B.S. (“T”)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>> 60 años</Table.Cell>
                <Table.Cell>Rechazo</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell rowSpan="3">De 100.001 a 200.000 €</Table.Cell>
                <Table.Cell>Hasta 55 años</Table.Cell>
                <Table.Cell>C.E.S. (“T”)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>De 55 a 60 años</Table.Cell>
                <Table.Cell>C.E.S. (“T”) + Lab (A)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>> 60 años</Table.Cell>
                <Table.Cell>Rechazo</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell rowSpan="3">De 200.001 a 300.000 €</Table.Cell>
                <Table.Cell>Hasta 55 años</Table.Cell>
                <Table.Cell>C.E.S. (“T”) + Lab (A)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>De 55 a 60 años</Table.Cell>
                <Table.Cell>C.E.S. (“T”) + Lab (A) + (B)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>> 60 años</Table.Cell>
                <Table.Cell>Rechazo</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell rowSpan="3">De 300.001 a 500.000 €</Table.Cell>
                <Table.Cell>Hasta 55 años</Table.Cell>
                <Table.Cell>C.E.S. (“T”) + Lab (A) + (B)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>De 55 a 60 años</Table.Cell>
                <Table.Cell>C.E.S. (“T”) + Lab (A) + (B) + IMC/TA</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>> 60 años</Table.Cell>
                <Table.Cell>Rechazo</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell rowSpan="3">De 500.001 a 1.000.000 €</Table.Cell>
                <Table.Cell>Hasta 49 años</Table.Cell>
                <Table.Cell>CARDIO* + EM + Lab (A) + (B)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>De 50 a 60 años</Table.Cell>
                <Table.Cell>EM + Lab (A) + (B) + Ergometría</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>> 60 años</Table.Cell>
                <Table.Cell>Rechazo</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell rowSpan="2">Más de 1.000.000 €</Table.Cell>
                <Table.Cell>Hasta 60 años</Table.Cell>
                <Table.Cell>
                  EM + Lab (A) + (B) + Ergometría + Ecocardiograma
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>> 60 años</Table.Cell>
                <Table.Cell>Rechazo</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <List.List>
            <List.Item>
              <strong>
                <i>C.B.S.</i>
              </strong>{' '}
              => Cuestionario Básico de Salud.
            </List.Item>
            <List.Item>
              <strong>
                <i>C.E.S.</i>
              </strong>{' '}
              => Cuestionario Extendido de Salud.
            </List.Item>
            <List.Item>
              <strong>
                <i>Laboratorio (A)</i>
              </strong>{' '}
              => Analítica (Hemograma, VSG, glucosa, creatinina, colesterol
              total, HDL colesterol, triglicéridos, ac.úrico, GOT, GPT, GGT) +
              Serología HIV.
            </List.Item>
            <List.Item>
              <strong>
                <i>Laboratorio (B)</i>
              </strong>{' '}
              => Hemoglobina glicosilada, serología hepatitis B y C, Análisis de
              Orina.
            </List.Item>
            <List.Item>
              <strong>
                <i>ECG</i>
              </strong>{' '}
              => Electrocardiograma.
            </List.Item>
            <List.Item>
              <strong>
                <i>CARDIO</i>
              </strong>{' '}
              => Cuestionario específico sobre patologías cardiovasculares.
            </List.Item>
            <List.Item>
              <strong>
                <i>EM</i>
              </strong>{' '}
              => Examen Médico
            </List.Item>
          </List.List>
        </List.Item>
      </List>
    )
  }
}

export default VidaSurne
