import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tab, Modal, Button, Image } from "semantic-ui-react";
import styled from "styled-components";

import { FlexRow } from "../styled";

import ProductPage from "../ProductsFiles/ProductPage";
import AccidentesAig from "../ProductsFiles/AccidentesAig";
import AccidentesSurne from "../ProductsFiles/AccidentesSurne";
import ComunidadesSantalucia from "../ProductsFiles/ComunidadesSantalucia";
import ComunidadesPlusUltra from "../ProductsFiles/ComunidadesPlusUltra";
import ComunidadesLiberty from "../ProductsFiles/ComunidadesLiberty";
import DecesosHelvetia from "../ProductsFiles/DecesosHelvetia";
import DependenciaSurne from "../ProductsFiles/DependenciaSurne";
import VidaCalife from "../ProductsFiles/VidaCalife";
import VidaSurne from "../ProductsFiles/VidaSurne";
import AutonomoSurne from "../ProductsFiles/AutonomoSurne";
import AccidentesseniorSurne from "../ProductsFiles/AccidentesseniorSurne";
import HogarPlusultra from "../ProductsFiles/HogarPlusultra";
import HogarReale from "../ProductsFiles/HogarReale";
import SaludAsisa from "../ProductsFiles/SaludAsisa";
import SaludAdeslas from "../ProductsFiles/SaludAdeslas";
import SaludReembolsoAdeslas from "../ProductsFiles/SaludReembolsoAdeslas";
import MicroflotasCamiondirecto from "../ProductsFiles/MicroflotasCamiondirecto";
import Seguropordias from "../ProductsFiles/Seguropordias";
import AsistenciaenviajesIntermundial from "../ProductsFiles/AsistenciaenviajesIntermundial";
import CiberclearHiscox from "../ProductsFiles/CiberclearHiscox";
import BusinessclassChubb from "../ProductsFiles/BusinessclassChubb";
import RcHiscox from "../ProductsFiles/RcHiscox";
import RcBerkley from "../ProductsFiles/RcBerkley";
import AdministradoresyaltoscargosMarkel from "../ProductsFiles/AdministradoresyaltoscargosMarkel";
import ProteccionempresasArag from "../ProductsFiles/ProteccionempresasArag";
//import ProteccionempresasMdc from "../ProductsFiles/ProteccionempresasMdc";
//import DefensadañosMdc from "../ProductsFiles/DefensadañosMdc";
//import ReclamaciondañosMdc from "../ProductsFiles/ReclamaciondañosMdc";
//import GarantiamecanicaGes from "../ProductsFiles/GarantiamecanicaGes";
import TurismoactivoMdc from "../ProductsFiles/TurismoactivoMdc";
//import LegalautonomosMdc from "../ProductsFiles/LegalautonomosMdc";
import RcaltoscargosAig from "../ProductsFiles/RcaltoscargosAig";
//import RcaltoscargosChubb from "../ProductsFiles/RcaltoscargosChubb";
import JoyeriasTecnicaaseguradora from "../ProductsFiles/JoyeriasTecnicaaseguradora";
import ComercioLiberty from "../ProductsFiles/ComercioLiberty";
import ComercioyoficinasPlusultra from "../ProductsFiles/ComercioyoficinasPlusultra";
import PolizaPYSolunion from "../ProductsFiles/PolizaPYSolunion";
import DañosGenerali from "../ProductsFiles/DañosGenerali";
import SaludGenerali from "../ProductsFiles/SaludGenerali";
//import DriveWin from "../ProductsFiles/DriveWin";
import AlquileresMutuaDePropietarios from "../ProductsFiles/AlquileresMutuaDePropietarios";
import EmpresaFlexibleAxa from "../ProductsFiles/EmpresaFlexibleAxa";
import ProtocoloEmpresasAllianz from "../ProductsFiles/ProtocoloEmpresasAllianz";
import CiberseguroTelefonicaEspabrok from "../ProductsFiles/CiberseguroTelefonicaEspabrok";
import RCConstruccionAllianz from "../ProductsFiles/RCConstruccionAllianz";
import TRCConstruccionAllianz from "../ProductsFiles/TRCConstruccionAllianz";
import EmbarcacionesLiberty from "../ProductsFiles/EmbarcacionesLiberty";
import MascotasLiberty from "../ProductsFiles/MascotasLiberty";

import bkgParticulares from "../../images/products/bkg_particulares.jpg";
import bkgEmpresas from "../../images/products/bkg_empresas.jpg";
import bkgAccidentes from "../../images/products/bkg_accidentes.jpg";
import bkgComunidades from "../../images/products/bkg_comunidades.jpg";
import bkgDecesos from "../../images/products/bkg_decesos.jpg";
import bkgDependencia from "../../images/products/bkg_dependencia.jpg";
import bkgVida from "../../images/products/bkg_vida.jpg";
import bkgAccidentessenior from "../../images/products/bkg_accidentessenior.jpg";
import bkgHogar from "../../images/products/bkg_hogar.png";
import bkgMascotas from "../../images/products/bkg_mascotas.jpg";
import bkgEmbarcaciones from "../../images/products/bkg_embarcaciones.jpg";
import bkgSalud from "../../images/products/bkg_salud.png";
import bkgMicroflotas from "../../images/products/bkg_microflotas.png";
import bkgSeguropordias from "../../images/products/bkg_seguropordias.png";
import bkgAutonomo from "../../images/products/bkg_autonomo.jpg";
import bkgAsistenciaenviajes from "../../images/products/bkg_asistenciaenviajes.png";
import bkgCiberclear from "../../images/products/bkg_ciberclear.png";
import bkgBusinessclass from "../../images/products/bkg_businessclass.png";
import bkgRcprofesional from "../../images/products/bkg_rcprofesional.png";
import bkgAdministradoresyaltoscargos from "../../images/products/bkg_administradoresyaltoscargos.png";
import bkgProteccionempresas from "../../images/products/bkg_proteccionempresas.jpg";
import bkgDefensadaños from "../../images/products/bkg_defensadaños.jpg";
import bkgReclamaciondaños from "../../images/products/bkg_reclamaciondaños.jpg";
import bkgTurismoactivo from "../../images/products/bkg_turismoactivo.jpg";
//import bkgLegalautonomos from "../../images/products/bkg_legalautonomos.jpg";
import bkgRcaltoscargos from "../../images/products/bkg_rcaltoscargos.jpg";
import bkgJoyerias from "../../images/products/bkg_joyerias.jpg";
import bkgComercioyoficinas from "../../images/products/bkg_comercioyoficinas.png";
//import bkgGarantiamecanica from "../../images/products/bkg_garantiamecanica.png";
import bkgPolizaPYSolunion from "../../images/products/bkg_polizapysolunion.jpg";
//import bkgDriveWin from "../../images/products/bkg_drivewin.png";

import logoAig from "../../images/companies/logo_aig.png";
import logoArag from "../../images/companies/logo_arag.svg";
import logoSantalucia from "../../images/companies/logo_santalucia.png";
import logoHelvetia from "../../images/companies/logo_helvetia.png";
import logoSurne from "../../images/companies/logo_surne.png";
import logoCalife from "../../images/companies/logo_calife.png";
import logoHiscox from "../../images/companies/logo_hiscox.png";
import logoBerkley from "../../images/companies/logo_berkley.png";
import logoChubb from "../../images/companies/logo_chubb.svg";
import logoMarkel from "../../images/companies/logo_markel.png";
import logoMdc from "../../images/companies/logo_mdc.png";
import logoTecnicaaseguradora from "../../images/companies/logo_tecnicaaseguradora.png";
import logoPlusultra from "../../images/companies/logo-vector-occident.png";
//import logoPlusultra from "../../images/companies/logo_plusultra.svg";
//import logoGes from "../../images/companies/logo_ges.png";
import logoReale from "../../images/companies/logo_reale.png";
import logoAsisa from "../../images/companies/logo_asisa.png";
import logoAdeslas from "../../images/companies/logo_adeslas.png";
import logoCamiondirecto from "../../images/companies/logo_camiondirecto.png";
import logoSeguropordias from "../../images/companies/logo_seguropordias.png";
import logoIntermundial from "../../images/companies/logo_intermundial.png";
import logoSolunion from "../../images/companies/logo_solunion.png";
import logoGenerali from "../../images/companies/logo_generali.png";
//import logoDriveWin from "../../images/companies/logo_drivewin.png";
import logoMutua from "../../images/companies/company_mutua.png";
import logoAxa from "../../images/companies/company_axa.svg";
import logoAllianz from "../../images/companies/company_allianz.svg";
import logoLiberty from "../../images/companies/logo_liberty.png";
import logoTelefonicaEspabrok from "../../images/companies/logo_telefonica_espabrok.png";
import CloudFilesExplorer from "../CloudFilesExplorer";

import { withUserContext } from "../Context";

const productsMenu = {
  Particulares: {
    "Espabrok Accidentes": {
      backgroundImage: bkgAccidentes,
      products: [
        {
          id: "Accidentes_AIG",
          name: "AIG",
          logo: logoAig,
          ProductInformation: AccidentesAig,
          folder: "Accidentes_AIG",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Erq1dKEBF3FPijyObWlJPfcBHUobo4RwnfXAykY7ZeNfGg?e=2U6fMn",
        },
        {
          id: "Accidentes_Surne",
          name: "Surne",
          logo: logoSurne,
          ProductInformation: AccidentesSurne,
          folder: "Accidentes_Surne",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EjwSiE4BuzFCpx2CcPJ6_iwBS9dSrvKYkhvjst7cWeWuEw?e=o8d0Po",
          
        },
      ],
    },
    "Espabrok Comunidades": {
      backgroundImage: bkgComunidades,
      products: [
        {
          id: "Comunidades_SantaLucia",
          name: "Santa Lucía",
          logo: logoSantalucia,
          ProductInformation: ComunidadesSantalucia,
          folder: "Comunidades_Santa Lucia",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eusc8bxwGtBDhDFaYoqR6W4Br_3zn3s5ICq3lIvz5eSERg?e=xAAxbu",
        },
        {
          id: "Comunidades_PlusUltra",
          name: "Plus Ultra",
          logo: logoPlusultra,
          ProductInformation: ComunidadesPlusUltra,
          folder: "Comunidades_Plus Ultra",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eh9u9j13aRNHuWOWyNXmLiEBxMlLFcKCRayfvaJj96tazg?e=7vYaQS",
        },
        {
          id: "Comunidades_Liberty",
          name: "Liberty",
          logo: logoLiberty,
          ProductInformation: ComunidadesLiberty,
          link: "https://espaservices.sharepoint.com/:b:/s/Productos/EbyaSUAV8N5Ctxs0YjYeCyIBNB4Kam6BzwvWY8ejcd3R6g?e=kgF7aH",
          folder: "Comunidades_Liberty",
        },
      ],
    },
    Decesos: {
      backgroundImage: bkgDecesos,
      products: [
        {
          id: "Decesos_Helvetia",
          name: "Helvetia",
          logo: logoHelvetia,
          ProductInformation: DecesosHelvetia,
          folder: "Decesos_Helvetia",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eo9_KmaPmlFIjzqmgDiosD0BAxPFUxUxJrdYL0UbhqVNOg?e=2e8jYb",
        },
      ],
    },
    Dependencia: {
      backgroundImage: bkgDependencia,
      products: [
        {
          id: "Dependencia_Surne",
          name: "Surne",
          logo: logoSurne,
          ProductInformation: DependenciaSurne,
          folder: "Dependencia_Surne",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Ev04R3x6u9hJo7VmaX_UFoIBIJKvWgdHLSRmepJdSYOtNQ?e=NqKxwU",
        },
      ],
    },
    Vida: {
      backgroundImage: bkgVida,
      products: [
        {
          id: "Vida_CALife",
          name: "CA Life",
          logo: logoCalife,
          ProductInformation: VidaCalife,
          folder: "Vida_CA Life",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Ekx6C-O5Ko5Mj9LpzHMYmXMBtFyvh54vzT6y8z0QOmTfiQ?e=aVKgwE",
        },
        {
          id: "Vida_Surne",
          name: "Surne",
          logo: logoSurne,
          ProductInformation: VidaSurne,
          folder: "Vida_Surne",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Epvx-5PeO7JPv5221Uv3ZiUB1mDsWp5vFlphwQbTjmaQ5Q?e=nk0FUV",
        },
      ],
    },
    "Autónomo Power": {
      backgroundImage: bkgAutonomo,
      products: [
        {
          id: "Autonomo_Surne",
          name: "Surne",
          logo: logoSurne,
          ProductInformation: AutonomoSurne,
          folder: "Autonomo Power_Surne",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EvPu7cYF-MVHqw8HDGy1ob0BGf-UyO4wotwQMbEOUk_2_w?e=GgxIoV",
        },
      ],
    },
    "Accidentes Senior": {
      backgroundImage: bkgAccidentessenior,
      products: [
        {
          id: "Accidentessenior_Surne",
          name: "Surne",
          logo: logoSurne,
          ProductInformation: AccidentesseniorSurne,
          folder: "Accidentes Senior_Surne",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EoSsoCaS5gJJmW5WF4b0VooBf2u9_R457Gb7KLsv4O67dw?e=VwD8nr",
        },
      ],
    },
    Hogar: {
      backgroundImage: bkgHogar,
      products: [
        {
          id: "Hogar_Plusultra",
          name: "Plus Ultra",
          logo: logoPlusultra,
          ProductInformation: HogarPlusultra,
          folder: "Hogar_Plus Ultra",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EuFV4LUmPVlGrj5X77-3gHIB0HBuiF120aJAUYG97SOxYg?e=eROAnj",
        },
        {
          id: "Hogar_Reale",
          name: "Reale",
          logo: logoReale,
          ProductInformation: HogarReale,
          folder: "Hogar_Reale",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EpAFy-BdP0dLlaNnABCH8doBDaKQ_Q3qnC7Mz_FcHnCF7A?e=zC7HuZ",
        },
      ],
    },
    "Salud Cuadro Médico": {
      backgroundImage: bkgSalud,
      products: [
        {
          id: "Salud_Asisa",
          name: "Asisa",
          logo: logoAsisa,
          ProductInformation: SaludAsisa,
          folder: "Salud_Asisa",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EoOukmALJGZNlrrnuSRTRqIBg0-cDAiICE7S2knNRIf5Cg?e=pHvXSN",
        },
        {
          id: "Salud_Adeslas",
          name: "Adeslas",
          logo: logoAdeslas,
          ProductInformation: SaludAdeslas,
          folder: "Salud_Adeslas",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/El_spqgB1u9PoQ19TJGalHwBQoKWeadgLtOQfz3PZBQhcw?e=jg8roB",
        },
        {
          id: "Salud_Generali",
          name: "Generali",
          logo: logoGenerali,
          ProductInformation: SaludGenerali,
          folder: "Salud_Generali",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EgUTLkKbjy9HlcmyM9HbC6MBU4FevefL05SF5isiq64TJA?e=yUbzRY",
        },
      ],
    },
    "Salud Reembolso": {
      backgroundImage: bkgSalud,
      products: [
        {
          id: "Saludreembolso_Adeslas",
          name: "Adeslas",
          logo: logoAdeslas,
          ProductInformation: SaludReembolsoAdeslas,
          folder: "Salud_Adeslas_Reembolso",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/ErY_TYX66bZGtWSEonKp2osBVpAy_HbmJMYTPbY5G-YJdg?e=piu238", 
        },
      ],
    },
    Microflotas: {
      backgroundImage: bkgMicroflotas,
      products: [
        {
          id: "Microflotas_Camiondirecto",
          name: "Camion Directo",
          logo: logoCamiondirecto,
          ProductInformation: MicroflotasCamiondirecto,
          folder: "Micro Flotas_Camión Directo",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EkEsf5Ja-4lLjqestCK0K_IB3sMgsuLgOWNkj-92u5neKA?e=ACMcey",
        },
      ],
    },
    "Seguro por Días": {
      backgroundImage: bkgSeguropordias,
      products: [
        {
          id: "Seguropordias",
          name: "Seguro por Días",
          logo: logoSeguropordias,
          ProductInformation: Seguropordias,
          folder: "Seguro por días",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EjkTVPtGwUxAh9NnHTa6q6sBdkACn5ER3dNhKQKrgCVNDg?e=mFsT9m",
        },
      ],
    },
    "Asistencia en Viajes": {
      backgroundImage: bkgAsistenciaenviajes,
      products: [
        {
          id: "Asistenciaenviajes_Intermundial",
          name: "Intermundial",
          logo: logoIntermundial,
          ProductInformation: AsistenciaenviajesIntermundial,
          folder: "Asistencia en Viajes_Intermundial",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EmUJfcNpXTdBunpGH35mDb4BBQlkb0Nn0Fz6Niqim9zwfQ?e=f9Eyge",
        },
      ],
    },
    /*"Producto conductores sin bonificación": {
      backgroundImage: bkgDriveWin,
      products: [
        {
          id: "Drive&Win",
          name: "Drive&Win",
          logo: logoDriveWin,
          ProductInformation: DriveWin,
          folder: "Drive&Win",
        },
      ],
    },*/
    "Impago de alquileres": {
      backgroundImage: bkgHogar,
      products: [
        {
          id: "ImpagoAlquileres_MutuaPropietarios",
          name: "Mutua de Propietarios.",
          logo: logoMutua,
          ProductInformation: AlquileresMutuaDePropietarios,
          folder: "ImpagoAlquileres_MutuaPropietarios",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EtbFIBQeJQVCjUf0HV2T3bIBVRRMn21OcAxNh6_eYLDXyQ?e=k8dRB6",
        },
      ],
    },
    Embarcaciones: {
      backgroundImage: bkgEmbarcaciones,
      products: [
        {
          id: "Embarcaciones_Liberty",
          name: "Liberty",
          logo: logoLiberty,
          ProductInformation: EmbarcacionesLiberty,
          folder: "Embarcaciones_Liberty",
          link: "https://espaservices.sharepoint.com/:b:/s/Productos/EUM52cnb8ehDjvQt50DPcy0BRlnxcf1qgCTtawNeHFLvQg?e=RJZkON",
        },
      ],
    },
    Mascotas: {
      backgroundImage: bkgMascotas,
      products: [
        {
          id: "Mascotas_Liberty",
          name: "Liberty",
          logo: logoLiberty,
          ProductInformation: MascotasLiberty,
          folder: "Mascotas_Liberty",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Enk21DksVMJEh_ALA_Rp8RgB4-iQ3iflEbqVfARHpX9NEQ?e=OahVvC"
        },
      ],
    },
  },
  Empresas: {
    "Clausulado Daños Espabrok": {
      backgroundImage: bkgComercioyoficinas,
      products: [
        {
          id: "Empresas_allianz",
          name: "Protocolo Empresas de Allianz",
          logo: logoAllianz,
          ProductInformation: ProtocoloEmpresasAllianz,
          folder: "Daños_Allianz",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Evqd_CcKbK1KoaOBxn4NJiQBlLiSDikCVQzFaUZBT_yhLg?e=8rsnSs",
        },
        {
          id: "Flexible_axa",
          name: "Empresa Flexible de Axa",
          logo: logoAxa,
          ProductInformation: EmpresaFlexibleAxa,
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EmiSlRVVEsxFqIj8WLYp9-EBf1xtsxJfTvWHMVJ-XtbVzA?e=tOXao7",
        },
        {
          id: "Daños_Generali",
          name: "Daños de la Compañía General",
          logo: logoGenerali,
          ProductInformation: DañosGenerali,
          folder: "Daños_Generali",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EkXCkFMiK29LnZxP6jUq_QIBg3u1c9-xLevYhat2nOhA9Q?e=m2IMfd",
        },
      ],
    },
    "Clausulado RC Construcción Espabrok": {
      backgroundImage: bkgDefensadaños,
      products: [
        {
          id: "RC_allianz",
          name: "RC Construcción Allianz",
          logo: logoAllianz,
          ProductInformation: RCConstruccionAllianz,
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Evqd_CcKbK1KoaOBxn4NJiQBlLiSDikCVQzFaUZBT_yhLg?e=ihdtRl",
        },
      ],
    },
    "Clausulado TRC Espabrok": {
      backgroundImage: bkgReclamaciondaños,
      products: [
        {
          id: "TRC_allianz",
          name: "TRC Allianz",
          logo: logoAllianz,
          ProductInformation: TRCConstruccionAllianz,
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EtybtwON8b5JrI5Y_p6DnFQBSp2JphgG-C-tywhj6pBVhw?e=HjgzKU",
        },
      ],
    },
    "RC Administradores y Altos Cargos (D&O)": {
      backgroundImage: bkgAdministradoresyaltoscargos,
      products: [
        {
          id: "AdminYAltosCargos_Markel",
          name: "Markel",
          logo: logoMarkel,
          ProductInformation: AdministradoresyaltoscargosMarkel,
          folder: "Administrador y Altos Cargos_Markel",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EgZ4g_v4Z3lMubaqCe4w3NcBq2kUa87vI2hXhd2-4Ox3ww?e=4040FE",
        },
      ],
    },
    "Ciber riesgo": {
      backgroundImage: bkgCiberclear,
      products: [
        {
          id: "Ciberclear_Hiscox",
          name: "Ciberclear Hiscox",
          logo: logoHiscox,
          ProductInformation: CiberclearHiscox,
          folder: "Ciberclear_Hiscox",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eudk0ETDxqNOjQrHJg469IIBOjnGXCdplTNUD3ZUVPrQKg?e=qjAi6R",
        },
        {
          id: "ciberseguro_telefonica_espabrok",
          name: "Ciberseguro Telefonica-Espabrok",
          logo: logoTelefonicaEspabrok,
          ProductInformation: CiberseguroTelefonicaEspabrok,
          folder: "Ciberseguro Telefonica-Espabrok",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EkVXrL6NamBLqtX9Mfr9eT4B9c_PFO3JD2QA94YZ0ptcsA?e=jfsVy0",
        },
      ],
    },
    "RC Profesional": {
      backgroundImage: bkgRcprofesional,
      products: [
        {
          id: "RCProfesional_Hiscox",
          name: "Hiscox",
          logo: logoHiscox,
          ProductInformation: RcHiscox,
          folder: "RC Profesional_Hiscox",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eh9FLAa49WBBlA462bZEfzwB4_bsDuLcoj2J5VKpBBk16g?e=rcLkjo",
        },
        {
          id: "RCProfesional_Berkley",
          name: "WR Berkley",
          logo: logoBerkley,
          ProductInformation: RcBerkley,
          folder: "RC Profesional_WRBerkley",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eu3VeGuanIlKpckYYso0lA8B6BI2lUjMlzI6L9yI1BUebg?e=52cEIg",
        },
      ],
    },
    "Crédito": {
      backgroundImage: bkgPolizaPYSolunion,
      products: [
        {
          id: "PY_Solunion",
          name: "PY",
          logo: logoSolunion,
          ProductInformation: PolizaPYSolunion,
          folder: "Póliza PY_Solunion",
          link: "https://espaservices.sharepoint.com/sites/Productos/Documentacin%20producto/Forms/AllItems.aspx?id=%2Fsites%2FProductos%2FDocumentacin%20producto%2FEmpresas%2FP%C3%B3liza%20PY%20Solunion%20%2D%20PY&p=true&ga=1",
        },
      ],
    },
    "Defensa Jurídica": {
      backgroundImage: bkgProteccionempresas,
      products: [
        {
          id: "Proteccion_Empresas_Arag",
          name: "ARAG",
          logo: logoArag,
          ProductInformation: ProteccionempresasArag,
          folder: "Protección Empresas_Arag",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EhQRaBtvN8ZEt-se5WtkWPQB_aDB9HhWrdw0y8BC4iwNkA?e=KieegV", 
        },
      ],
    },
    "Espabrok Business Class": {
      backgroundImage: bkgBusinessclass,
      products: [
        {
          id: "BusinessClass_Chubb",
          name: "Chubb",
          logo: logoChubb,
          ProductInformation: BusinessclassChubb,
          folder: "Business Class_Chubb",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EmZOdL7JOFpOt4Y5iqGIrrYBfrZeDIk-TvvkdlQG5iD3DA?e=JCqCTk",
        },
      ],
    },
    /* Eliminado en TICKET: #9602224
    "Protección de Empresas": {
      backgroundImage: bkgProteccionempresas,
      products: [
        {
          id: "Proteccion_Empresas_MDC",
          name: "MDC",
          logo: logoMdc,
          ProductInformation: ProteccionempresasMdc,
          folder: "Protección de Empresas_MDC",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eoe4QSXXT_9Dick2KsQsQigBa5dNQPrz4LDTrgHYLcRViQ?e=Qeu4Q6",
        },
      ],
    },
    "Reclamación de Daños": {
      backgroundImage: bkgReclamaciondaños,
      products: [
        {
          id: "Reclamacion_Daños",
          name: "MDC",
          logo: logoMdc,
          ProductInformation: ReclamaciondañosMdc,
          folder: "Reclamación de Daños_MDC",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Etsq8tMyXXJCosyezeB58HYBdxl858pgxMQIOsBrkHwMog?e=Mobyfh",
        },
      ],
    },*/
    "Turismo Activo": {
      backgroundImage: bkgTurismoactivo,
      products: [
        {
          id: "Turismo_Activo",
          name: "MDC",
          logo: logoMdc,
          ProductInformation: TurismoactivoMdc,
          folder: "Turismo Activo_MDC",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/Eh-TZsxZx5lEg72X7F7FWSkBc8LgDJP4hBnPZi6iDkQQWg?e=QieS68",
        },
      ],
    },
    /*"Legal Autónomos": {
      backgroundImage: bkgLegalautonomos,
      products: [
        {
          id: "Legal_Autonomos",
          name: "MDC",
          logo: logoMdc,
          ProductInformation: LegalautonomosMdc,
          folder: "Legal Autónomos_MDC",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/En4jAbPATRNKi5osaAE_ueoB3zJVErtQt6p1y-6712bwlA?e=gjN8sU",
        },
      ],
    },*/
    "RC Administraciones Públicas": {
      backgroundImage: bkgRcaltoscargos,
      products: [
        {
          id: "Rc_Altos_Cargos_Aig",
          name: "AIG",
          logo: logoAig,
          ProductInformation: RcaltoscargosAig,
          folder: "RC Altos Cargos Administración Pública_AIG",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EkFY_ns-AL9PqoPcoxGqWiABBcVlkoUhr0QS_l2gFQ5BCg?e=NTtCJB",
        },
        /*{
          id: "Rc_Altos_Cargos_Chubb",
          name: "Chubb",
          logo: logoChubb,
          ProductInformation: RcaltoscargosChubb,
          folder: "RC Altos Cargos Administración Pública_Chubb",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EhU0VA_tjL5HpC8eIfhX8O0BR72Hs0weRgoy2--j4AlExA?e=hdc7do",
        },*/
        {
          id: "Rc_Altos_Cargos_Berkley",
          name: "Berkley",
          logo: logoBerkley,
          ProductInformation: null,
          folder: "RC Altos Cargos Administración Pública_Berkley",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EhU0VA_tjL5HpC8eIfhX8O0BR72Hs0weRgoy2--j4AlExA?e=hdc7do",
        },
      ],
    },
    Joyerías: {
      backgroundImage: bkgJoyerias,
      products: [
        {
          id: "Joyerias",
          name: "TécnicaAseguradora",
          logo: logoTecnicaaseguradora,
          ProductInformation: JoyeriasTecnicaaseguradora,
          folder: "Joyería_Técnica Aseguradora",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EthLFv0jmw9CqrmmuN-vbEIBuWeMIbhubsa2-QUTh4I_5g?e=60MxPf",
        },
      ],
    },
    /* Eliminado en TICKET: #9602224
    "Garantía Mecánica": {
      backgroundImage: bkgGarantiamecanica,
      products: [
        {
          id: "Garantia_Mecanica",
          name: "GES",
          logo: logoGes,
          ProductInformation: GarantiamecanicaGes,
          folder: "Garantía Mecánica_Ges",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EqJk3lW2r3pEpzRf0kUaOwcBWLfZniVTZtNMSCh8eKbmQQ?e=vKNiEv",
        },
      ],
    },
    "Defensa de Daños": {
      backgroundImage: bkgDefensadaños,
      products: [
        {
          id: "Defensa_Daños",
          name: "MDC",
          logo: logoMdc,
          ProductInformation: DefensadañosMdc,
          folder: "Defensa de Daños_MDC",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EggefxiTEt9PoZbikUo7QWkB05_ScJDhqC0L9w9p9Mnz2A?e=osKD83",
        },
      ],
    },*/
    "Comercio y Oficinas": {
      backgroundImage: bkgComercioyoficinas,
      products: [
        {
          id: "Comercio_Oficinas",
          name: "Plus Ultra",
          logo: logoPlusultra,
          ProductInformation: ComercioyoficinasPlusultra,
          folder: "Comercio y Oficinas_Plus Ultra",
          link: "https://espaservices.sharepoint.com/:f:/s/Productos/EjcAVvkACy9PrDvi4BEFSKgB4GdVFF__PjRZOE21G6uzQw?e=sp6NVr",
        },
        {
          id: "Comercio_Liberty",
          name: "Liberty",
          logo: logoLiberty,
          ProductInformation: ComercioLiberty,
          folder: "Comercio_Liberty",
          link: "https://espaservices.sharepoint.com/sites/Productos/Documentacin%20producto/Forms/AllItems.aspx?id=%2Fsites%2FProductos%2FDocumentacin%20producto%2FEmpresas%2FComercio%20y%20Oficinas%20%2D%20Liberty&p=true&ga=1",
        },
      ],
    },
  },
};

const ProductsModalStyled = styled.div`
  margin: 2rem;
  padding: 0;
  & .title {
    font-size: 1.4rem;
    /* font-weight: bold; */
    margin-bottom: 1.4rem;
  }
  @media (min-width: 768px) {
    margin: 2.6rem;
    padding: 0 1rem;
    & .title {
      font-size: 2rem;
    }
  }
`;
const ProductMenuStyled = styled.div``;
const ProductType = styled.div`
  height: 95px;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  background: ${(props) =>
    props.bkgimg
      ? `#fff url(${props.bkgimg}) no-repeat center center`
      : "#ddd"};
  background-size: cover;
  position: relative;
  margin-bottom: 0.4rem;
  &:last-of-type {
    margin-bottom: 0%;
  }
  & .opacity-layer {
    background: #fff;
    opacity: 0.3;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  & .content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.2rem 1.2rem 0.8rem 1.2rem;
  }
  & .type-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px 2px black;
    margin-bottom: 0.6rem;
  }
  @media (min-width: 768px) {
    height: 105px;
    & .type-title {
      font-size: 1.2rem;
    }
    & .content {
      padding: 1.4rem 1.4rem 1rem 1.4rem;
    }
  }
`;
const Product = styled.div`
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  height: 40px;
  background: #fff;
  padding: 0 1rem;
  margin-left: 0.4rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    border: 1px solid #999;
    opacity: 0.9;
  }
  & .logo {
    height: 20px;
  }
  @media (min-width: 768px) {
    height: 50px;
    & .logo {
      height: 25px;
    }
  }
`;

class ProductsModal extends Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
    onProductSelectionChange: PropTypes.func,
  };
  static defaultProps = {
    onProductSelectionChange: () => null,
  };

  state = {
    productSelected: null,
  };

  componentWillUnmount() {
    this.props.onProductSelectionChange(null);
  }

  handleProductSelection = (productData) => {
    // Si posee información de un enlace. Lo abrimos
    if(productData.link){
      // 👇 Open link in new tab programmatically
      window.open(productData.link, '_blank', 'noreferrer');
      return;
    }
    this.setState({ productSelected: productData });
    this.props.onProductSelectionChange(productData);
  };

  handleBack = () => {
    this.setState({ productSelected: null });
    this.props.onProductSelectionChange(null);
  };

  render() {
    const { category } = this.props;
    const { productSelected } = this.state;
    const menuOptions = productsMenu[category];

    return (
      <ProductsModalStyled>
        <FlexRow justifyContent="space-between" className="title">
          <div>Productos para {category}</div>
          {productSelected && (
            <Button circular icon="chevron left" onClick={this.handleBack} />
          )}
        </FlexRow>
        {/* PRODUCTS MENU */}
        {!productSelected && (
          <ProductMenuStyled>
            {Object.keys(menuOptions).map((menuOptionTitle) => (
              <ProductType
                key={menuOptionTitle}
                bkgimg={menuOptions[menuOptionTitle].backgroundImage}
              >
                <div className="opacity-layer" />
                <div className="content">
                  <div className="type-title">{menuOptionTitle}</div>
                  <FlexRow justifyContent="flex-end">
                    {menuOptions[menuOptionTitle].products.map((product) => (
                      <Product
                        key={product.id}
                        onClick={(e) =>
                          this.handleProductSelection({
                            ...product,
                            productType: menuOptionTitle,
                          })
                        }
                      >
                        <Image src={product.logo} className="logo" />
                      </Product>
                    ))}
                  </FlexRow>
                </div>
              </ProductType>
            ))}
          </ProductMenuStyled>
        )}
        {/* PRODUCT INFO */}
        {productSelected && (
          <ProductPage productData={productSelected}>
            {productSelected.ProductInformation ? (
              <productSelected.ProductInformation />
            ) : (
              <div>{productSelected.id}</div>
            )}
          </ProductPage>
        )}
      </ProductsModalStyled>
    );
  }
}

const productCategoriesList = [
  { title: "Particulares", backgroundImage: bkgParticulares },
  { title: "Empresas", backgroundImage: bkgEmpresas },
];

const ProductCategoriesStyled = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;
const ProductCategory = styled.div`
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  flex: 1 1 auto;
  position: relative;
  background: ${(props) =>
    props.bkgimg
      ? `#fff url(${props.bkgimg}) no-repeat center center`
      : "#ddd"};
  background-size: cover;
  margin-bottom: 0.4rem;
  &:last-of-type {
    margin-bottom: 0%;
  }
  & .opacity-layer {
    background: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  & .title {
    color: #000;
    font-size: 1.6rem;
    font-weight: bold;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  &:hover {
    cursor: pointer;
    & .opacity-layer {
      opacity: 0;
    }
    & .title {
      color: #fff;
      text-shadow: 1px 1px 2px black;
    }
  }
`;
class ProductCategories extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
  };

  // Sending the product selection also up here just to modify the modal
  // width depending if we're watching the menu or a product file.
  state = {
    productSelected: null,
  };

  onProductSelectionChange = (productSelected) => {
    this.setState({ productSelected });
  };

  render() {
    const { categories } = this.props;
    const { productSelected } = this.state;

    return (
      <ProductCategoriesStyled>
        {categories.map(({ title, backgroundImage }) => (
          <Modal
            key={title}
            // centered={false}
            size={!productSelected ? "tiny" : "large"}
            trigger={
              <ProductCategory bkgimg={backgroundImage}>
                <div className="opacity-layer" />
                <div className="title">{title}</div>
              </ProductCategory>
            }
          >
            <ProductsModal
              category={title}
              onProductSelectionChange={this.onProductSelectionChange}
            />
          </Modal>
        ))}
      </ProductCategoriesStyled>
    );
  }
}

const paneStyle = { height: "300px", padding: "1.6rem" };

const panes = (userId) => {
  const list = [
    {
      menuItem: "Productos",
      render: () => (
        <Tab.Pane
          style={paneStyle}
          content={
            <ProductCategories
              key="products-categories-list"
              categories={productCategoriesList}
            />
          }
        />
      ),
    },
  ];

  // if (!BETA_IDS.includes(userId)) {
  //   return list;
  // }

  return [
    ...list,
    ...[
      {
        menuItem: "Proveedores de servicios",
        render: () => (
          <Tab.Pane
            style={paneStyle}
            content={
              <CloudFilesExplorer
                key="proveedores-de-servicios"
                baseFolder="PROVEDORES DE SERVICIOS/"
              />
            }
          />
        ),
      },
      {
        menuItem: "Cuestionarios",
        render: () => (
          <Tab.Pane
            style={paneStyle}
            content={
              <CloudFilesExplorer
                key="cuestionarios"
                baseFolder="DOCUMENTACION Y CUESTIONARIOS/"
              />
            }
          />
        ),
      },
      {
        menuItem: "Videoteca",
        render: () => (
          <Tab.Pane
            style={paneStyle}
            content={
              <CloudFilesExplorer
                key="videos-espabrok"
                baseFolder="Videos Espabrok/"
              />
            }
          />
        ),
      },
    ],
  ];
};

class WidgetProducts extends Component {
  render() {
    const { userId } = this.props.user;

    return (
      <Tab
        panes={panes(userId)}
        menu={{
          attached: true,
          tabular: true,
          style: { display: "flex", flexDirection: "row", flexWrap: "wrap" },
        }}
      />
    );
  }
}

export default withUserContext(WidgetProducts);
