import React from "react";
import { Icon } from "semantic-ui-react";

export const GenericLoader = ({ style, ...rest }) => (
  <div
    style={{
      width: "100%",
      textAlign: "center",
      marginTop: "1.4rem",
      ...style,
    }}
    {...rest}
  >
    <Icon loading size="big" name="spinner" />
  </div>
);
