import React from "react";
import { Label, Popup } from "semantic-ui-react";

export const Stock = ({ name, stock, style, ...rest }) => {
  const status =
    stock > 20
      ? { color: "green", text: "Disponible" }
      : stock > 5
      ? { color: "orange", text: "Limitado" }
      : stock > 0
      ? { color: "grey", text: "Indefinido" }
      : { color: "red", text: "No disponible" };
  return (
    <div style={{ display: "inline-block", whiteSpace: "nowrap", ...style }}>
      {name && name + ": "}
      <Popup
        trigger={<Label color={status.color} {...rest} />}
        content={`${status.text} (${stock})`}
      />
    </div>
  );
};
