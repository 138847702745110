import React, { Component } from 'react'

class MicroflotasCamiondirecto extends Component {
  render() {
    return (
      <div>
        <strong>EMISION</strong>: Producto Descentralizado. Se emite
        directamente a través de la web de{' '}
        <a href="camiondirecto.com" target="_blank">
          camiondirecto.com
        </a>
        .
        <br />
        <br />
        <strong>OPERATIVA</strong>: Hay que cumplimentar el modelo de toma de
        datos colaborador y enviarlo a Vanesa Rincón 
        <a href="mailto:vanesarincon@camiondirecto.com">
          vanesarincon@camiondirecto.com
        </a>
         para que os envíe el contrato de colaboración y las claves de acceso.
        <br />
        <br />
        <strong>ENFOCADO</strong>: Micro flotas (menos de 10 vehículos entre
        cabezas y remolques), flotas con mal SINCO o clientes sin SINCO en
        vehículos de segunda categoría.
      </div>
    )
  }
}

export default MicroflotasCamiondirecto
