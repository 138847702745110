import React, { Component } from "react";
import { List, Table } from "semantic-ui-react";

class ComercioyoficinasPlusultra extends Component {
  render() {
    return (
      <div>
        <strong>PRODUCTOS DESCENTRALIZADOS</strong>: Se emiten a través de la
        página web de la compañía.
        <List bulleted>
          <List.Item>Oficina Plus Espabrok.</List.Item>
          <List.Item>Comercio Plus Espabrok.</List.Item>
        </List>
        <br />
        <strong>DIRIGIDO</strong> a un amplio sector de clientes, que necesitan
        asegurar sus comercios y oficinas, ofreciendo unas coberturas especiales
        y exclusivas para solucionar todos los problemas que pudieran surgir en
        el negocio.
        <br />
        <br />
        <strong>NOVEDADES</strong>:
        <List bulleted>
          <List.Item>
            Mejoras en el proceso de tarificación, gracias a su diseño
            ergonómico, con una navegación intuitiva y cálculo de cotización
            ultrarrápido.
          </List.Item>
          <List.Item>
            <strong>Adaptado a sus clientes</strong>: Alimentación, moda,
            restauración, belleza, artículos del hogar.
          </List.Item>
          <List.Item>
            <strong>Nuevas coberturas</strong>: daños a bienes en el exterior,
            robo de bienes en el exterior, robo y atraco de metálico guardado en
            la caja fuerte del domicilio, RC subsidiaria, RC Cruzada.
          </List.Item>
          <List.Item>Próxima inclusión en multitarificadores.</List.Item>
        </List>
        <br />
        <strong>MEJORAS EXCLUSIVAS PARA ESPABROK</strong>:
        <Table
          unstackable
          celled
          structured
          textAlign="center"
          style={{ margin: "1rem 0" }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Cobertura</Table.HeaderCell>
              <Table.HeaderCell>Producto anterior</Table.HeaderCell>
              <Table.HeaderCell>Nueva oferta personalizada</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                Localización y reparación de tuberías sin daños
              </Table.Cell>
              <Table.Cell>Límite 1.500€</Table.Cell>
              <Table.Cell>Límite 2.000€</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Daños eléctricos</Table.Cell>
              <Table.Cell>Hasta 6.000€</Table.Cell>
              <Table.Cell>Hasta 100% capital</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Atraco a clientes y empleados</Table.Cell>
              <Table.Cell>Límite por persona 300€</Table.Cell>
              <Table.Cell>Límite por persona 500€</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Avería de equipos electrónicos / maquinaria
              </Table.Cell>
              <Table.Cell>Límite 6.000€</Table.Cell>
              <Table.Cell>Límite abierto</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>RC bienes manipulados / en depósito</Table.Cell>
              <Table.Cell>Límite 6.000€ por siniestro</Table.Cell>
              <Table.Cell>Límite 10.000€ por siniestro</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Robo de bienes en escaparates</Table.Cell>
              <Table.Cell>500€</Table.Cell>
              <Table.Cell>1.000€</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default ComercioyoficinasPlusultra;
