import React, { Component } from 'react'
import { List, Table } from 'semantic-ui-react'

class DecesosHelvetia extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO DESCENTRALIZADO</strong>.
        </List.Item>
        <List.Item>
          <strong>OPERATIVA DE CONTRATACIÓN</strong>. Online, a través de la
          página Web de la compañía.
        </List.Item>
        <List.Item>
          COMISIÓN:
          <Table
            unstackable
            celled
            structured
            textAlign="center"
            style={{ margin: '1rem 0' }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  Comisiones <i>(*)</i>
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">
                  Facturación Mensual
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Año 1º</Table.HeaderCell>
                <Table.HeaderCell>Año 2º</Table.HeaderCell>
                <Table.HeaderCell>Año 3º</Table.HeaderCell>
                <Table.HeaderCell>Sucesivos</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>99,90%</Table.Cell>
                <Table.Cell>70%</Table.Cell>
                <Table.Cell>50%</Table.Cell>
                <Table.Cell>25%</Table.Cell>
                <Table.Cell>3.000 €</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <i>(*)</i> Emitiendo 3.000€/mes de primas netas anualizadas entre toda
          la red de Espabrok.
          <br />
          <br />
        </List.Item>
        <List.Item>
          <strong>SOLICITUD DE CÓDIGO</strong>: Contactar con el comercial de
          zona. Ver documento de contactos.
        </List.Item>
        <List.Item>
          <strong>PRODUCTO DE PRIMER NIVEL</strong>. Plan de Asistencia
          Familiar, destacando:
          <List.List>
            <List.Item>
              Posibilidad de contratación de primas periódicas hasta los 80
              años.
            </List.Item>
            <List.Item>Historial médico en la nube.</List.Item>
            <List.Item>Consulta médica por videollamada.</List.Item>
            <List.Item>
              Consultas gratuitas en especialidades médicas.
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          <strong>GARANTÍAS Y COBERTURAS EXCLUSIVAS</strong> que nos
          diferencian del resto de Compañías que operan en este ramo. Para
          mayores de 80 años se comercializa modalidad de Prima Única, con
          primas muy competitivas y donde se cubre sin límites el traslado
          nacional e internacional.
        </List.Item>
      </List>
    )
  }
}

export default DecesosHelvetia
