import React, { Component } from "react";
import Parser from "rss-parser";
import { Timeline } from "react-twitter-widgets";
import { Tab /*, Image*/ } from "semantic-ui-react";
import styled from "styled-components";

import { GenericLoader } from "../UI";

// import isotype from '../../images/isotype-espabrok.svg'

const MONTHS = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

/* RSS */
const RssFeed = styled.div`
  height: 300px;
  font-size: 0.95rem;
  padding: 1rem;
  overflow-y: scroll;
`;
const RssFeedList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0rem;
`;
const RssFeedNew = styled.li`
  margin: 0 0 1rem 0;
  & a {
    color: rgb(54, 90, 179);
  }
  & a:hover {
    cursor: pointer;
  }
`;

class Rss extends Component {
  state = {
    status: "loading",
    items: [],
  };

  componentDidMount() {
    this.loadFeed();
  }

  loadFeed = () => {
    let parser = new Parser();

    this.setState({ status: "loading" });
    parser
      .parseURL(
        // 'https://cors-anywhere.herokuapp.com/http://www.espabrok.es/feed/'
        "https://www.espabrok.es/feed/"
      )
      .then((response) => {
        // console.log(response)
        this.setState({ status: "ok", items: response.items });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ status: "error" });
      });
  };

  formatDate = (date) => {
    const dt = new Date(date);
    return `${dt.getDate()} ${MONTHS[dt.getMonth()]}, ${dt.getFullYear()}`;
  };

  render() {
    const { status, items } = this.state;

    if (status === "loading")
      return (
        <RssFeed>
          <GenericLoader />
        </RssFeed>
      );
    if (status === "error") return <div>ERROR</div>;

    return (
      <RssFeed>
        <RssFeedList>
          {items.map((item, i) => (
            <RssFeedNew key={item.isoDate + i}>
              <div>{this.formatDate(item.isoDate)}</div>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            </RssFeedNew>
          ))}
        </RssFeedList>
      </RssFeed>
    );
  }
}

/* TWITTER */
const TweeterTimeline = styled.div`
  padding: 0.5rem;
  height: 300px;
  overflow-y: scroll;
`;

const Twitter = () => (
  <TweeterTimeline>
    <Timeline
      dataSource={{
        sourceType: "profile",
        screenName: "espabrok",
      }}
      options={{
        username: "Espabrok",
        // height: '400'
      }}
      // onLoad={() => console.log('Timeline is loaded!')}
    />
  </TweeterTimeline>
);

/* FACEBOOK */
const FacebookTimeline = styled.div`
  overflow-x: hidden;
`;

class Facebook extends Component {
  state = {
    paneWidth: null,
  };

  resizeTimeout = null;

  componentDidMount() {
    this.setState({
      paneWidth: document.getElementById("facebook-pane").clientWidth - 30,
    });
    window.addEventListener("resize", this.resizeOperations);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeOperations);
  }

  resizeOperations = (e) => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(this.updateDimensions, 1000);
  };

  updateDimensions = (e) => {
    this.setState({
      paneWidth: document.getElementById("facebook-pane").clientWidth - 30,
    });
  };

  render() {
    const { paneWidth } = this.state;
    if (!paneWidth) return null;

    return (
      <FacebookTimeline>
        <iframe
          src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FEspabrok%2F&tabs=timeline&width=${paneWidth}&height=310&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId`}
          width={paneWidth}
          height="310"
          title="facebook-timeline"
          style={{
            border: "none",
            overflow: "hidden",
          }}
          scrolling="no"
          frameBorder="0"
          allow="encrypted-media"
        />
      </FacebookTimeline>
    );
  }
}

const panes = [
  {
    menuItem: { key: "rss", content: "Actualidad" },
    render: () => (
      <Tab.Pane style={{ paddingBottom: "2rem" }}>
        <Rss />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      key: "twitter",
      icon: { name: "twitter", size: "large" },
      style: { paddingTop: "0.7em", paddingBottom: "0.7em" },
    },
    render: () => (
      <Tab.Pane style={{ paddingBottom: "2rem" }}>
        <Twitter />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      key: "facebook",
      icon: { name: "facebook", size: "large" },
      style: { paddingTop: "0.7em", paddingBottom: "0.7em" },
    },
    render: () => (
      <Tab.Pane style={{ paddingBottom: "1rem" }} id="facebook-pane">
        <Facebook />
      </Tab.Pane>
    ),
  },
];

class WidgetNews extends Component {
  render() {
    return <Tab panes={panes} />;
  }
}

export default WidgetNews;
