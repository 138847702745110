import React from "react";
import { List } from "semantic-ui-react";

const AlquileresMutuaDePropietarios = () => {
  return (
    <List bulleted>
      <List.Item>
        <strong>COMPAÑÍA:</strong> Mutua de Propietarios.
      </List.Item>
      <List.Item>
        <strong>EMISIÓN:</strong> Producto centralizado.
      </List.Item>
      <List.Item>
        <strong>OBJETIVO:</strong> Impago de alquileres
      </List.Item>
      <List.Item>
        <strong>OPERATIVA DE EMISION:</strong> enviar la documentación a{" "}
        <a href="mailto:joseluis.navarro@espabrok.com">
          joseluis.navarro@espabrok.com
        </a>
        .
      </List.Item>
      <List.Item>
        <strong>COMISIÓN:</strong> 20% NP y 20% CA
      </List.Item>
      <List.Item>
        <strong>VENTAJAS:</strong> Os resumimos algunas de las ventajas de este
        acuerdo centralizado.
        <List.List>
          <List.Item>
            <strong>Tasa:</strong> Para 12 meses de cobertura + 1 mes de
            franquicia, la tasa es del 4,02% de la renta anual + Consorcio con
            forma de pago anual.
          </List.Item>
          <List.Item>
            <strong>Garantías:</strong> impago de rentas + defensa jurídica +
            actos vandálicos continente (3.000) + pérdida de alquileres por
            incendio, explosión y caída de rayo + STP + sustitución de
            cerraduras. Resto de garantías serán de contratación opcional.
          </List.Item>
        </List.List>
      </List.Item>
      <List.Item>
        <strong>DOCUMENTACIÓN NECESARIA:</strong> Es <u>obligatorio</u>{" "}
        cumplimentar el documento de plantilla física por cada inquilino o
        avalista y adjuntar fotocopia del DNI, NIE o pasaporte.
        <br />
        Aun aportando el documento debidamente cumplimentado también la compañía
        podría solicitar:
        <List.List>
          <List.Item>
            <strong>En el caso de Asalariados</strong>
            <List.List>
              <List.Item>
                Dos últimas nóminas del arrendatario/s y del avalista/s (en su
                caso) y acreditación de su antigüedad en la empresa.
              </List.Item>
              <List.Item>
                En caso de inquilino y/o avalistas asalariados con contratos
                temporales, remitir informe de “vida laboral”.
              </List.Item>
            </List.List>
          </List.Item>
          <List.Item>
            <strong>En caso de autónomos:</strong>
            <List.List>
              <List.Item>
                Acreditación de la fecha de alta como autónomo en la empresa.
              </List.Item>
              <List.Item>Última declaración de la renta.</List.Item>
              <List.Item>
                Última declaración del I.V.A, de ingresos a cuenta del IRPF o
                cualquier otro justificante que acredite que continúa con la
                actividad en la actualidad.
              </List.Item>
            </List.List>
          </List.Item>
          <List.Item>
            <strong>En caso de jubilado:</strong>
            <List.List>
              <List.Item>Último justificante de la pensión.</List.Item>
            </List.List>
          </List.Item>
          <List.Item>
            <strong>En caso de Sociedad o Asociación:</strong>
            <List.List>
              <List.Item>Últimas Cuentas Anuales.</List.Item>
              <List.Item>Cuenta de Pérdidas y Ganancias.</List.Item>
            </List.List>
          </List.Item>
        </List.List>
      </List.Item>
      <List.Item>
        En todos los casos se deberá remitir también:
        <List>
          <List.Item>
            Documento de identidad de los arrendatarios y avalistas, en su caso.
          </List.Item>
          <List.Item>
            Justificante de subsidios familiares y otros que satisface si los
            hay.
          </List.Item>
          <List.Item>Contrato de alquiler o proforma.</List.Item>
          <List.Item>
            En caso de contratos de alquiler antiguos, escrito de no morosidad
            conforme no ha habido impagos ni retrasos en el pago de las rentas
            de alquiler por parte de los arrendatarios.
          </List.Item>
        </List>
      </List.Item>
    </List>
  );
};

export default AlquileresMutuaDePropietarios;
