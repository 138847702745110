import React, { Component } from "react";
import { List } from "semantic-ui-react";

class VidaCalife extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO DESCENTRALIZADO</strong>. Para la apertura de código,
          hay que rellenar la solicitud de Alta y remitirla al comercial de la
          zona.
        </List.Item>
      </List>
    );
  }
}

export default VidaCalife;
