import React, { Component } from "react";
import { Link } from "@reach/router";
import Calendar from "react-calendar";
import format from "date-fns/format";
import { es } from "date-fns/locale";
import parseISO from "date-fns/parseISO";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import getTime from "date-fns/getTime";
import isSameDay from "date-fns/isSameDay";
import getMonth from "date-fns/getMonth";
import isAfter from "date-fns/isAfter";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import compareAsc from "date-fns/compareAsc";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

import {
  Tab,
  Dropdown,
  Popup,
  Button,
  Label,
  Icon,
  Header,
} from "semantic-ui-react";
import styled from "styled-components";

import { withUserContext, withApiContext } from "../Context";
import WidgetLinkButton from "./WidgetLinkButton";
import { GenericLoader } from "../UI";

import { CLOUDCALENDARSREFRESH } from "../../globals";

const NEXTEVENTSNUMBEROFDAYS = 5;
const NEXTEVENTSDAYSNAMES = ["hoy", "mañana"];

// Styles to apply to the calendar and to the days that has events.
const calendarStyles = {
  width: "auto !important",
  border: "1px solid #ccc !important",
  marginBottom: "1.4rem",
  "& .react-calendar__tile--active, & .react-calendar__tile--active time": {
    background: "#006edc !important",
  },
};
const dayWithEventsStyles = {
  color: "#ffffff",
  background: "rgb(44, 80, 129)",
};

// For one event, check if I've been invited and if my confirmation is pending.
// const eventHasPendingConfirmation = (userEmail, event) => {
//   const attendees = Object.keys(event.attendee)

//   if (attendees.length === 0) return false

//   return (
//     attendees.includes(`mailto:${userEmail}`) &&
//     event.attendee[`mailto:${userEmail}`].partstat === 'NEEDS-ACTION'
//   )
// }

// For one event, check if I've been invited and if my confirmation is pending.
// const eventIsOrganizedByMe = (userEmail, event) => {
//   return event.organizer === `mailto:${userEmail}`
// }

/**
 * Component for showing a cloud calendar event.
 *
 * @class CloudEvent
 */
const CloudEventContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  & .event-time {
    flex: 0 1 auto;
    min-width: 40px;
    margin-right: 20px;
    font-size: 1.1rem;
    text-align: center;
    & .event-start-day {
      color: #bbb;
      font-size: 0.85em;
      line-height: 1.1;
    }
  }
  & .event-data {
    flex: 1 1 0%;
  }
  & .event-data__title-bar {
    & button {
      display: block !important;
      width: 100% !important;
      margin: 0 0 0.4rem 0 !important;
      white-space: nowrap;
    }
  }
  & .event-data .event-title {
    font-size: 1.15em;
    font-weight: bold;
  }
  & .event-data .event-dates {
    color: #bbb;
    font-style: italic;
    line-height: 1;
  }
  /* & .event-data .event-attendees {
    font-size: 1em;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    & .event-attendees__status {
      flex: 1 1 auto;
    }
  } */
  & .event-data .event-calendar {
    font-size: 1em;
  }
  & .event-data .event-location {
    margin-top: 0.2rem;
  }
  & .event-data .event-description {
    word-break: break-word;
    color: #bbb;
    margin-top: 0.4rem;
    line-height: 1;
  }
  & .event-data .registration-confirmation {
    margin: 0.4rem 0 !important;
    width: 100% !important;
    text-align: center !important;
  }
  @media (min-width: 450px) {
    & .event-data__title-bar {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      & button {
        width: auto !important;
        margin: 0 0 0 0.4rem !important;
      }
    }
    & .event-data .registration-confirmation {
      width: auto !important;
    }
  }
`;
const CloudEvent = React.memo(
  ({ user, event, selectedDay, showEventCalendar, ...restProps }) => {
    const isAllDayEvent = !event.dtstart.split("T")[1];
    const isMultidayEvent = !isSameDay(
      parseISO(event.dtstart),
      parseISO(event.dtend)
    );
    const dateFormat = isAllDayEvent
      ? "dd/MM/yyyy"
      : isMultidayEvent
      ? "dd/MM/yyyy HH:mm"
      : "HH:mm";
    const startTime = isAllDayEvent
      ? "*"
      : format(parseISO(event.dtstart), "HH:mm");

    // const attendees = Object.keys(event.attendee)
    // const [acceptedAttendees, rejectedAttendees, pendingAttendees] = attendees.reduce(
    //   (t, attendee) => {
    //     const eventUserStatus = event.attendee[attendee].partstat
    //     t[eventUserStatus === 'ACCEPTED' ? 0 : eventUserStatus === 'DECLINED' ? 1 : 2].push(
    //       attendee.replace(/^mailto:/, '')
    //     )

    //     return t
    //   },
    //   [[], [], []]
    // )

    return (
      <CloudEventContainer {...restProps}>
        <div className="event-time">
          {startTime}
          {!isSameDay(parseISO(event.dtstart), selectedDay) && (
            <div className="event-start-day">
              {format(parseISO(event.dtstart), "d MMM", {
                locale: es,
              }).toUpperCase()}
            </div>
          )}
        </div>
        <div className="event-data">
          <div className="event-data__title-bar">
            <div>
              <div className="event-title">{event.summary}</div>
              {(!isAllDayEvent || isMultidayEvent) && (
                <div className="event-dates">
                  {format(parseISO(event.dtstart), dateFormat)} -{" "}
                  {format(parseISO(event.dtend), dateFormat)}
                </div>
              )}
              {showEventCalendar && (
                <div className="event-calendar">
                  <Icon
                    name="calendar alternate"
                    style={{ color: event.color }}
                  />
                  {event.calendar}
                </div>
              )}
              {event.is_registered === true ? (
                <Label
                  className="registration-confirmation"
                  basic
                  color="green"
                  size="small"
                  content="Asistencia confirmada"
                />
              ) : event.has_registration === true ? (
                <Label
                  className="registration-confirmation"
                  basic
                  color="red"
                  size="small"
                  content="Requiere inscripción"
                />
              ) : null}
            </div>
            {event.espaevent_id !== null && (
              <Link to={`/evento/${event.espaevent_id}`}>
                <Button compact size="mini" color="blue" content="Saber más" />
              </Link>
            )}
          </div>
          {event.location && (
            <div className="event-location">
              <Icon name="point" style={{ color: "#666" }} />
              {event.location}
            </div>
          )}
          {event.description && (
            <div className="event-description">{event.description}</div>
          )}
        </div>
      </CloudEventContainer>
    );
  }
);

/**
 * Component for showing the cloud calendar.
 *
 * @class NextCloudCalendar
 */
const CloudCalendarContainer = styled.div`
  min-height: 0;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
`;
const CurrentCloudCalendar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-bottom: 0.6rem;
  & .cloud-calendar-select {
    flex: 1 1 0%;
    z-index: 500;
  }
  & .cloud-calendar-button {
    margin-left: 0.4rem !important;
    margin-right: 0 !important;
  }
`;
const CloudEventsContainer = styled.div`
  flex: 1 1 0%;
  padding-right: 0.8rem;
  overflow-y: visible;
  @media (min-width: 768px) {
    overflow-x: auto;
    overflow-y: auto;
  }
`;
const CloudEventsOnSelectedDay = styled.div`
  margin-bottom: 2rem;
`;
const CloudEventsHeader = styled.div`
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #999;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  & .next-events-title {
    font-size: 1.4rem;
  }
`;
const CloudNextEventsContainer = styled.div`
  & .next-event-day-header {
    font-style: italic;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
  }
`;
const CloudNextEventsDayContainer = styled.div`
  margin-bottom: 0.6rem;
`;

class NextCloudCalendar extends Component {
  state = {
    noAuthData: false,
    isLoadingCalendars: true,
    calendars: [],
    selectedCalendar: null,
    isLoadingEvents: false,
    eventsGroupedByDay: {},
    daysWithEvents: {},
    selectedDay: null,
    activeStartDate: startOfMonth(new Date()),
  };

  refreshInterval = null;

  componentDidMount() {
    this.loadCalendars();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCalendar !== this.state.selectedCalendar)
      this.loadEvents(this.state.selectedCalendar);
  }

  componentWillUnmount() {
    clearTimeout(this.refreshInterval);
  }

  handleSelectCalendar = (e, { value }) => {
    this.setState({
      selectedCalendar: value,
      eventsGroupedByDay: {},
      daysWithEvents: {},
    });
  };

  handleDaySelection = (dtDay) => {
    this.setState((prevState) => ({
      selectedDay: !isSameDay(prevState.selectedDay, dtDay) ? dtDay : null,
      activeStartDate: !isSameDay(prevState.selectedDay, dtDay)
        ? startOfMonth(dtDay)
        : prevState.activeStartDate,
    }));
  };

  handleActiveStartDateChange = ({ activeStartDate, view }) => {
    this.setState({ activeStartDate });
  };

  handleMonthChange = (dtDay) => {
    this.setState({ activeStartDate: dtDay });
  };

  loadCalendars = () => {
    this.props.api
      .getRequest(`/calendars`)
      .then(({ data }) => {
        this.setState({
          isLoadingCalendars: false,
          calendars: data.data,
          selectedCalendar: data.data.length > 0 ? "all-calendars" : null,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingCalendars: false,
          noAuthData:
            error.response &&
            error.response.data &&
            error.response.data.code === 403
              ? true
              : false,
        });
      });
  };

  loadEvents = (calendarId) => {
    // In case is a calendar change, cancel the next automatic refresh.
    clearTimeout(this.refreshInterval);

    this.setState({ isLoadingEvents: true });

    const selectedCalendars =
      calendarId === "all-calendars"
        ? this.state.calendars.map((calendar) => calendar.id)
        : [this.state.selectedCalendar];

    selectedCalendars.forEach((calId) => {
      this.props.api
        .getRequest(`/events?calendar=${calId}`)
        .then(({ data }) => {
          // We need to group the events per day, so we'll be able to click in
          // one day and show all its events quickly.
          let eventsGroupedByDay = {};
          let daysWithEvents = {};

          data.data.forEach((event) => {
            // First we need to get all the days convered by this event.
            const isAllDayEvent = !event.dtstart.split("T")[1];
            const dtEventStart = isAllDayEvent
              ? startOfDay(parseISO(event.dtstart))
              : parseISO(event.dtstart);
            const dtEventEnd = isAllDayEvent
              ? subDays(startOfDay(parseISO(event.dtend)), 1)
              : parseISO(event.dtend);

            const eventDays = !isAfter(dtEventStart, dtEventEnd)
              ? eachDayOfInterval({ start: dtEventStart, end: dtEventEnd })
              : [];

            eventDays.forEach((day) => {
              if (!eventsGroupedByDay[day]) eventsGroupedByDay[day] = [];

              // If is a multiday event, we have to substract one day to end date,
              // because the API is adding one day.
              if (isAllDayEvent)
                eventsGroupedByDay[day].push({
                  ...event,
                  dtend: format(subDays(parseISO(event.dtend), 1), "yyyyMMdd"),
                });
              else eventsGroupedByDay[day].push(event);

              // Because we're using an external component to show the calendar,
              // we need to use its internal classes to set a different background
              // for the days that has events.
              const dayCssSelector = `& .react-calendar__month-view__days__day.day${format(
                day,
                "yyyyMMdd"
              )}`;
              if (!daysWithEvents[dayCssSelector])
                daysWithEvents[dayCssSelector] = {
                  ...dayWithEventsStyles,
                  ...(this.state.selectedCalendar === "all-calendars"
                    ? {
                        color: "#000",
                        border: `1px solid #666`,
                        background: "#fff",
                      }
                    : {
                        background: event.color || "#ccc",
                      }),
                  // ...(eventHasPendingConfirmation(this.props.user.userEmail, event)
                  //   ? { color: '#fff', background: '#db2828' }
                  //   : {}),
                };
            });
          });

          this.setState((prevState) => ({
            isLoadingEvents: false,
            eventsGroupedByDay: Object.keys(eventsGroupedByDay).reduce(
              (t, day) => {
                if (!t[day]) t[day] = [];

                eventsGroupedByDay[day].forEach((newEvent) => {
                  const eventIndex = t[day].findIndex(
                    (savedEvent) => savedEvent.uid === newEvent.uid
                  );

                  if (eventIndex === -1) t[day] = [...t[day], newEvent];
                  else t[day][eventIndex] = newEvent;
                });

                return t;
              },
              prevState.eventsGroupedByDay
            ),
            daysWithEvents: { ...prevState.daysWithEvents, ...daysWithEvents },
          }));
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoadingEvents: false });
        });
    });

    // Set the timeout for the next automatic refresh.
    this.refreshInterval = setTimeout(
      () => this.loadEvents(this.state.selectedCalendar),
      CLOUDCALENDARSREFRESH * 1000
    );
  };

  render() {
    const {
      noAuthData,
      isLoadingCalendars,
      calendars,
      selectedCalendar,
      isLoadingEvents,
      eventsGroupedByDay,
      daysWithEvents,
      selectedDay,
      activeStartDate,
    } = this.state;

    if (isLoadingCalendars)
      return (
        <CloudCalendarContainer>
          <GenericLoader />
        </CloudCalendarContainer>
      );

    if (noAuthData)
      return (
        <CloudCalendarContainer style={{ justifyContent: "center" }}>
          <Header as="h3" textAlign="center" icon>
            <Icon name="exclamation" />
            Error de autenticación en Cloud
            <Header.Subheader>
              Revisa <Link to="/perfil">tu perfil</Link> y verifica que tu
              nombre de usuario y contraseña de Cloud son correctos
            </Header.Subheader>
          </Header>
        </CloudCalendarContainer>
      );

    // Use an styled component to add all the days that has events, so it
    // will apply the corresponding classes and show the calendar
    // background for those days.
    const StyledCalendar = styled(Calendar)({
      ...calendarStyles,
      ...daysWithEvents,
    });

    // Create calendar list to feed the dropdown and add an 'all calendars' option.
    const calendarsListForDropdown = [
      {
        key: "all-calendars",
        value: "all-calendars",
        text: (
          <div>
            <Label
              empty
              circular
              style={{
                border: `1px solid #666`,
                background: "#fff",
                marginRight: "0.6rem",
                verticalAlign: "-10%",
              }}
            />
            Todos los calendarios
          </div>
        ),
      },
      ...calendars.map((calendar) => ({
        key: calendar.id,
        value: calendar.id,
        text: (
          <div>
            <Label
              empty
              circular
              style={{
                border: `1px solid ${calendar.color}`,
                background: calendar.color,
                marginRight: "0.6rem",
                verticalAlign: "-10%",
              }}
            />
            {calendar.name}
          </div>
        ),
      })),
    ];

    // Get next 5 days events (grouped by day).
    const dtToday = startOfDay(new Date());
    const nextEventsDays = Object.keys(eventsGroupedByDay)
      .map((strDay) => new Date(strDay))
      .filter((dtDay) => {
        return compareAsc(dtDay, dtToday) !== -1;
      })
      .sort(compareAsc)
      .slice(0, NEXTEVENTSNUMBEROFDAYS);

    return (
      <CloudCalendarContainer>
        {/* CALENDAR SELECTOR AND CLOUD LINK*/}
        <CurrentCloudCalendar>
          <Dropdown
            fluid
            selection
            selectOnBlur={false}
            className="cloud-calendar-select"
            loading={isLoadingEvents}
            options={calendarsListForDropdown}
            value={selectedCalendar}
            onChange={this.handleSelectCalendar}
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://cloud.espabrok.es/index.php/apps/calendar/`}
          >
            <Popup
              trigger={
                <Button icon="cloud" className="cloud-calendar-button" />
              }
              content="Abrir en el cloud"
            />
          </a>
        </CurrentCloudCalendar>

        {/* CALENDAR */}
        <StyledCalendar
          tileClassName={({ date, view }) =>
            view === "month" ? format(date, "'day'yyyyMMdd") : null
          }
          onClickDay={this.handleDaySelection}
          onClickMonth={this.handleMonthChange}
          onActiveDateChange={this.handleActiveStartDateChange}
          activeStartDate={activeStartDate}
          {...(getMonth(selectedDay) === getMonth(activeStartDate)
            ? { value: selectedDay }
            : {})}
        />

        <CloudEventsContainer>
          {/* EVENTS ON SELECTED DAY */}
          {selectedDay && (
            <CloudEventsOnSelectedDay>
              <CloudEventsHeader>
                Eventos del {format(selectedDay, "PPPP", { locale: es })}
              </CloudEventsHeader>
              <div>
                {!eventsGroupedByDay[selectedDay] ? (
                  <div style={{ textAlign: "center", fontStyle: "italic" }}>
                    Sin eventos para la fecha seleccionada
                  </div>
                ) : (
                  eventsGroupedByDay[selectedDay]
                    .sort(
                      (a, b) =>
                        getTime(parseISO(a.dtstart)) -
                        getTime(parseISO(b.dtstart))
                    )
                    .map((event, i) => (
                      <CloudEvent
                        key={event.dtstart + i}
                        user={this.props.user}
                        event={event}
                        selectedDay={selectedDay}
                        showEventCalendar={selectedCalendar === "all-calendars"}
                      />
                    ))
                )}
              </div>
            </CloudEventsOnSelectedDay>
          )}

          {/* NEXT EVENTS */}
          <CloudEventsHeader>
            <div className="next-events-title">Próximos eventos</div>
          </CloudEventsHeader>
          <CloudNextEventsContainer>
            {!isLoadingCalendars &&
            !isLoadingEvents &&
            nextEventsDays.length === 0 ? (
              <div style={{ textAlign: "center", fontStyle: "italic" }}>
                Sin eventos en los próximos días
              </div>
            ) : (
              nextEventsDays.map((dtDay) => {
                const daysDifference = differenceInCalendarDays(dtDay, dtToday);
                return (
                  <CloudNextEventsDayContainer key={dtDay}>
                    <div className="next-event-day-header">
                      {NEXTEVENTSDAYSNAMES[daysDifference] ||
                        format(dtDay, "PPPP", { locale: es })}
                    </div>
                    {eventsGroupedByDay[dtDay].map((event, i) => (
                      <CloudEvent
                        key={event.dtstart + i}
                        user={this.props.user}
                        event={event}
                        selectedDay={selectedDay}
                        showEventCalendar={selectedCalendar === "all-calendars"}
                      />
                    ))}
                  </CloudNextEventsDayContainer>
                );
              })
            )}
          </CloudNextEventsContainer>
        </CloudEventsContainer>
      </CloudCalendarContainer>
    );
  }
}

class WidgetCalendar extends Component {
  panes = [
    {
      menuItem: "Calendario",
      render: () => (
        <Tab.Pane
          style={{
            padding: "2rem 2rem 2.6rem 2rem",
            height: "90%",
            flex: "1 1 0%",
            minHeight: "0",
          }}
        >
          <NextCloudCalendar api={this.props.api} user={this.props.user} />
        </Tab.Pane>
      ),
    },
  ];

  render() {
    const { id, ...restProps } = this.props;
    return (
      <div style={{ position: "relative" }} id={id}>
        <WidgetLinkButton
          link="https://cloud.espabrok.es/index.php/apps/calendar/"
          text="Ir al Calendario"
          icon="cloud"
        />
        <Tab
          panes={this.panes}
          {...restProps}
          style={{
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
          }}
        />
      </div>
    );
  }
}

export default withUserContext(withApiContext(WidgetCalendar));
