import React, { Component } from "react";
import { Link } from "@reach/router";
import { Tab, Image, Popup, Header, Icon } from "semantic-ui-react";
import styled from "styled-components";

import { withUserContext, withApiContext } from "../Context";
import { GenericLoader } from "../UI";

const CompaniesContainer = styled.div`
  padding: 1rem 0 1rem 1rem;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  height:300px;
  @media (min-width: 768px) {
    height: 303px;
  }
  @media (min-width: 1280px) {
    height: 262px;
  }
`;
const Company = styled.a`
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  width: 31%;
  height: 80px;
  padding: 0 1rem;
  margin: 0.2rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  & img {
    max-width: 80px !important;
    max-height: 60px !important;
  }
  &:hover {
    transition: all 0.3s ease;
    background: #eee;
    & img {
      transition: all 0.3s ease;
      transform: scale(1.1);
    }
  }
`;

class CompaniesGrid extends Component {
  state = {
    isLoadingCompanies: true,
    companies: [],
  };

  componentDidMount() {
    this.loadCompanies();
  }

  loadCompanies = () => {
    this.props.api
      .getRequest("/companies")
      .then(({ data }) => {
        this.setState({
          isLoadingCompanies: false,
          companies: data.data,
        });
      })
      .catch((error) => {
        this.setState({ isLoadingItems: false });
        this.props.user.logoutUser();
      });
  };

  render() {
    const { isLoadingCompanies, companies } = this.state;

    if (isLoadingCompanies)
      return <GenericLoader style={{ marginTop: "2rem" }} />;

    if (companies.length === 0)
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Header as="h3" textAlign="center" icon>
            <Icon name="exclamation" />
            Ninguna compañía seleccionada
            <Header.Subheader>
              Revisa <Link to="/perfil">tu perfil</Link> y selecciona las
              compañías aseguradoras con las que trabajas habitualmente, para
              tener disponibles aquí sus enlaces.
            </Header.Subheader>
          </Header>
        </div>
      );

    return (
      <CompaniesContainer>
        {companies.map((company) => (
          <Popup
            key={company.id}
            trigger={
              <Company
                href={company.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {company.logo ? (
                  <Image src={company.logo} />
                ) : (
                  <div>{company.name}</div>
                )}
              </Company>
            }
            content={company.friendly_name}
          />
        ))}
      </CompaniesContainer>
    );
  }
}

class WidgetCompanies extends Component {
  panes = [
    {
      menuItem: "Compañías",
      render: () => (
        <Tab.Pane style={{ paddingBottom: "2rem" }}>
          <CompaniesGrid user={this.props.user} api={this.props.api} />
        </Tab.Pane>
      ),
    },
  ];

  render() {
    const {id} = this.props
    return (<div id={id}><Tab panes={this.panes} /></div>);
  }
}

export default withUserContext(withApiContext(WidgetCompanies));
