import React, { Component } from "react";
import { List } from "semantic-ui-react";

class MascotasLiberty extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>Acuerdo descentralizado</strong>.
        </List.Item>
      </List>
    );
  }
}

export default MascotasLiberty;
