import React from "react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  position: absolute;
  top: 0.3rem;
  right: 0;
  margin-right: 0 !important;
  color: #444 !important;
  background: #ccc !important;
  &:hover {
    background: #bbb !important;
  }
`;

const WidgetLinkButton = ({ text, link, ...restProps }) => (
  <StyledButton
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    compact
    content={text}
    {...restProps}
  />
);

export default WidgetLinkButton;
