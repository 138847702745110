import React, { Component } from "react";
import { List } from "semantic-ui-react";

class DañosGenerali extends Component {
  render() {
    return (
      <div>
        <strong>MEJORAS EXCLUSIVAS PRODUCTO DE DAÑOS GENERALI.</strong>
        <br />
        <p>
          Clausulado especial que se refleja al aplicar el correspondiente
          código de flota.
        </p>
        <strong>ACTIVACIÓN</strong>
        <p>Se puede activar de la siguiente forma:</p>
        <List ordered>
          <List.Item>
            Directamente la Correduría asociada; para ello la Correduría debe
            indicar el código de flota a Generali, y poner en cláusula manual el
            redactado de las cláusulas acordadas y que se adjuntan en el
            documento pdf.
          </List.Item>
          <List.Item>
            El Centro de empresas de Generali; el Centro tiene toda la
            información de los códigos de flota, por lo que cuando la Correduría
            asociada remita un correo solicitando la inclusión del clausulado,
            el Centro de empresas ya saben qué código aplicar.
          </List.Item>
        </List>
        <br />
        <p>
          <strong>A tener en cuenta </strong> que el texto del pdf adjunto
          recoge las cláusulas que deben pegarse en cláusula manual, por eso la
          cláusula relativa a VEHICULOS DE CLIENTES Y EMPLEADOS, no aparece en
          la relación porque se encuentra informatizada, por tanto, al insertar
          los códigos de flota en el sistema automáticamente se añadirá esta
          cláusula.
        </p>
      </div>
    );
  }
}

export default DañosGenerali;
