import React, { Component } from "react";
import { List } from "semantic-ui-react";

class AsistenciaenviajesIntermundial extends Component {
  render() {
    return (
      <div>
        <strong>EMISIÓN</strong>: Producto descentralizado.
        <br />
        <br />
        <strong>OPERATIVA</strong>:
        <List bulleted>
          <List.Item>
            Hay que cumplimentar y firmar los documentos abajo detallados junto
            con copia de la J de la Correduria enviarlos a Mario Sanchez:
            <a href="mailto:mario.sanchez@espabrok.com">
              mario.sanchez@espabrok.com
            </a>
            .
          </List.Item>
          <List.Item>
            Posteriormente Intermundial remite por correo electrónico las claves
            de USUARIO y CONTRASEÑA para poder acceder al portal de
            contratación.
          </List.Item>
        </List>
      </div>
    );
  }
}

export default AsistenciaenviajesIntermundial;
