import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Tab, Icon, Image, Reveal, Label } from "semantic-ui-react";
import styled from "styled-components";

import { withUserContext, withApiContext } from "../Context";
import TableBackEnd from "@bit/rigobauer.iteisa-components.semantic-ui.table-back-end";
import WidgetLinkButton from "./WidgetLinkButton";

import { APIBASEURL, APIREQUESTOPTIONS, TASKSTATES } from "../../globals";

const QuotesContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  & .inner-container {
    flex-basis: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
const Quote = styled.a`
  display: block;
  border: 1px solid #ccc;
  margin: 0.2rem;
  position: relative;
  width: 47.5%;
  height: 60px;
  & .quote-logo {
    padding: 0.6rem;
    width: 100%;
    max-height: 50px;
  }
  & .logo-replacement {
    text-align: center;
    font-weight: bold;
  }
  & .task-state {
    text-align: center;
    color: #fff;
  }
  & .ui.left.corner.label:after {
    border-top-width: 1.8em;
    border-right-width: 1.8em;
  }
  @media (min-width: 500px) {
    width: 31.8%;
  }
  @media (min-width: 769px) {
    width: auto;
    & .quote-logo {
      width: 100px;
    }
  }
`;

class TarifEspabrokRisks extends Component {
  url = `${APIBASEURL}/risks`;

  headerPc = {
    id: { text: "Id", collapsing: true },
    risk: { text: "Riesgo" },
    product: { text: "Producto" },
    quotes: { text: "Presupuestos", width: 11 },
  };
  headerCellphone = {
    id: { text: "Id", collapsing: true },
    risk: { text: "Riesgo y Presupuestos", width: 16 },
  };

  dataExtractionPc = (data) => {
    return Object.values(data.data).map((v, i) => ({
      id: v.id,
      risk: (
        <div style={{ whiteSpace: "nowrap", fontSize: "1rem" }}>
          <div>
            <strong>{v.name}</strong>
          </div>
          {v.client && (
            <div style={{ color: "#999", fontSize: "0.95rem" }}>{v.client}</div>
          )}
        </div>
      ),
      product: v.product && (
        <div style={{ whiteSpace: "nowrap" }}>
          <Icon name={v.product.icon} /> {v.product.name}
        </div>
      ),
      quotes: v.quotes && v.quotes.length > 0 && (
        <QuotesContainer>
          <div className="inner-container">
            {v.quotes.map((quote) => (
              <Quote
                key={quote.id}
                href={quote.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Reveal animated="move" style={{ height: "100%" }}>
                  <Reveal.Content visible style={{ height: "100%" }}>
                    <div
                      style={{
                        height: "100%",
                        background: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Label
                        corner="left"
                        style={{
                          width: "5px",
                          borderColor: TASKSTATES[quote.status].color,
                        }}
                      />
                      {quote.portal.logo ? (
                        <Image src={quote.portal.logo} className="quote-logo" />
                      ) : (
                        <div className="logo-replacement">
                          {quote.portal.name}
                        </div>
                      )}
                    </div>
                  </Reveal.Content>
                  <Reveal.Content
                    hidden
                    style={{
                      background: TASKSTATES[quote.status].color,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="task-state">
                      {TASKSTATES[quote.status].name}
                    </div>
                  </Reveal.Content>
                </Reveal>
              </Quote>
            ))}
          </div>
        </QuotesContainer>
      ),
    }));
  };

  dataExtractionCellphone = (data) => {
    return Object.values(data.data).map((v, i) => ({
      id: v.id,
      risk: (
        <div style={{ textAlign: "center", margin: "1rem 0" }}>
          <div>
            <strong>{v.name}</strong>
          </div>
          {v.client && (
            <div style={{ color: "#999", fontSize: "0.95rem" }}>{v.client}</div>
          )}
          <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Icon name={v.product.icon} /> {v.product.name}
          </div>
          <div>
            {v.quotes && v.quotes.length > 0 && (
              <QuotesContainer>
                <div className="inner-container">
                  {v.quotes.map((quote) => (
                    <Quote
                      key={quote.id}
                      href={quote.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        style={{
                          height: "100%",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Label
                          corner="left"
                          style={{
                            width: "5px",
                            borderColor: TASKSTATES[quote.status].color,
                          }}
                        />
                        {quote.portal.logo ? (
                          <Image
                            src={quote.portal.logo}
                            className="quote-logo"
                          />
                        ) : (
                          <div className="logo-replacement">
                            {quote.portal.name}
                          </div>
                        )}
                      </div>
                    </Quote>
                  ))}
                </div>
              </QuotesContainer>
            )}
          </div>
        </div>
      ),
    }));
  };

  totalPagesExtraction = (data) => data.pagination.total_pages;

  handleApiRequestError = (error) => {
    console.log(error);
    this.props.user.logoutUser();
  };

  render() {
    return (
      <MediaQuery minWidth={769}>
        {(matches) => (
          <TableBackEnd
            compact
            unstackable
            url={this.url}
            apiRequestOptions={APIREQUESTOPTIONS}
            header={matches ? this.headerPc : this.headerCellphone}
            dataExtraction={
              matches ? this.dataExtractionPc : this.dataExtractionCellphone
            }
            totalPagesExtraction={this.totalPagesExtraction}
            hasSearch={true}
            hasSort={false}
            hasPagination={true}
            defaultPageSize={5}
            handleApiRequestError={this.handleApiRequestError}
          />
        )}
      </MediaQuery>
    );
  }
}

class WidgetTarifEspabrok extends Component {
  panes = [
    {
      menuItem: "TarifEspabrok",
      render: () => (
        <Tab.Pane style={{ padding: "2rem" }}>
          <TarifEspabrokRisks user={this.props.user} api={this.props.api} />
        </Tab.Pane>
      ),
    },
  ];

  render() {
    const { id, ...restProps } = this.props;
    return (
      <div style={{ position: "relative" }} id={id}>
        <WidgetLinkButton
          link="https://tarif.espabrok.es/portada"
          text="Ir a TarifEspabrok"
          icon="calculator"
        />
        <Tab panes={this.panes} {...restProps} />
      </div>
    );
  }
}

export default withUserContext(withApiContext(WidgetTarifEspabrok));
