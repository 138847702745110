import React, { Component } from 'react'
import { List } from 'semantic-ui-react'

class DependenciaSurne extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO DESCENTRALIZADO</strong>. Para la apertura de código
          hay que realizar la petición a central.
        </List.Item>
        <List.Item>
          <strong>CONDICIONADO ESPECIAL “ESPABROK-DEPENDENCIA”</strong>.
        </List.Item>
        <List.Item>
          EDAD MÁXIMA DE ENTRADA AMPLIADA HASTA LOS 75 AÑOS para la opción de
          RENTA (para la opción de capital es de 70).
        </List.Item>
        <List.Item>
          REQUISITOS DE ADHESIÓN MEJORADOS RESPECTO A PRODUCTO ESTÁNDAR.
        </List.Item>
        <List.Item>
          <strong>COMISIONAMIENTO MEJORADO</strong> y SIN compromiso de
          producción: COMISIÓN DEL 40% Cartera y Nueva Producción.
        </List.Item>
        <List.Item>
          PRODUCTO <strong>PERSONALIZABLE</strong>.
        </List.Item>
      </List>
    )
  }
}

export default DependenciaSurne
