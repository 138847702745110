import React, { Component } from "react";
import { Tab } from "semantic-ui-react";
import styled from "styled-components";

import CloudFilesExplorer from "../CloudFilesExplorer";
import WidgetLinkButton from "./WidgetLinkButton";

const Container = styled.div`
  margin: 1rem 0.8rem 1rem 1rem;
  height: 275px;
  @media (min-width: 768px) {
    height: 559px;
  }
  @media (min-width: 1280px) {
    height: 290px;
  }
`;

class WidgetCloudFiles extends Component {
  panes = [
    {
      menuItem: { key: "mail", content: "Cloud" },
      render: () => (
        <Tab.Pane>
          <Container>
            <CloudFilesExplorer />
          </Container>
        </Tab.Pane>
      ),
    },
  ];

  render() {
    const { id, ...restProps } = this.props;
    return (
      <div style={{ position: "relative" }} id={id}>
        <WidgetLinkButton
          link="https://cloud.espabrok.es/index.php/apps/files/"
          text="Ir al Cloud"
          icon="cloud"
        />
        <Tab panes={this.panes} {...restProps} />
      </div>
    );
  }
}

export default WidgetCloudFiles;
