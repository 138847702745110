import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
import { Icon, Popup, Button, Header } from "semantic-ui-react";
import styled from "styled-components";

import { withApiContext } from "./Context";
import { GenericLoader } from "./UI";

import { CLOUDFILESREFRESH, APIBASEURL } from "../globals";

const formatFileSize = (fileSize) => {
  const fileSizeInBits = parseInt(fileSize, 10);
  return !fileSizeInBits
    ? `0 KB`
    : fileSizeInBits < 1024
    ? `${fileSizeInBits} B`
    : fileSizeInBits / 1024 < 1024
    ? `${Math.ceil(fileSizeInBits / 1024)} KB`
    : `${Math.round((10 * fileSizeInBits) / (1024 * 1024)) / 10} MB`;
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;
const CurrentCloudFolder = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 1rem;
  & .cloud-folder-route {
    flex: 1 1 0%;
    border: 1px solid transparent;
    border-radius: 0.2rem;
    background: #eee;
    padding: 0.4rem 0.6rem;
    word-break: break-all;
  }
  & .cloud-folder-button {
    flex: 0 1 0%;
    margin-left: 0.4rem !important;
  }
`;
const CloudItems = styled.div`
  flex: 1 1 auto;
  height: 100% !important;
  overflow-x: hidden;
  overflow-y: auto;
`;
const CloudFolder = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  margin-right: 0.4rem;
  &:hover {
    background: #eee;
    cursor: pointer;
  }
`;
const CloudFile = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: #000 !important;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  margin-right: 0.4rem;
  &:hover {
    background: #eee;
    cursor: pointer;
  }
`;
const CloudItemIcon = styled(Icon).attrs({
  size: "large",
})`
  flex: 0 1 auto;
  color: #444;
  margin-right: 1rem !important;
  /* vertical-align: -10% !important; */
`;
const CloudItemName = styled.div`
  flex: 1 1 0%;
  line-height: 1.6;
`;
const CloudItemSize = styled.div`
  flex: 0 1 auto;
  white-space: nowrap;
  color: #999;
  padding-left: 0.4rem;
`;

class CloudFilesExplorer extends Component {
  static propTypes = {
    baseFolder: PropTypes.string,
  };
  static defaultProps = {
    baseFolder: "",
  };

  state = {
    noAuthData: false,
    isLoadingItems: true,
    items: [],
    selectedItem: this.props.baseFolder || "",
  };

  refreshInterval = null;

  componentDidMount() {
    this.setState({ isLoadingItems: true });
    this.loadItems(this.props.baseFolder);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedItem !== this.state.selectedItem) {
      this.setState({ isLoadingItems: true });
      this.loadItems(this.state.selectedItem);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshInterval);
  }

  handleSelectFolder = (item) => {
    this.setState({ selectedItem: item });
  };

  loadItems = (folder = "") => {
    // In case is a folder change, cancel the next automatic refresh.
    clearTimeout(this.refreshInterval);

    this.props.api
      .getRequest(
        `/folders${
          encodeURIComponent(folder)
            ? `/content?folder=${encodeURIComponent(folder)}`
            : ""
        }`
      )
      .then(({ data }) => {
        this.setState({
          isLoadingItems: false,
          items: Object.keys(data.data)
            .sort((a, b) => {
              const aIsFile = data.data[a]["{DAV:}getcontenttype"];
              const bIsFile = data.data[b]["{DAV:}getcontenttype"];
              return !aIsFile && bIsFile ? -1 : aIsFile && !bIsFile ? 1 : 0;
            })
            .map((v) => ({
              name: decodeURIComponent(v.replace("/remote.php/files/", "")),
              ...data.data[v],
            })),
        });

        // Set the timeout for the next automatic refresh.
        this.refreshInterval = setTimeout(
          () => this.loadItems(this.state.selectedItem),
          CLOUDFILESREFRESH * 1000
        );
      })
      .catch((error) => {
        this.setState({
          isLoadingItems: false,
          noAuthData:
            error.response &&
            error.response.data &&
            error.response.data.code === 403
              ? true
              : false,
        });
      });
  };

  render() {
    const { baseFolder } = this.props;
    const { noAuthData, isLoadingItems, items, selectedItem } = this.state;

    if (noAuthData)
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Header as="h3" textAlign="center" icon>
            <Icon name="exclamation" />
            Error de autenticación en Cloud
            <Header.Subheader>
              Revisa <Link to="/perfil">tu perfil</Link> y verifica que tu
              nombre de usuario y contraseña de Cloud son correctos
            </Header.Subheader>
          </Header>
        </div>
      );

    const parentFolder = selectedItem
      ? `${selectedItem.split("/").slice(0, -2).join("/")}/`
      : null;

    return (
      <Container>
        <CurrentCloudFolder>
          <div className="cloud-folder-route">
            {selectedItem ? `/${selectedItem.replace(baseFolder, "")}` : "/"}
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://cloud.espabrok.es/index.php/apps/files/?dir=${encodeURIComponent(
              selectedItem
            )}`}
          >
            <Popup
              trigger={
                <Button compact icon="cloud" className="cloud-folder-button" />
              }
              content="Abrir en el cloud"
            />
          </a>
        </CurrentCloudFolder>
        <CloudItems>
          {isLoadingItems ? (
            <GenericLoader />
          ) : (
            items.map((item) => {
              // If is the base route, don't show the parent folder link.
              if (item.name === baseFolder || item.name === "") return null;

              // Obtain item name and folder route (if proceed).
              const itemName =
                item.name === selectedItem
                  ? ". ."
                  : item.name.replace(selectedItem, "");
              const itemRoute =
                item.name === selectedItem
                  ? parentFolder === "/"
                    ? ""
                    : parentFolder
                  : item.name;

              // Check if the item is a file or a folder, to show diferent icons
              // and to set the item as a div or as a link to the file.
              const isFile = item["{DAV:}getcontenttype"];
              const CloudItem = isFile ? CloudFile : CloudFolder;

              return (
                <CloudItem
                  key={item.name}
                  {...(isFile
                    ? {
                        target: "_blank",
                        rel: "noopener noreferrer",
                        href: `${APIBASEURL}/files/download?file=${encodeURIComponent(
                          item.name
                        )}`,
                      }
                    : { onClick: () => this.handleSelectFolder(itemRoute) })}
                >
                  <CloudItemIcon name={isFile ? "file" : "folder"} />
                  <CloudItemName>{itemName}</CloudItemName>
                  {item.name !== selectedItem && (
                    <CloudItemSize>
                      {formatFileSize(item["{http://owncloud.org/ns}size"])}
                    </CloudItemSize>
                  )}
                </CloudItem>
              );
            })
          )}
        </CloudItems>
      </Container>
    );
  }
}

export default withApiContext(CloudFilesExplorer);
