import React, { Component } from "react";
import { Header, List } from "semantic-ui-react";

class ProteccionempresasArag extends Component {
  render() {
    return (
      <div>
        <p>
          ARAG pone a disposición de los asociados de Espabrok el producto
          denominado "ARAG Negocios Basic Plus", producto de inclusión
          automática en todas las ofertas del producto de empresas, en
          condiciones especiales.
        </p>

        <Header as="h3">¿A quién se asegura por esta póliza?</Header>
        <List ordered>
          <List.Item>
            Son asegurados la persona física o la persona jurídica que actúe
            como profesional, autónomo o empresa y sea titular de la actividad
            descrita en las Condiciones Particulares de la póliza, y en su caso,
            sus socios mercantiles, sus familiares y la persona o personas en
            las que delegue la dirección de la empresa, durante el ejercicio de
            la actividad propia de la misma.
            <br />
            Cuando el titular de la empresa sea una Sociedad o Asociación, serán
            asimismo asegurados los miembros de su Consejo de Administración o
            Junta Rectora.
          </List.Item>
          <List.Item>
            Excepcionalmente y para las garantías en que así se señale, tendrán
            también la consideración de asegurados los asalariados debidamente
            inscritos en el régimen general de la Seguridad Social, del
            profesional, autónomo o empresa amparada por la póliza, mientras
            desempeñen su trabajo, siempre que se designen expresamente en las
            Condiciones Particulares en la forma que determine la propia
            garantía.
          </List.Item>
        </List>

        <Header as="h3">¿Cuál es el objeto del seguro?</Header>
        <p>
          Por el presente Contrato de Seguro, ARAG se obliga dentro de los
          límites establecidos en la Ley y en el contrato, a prestar al
          Asegurado los servicios de asistencia extrajudicial y a hacerse cargo
          de los gastos en que pueda incurrir el mismo, como consecuencia de su
          intervención en un procedimiento judicial, administrativo o arbitral,
          derivados de la cobertura del seguro.
        </p>
        <p>
          Asimismo, y dentro de los límites establecidos en el contrato, ARAG
          también se hará cargo de los gastos en que puedan incurrir los
          Asegurados como consecuencia de su intervención en un procedimiento de
          mediación, siempre que dicho procedimiento derive de un hecho
          garantizado en la póliza y se encuentre comprendido dentro del ámbito
          de aplicación de la Ley 5/2012, de 6 de julio, de mediación en asuntos
          civiles y mercantiles.
        </p>
        <p>
          También puede ser objeto del seguro la prestación de determinados
          servicios o el pago de estos, relacionados con las coberturas de la
          póliza.
        </p>

        <Header as="h3">¿Qué garantiza el seguro?</Header>
        <p>
          En esta póliza, ARAG garantiza la defensa jurídica de los intereses
          del Asegurado, en el ámbito de la actividad profesional, autónoma o
          empresarial descrita en las Condiciones Particulares.
        </p>
        <p>
          El local o locales destinados a dicha actividad quedarán incluidos en
          las coberturas contratadas, siempre que se designen expresamente en
          las Condiciones Particulares del presente contrato de seguro.
        </p>

        <Header as="h3">
          Primas totales{" "}
          <small>
            (se mejoran sustancialmente a las que figuran inicialmente en el
            fichero proyecto ARAG NEGOCIO BASIC PLUS)
          </small>
        </Header>
        <List bulleted>
          <List.Item>Hasta 5 empleados: 52,42€</List.Item>
          <List.Item>De 6 a 10 empleados: 94,80€</List.Item>
          <List.Item>De 11 a 20 empleados: 197,55€</List.Item>
          <List.Item>De 21 a 50 empleados: 314,51€</List.Item>
          <List.Item>De 51 a 100 empleados: 445,56€</List.Item>
        </List>

        <Header as="h3">Comisiones</Header>
        <p>La comisión del producto en producción y cartera es del 20%.</p>
        <p>
          Como consecuencia del acuerdo exclusivo de ARAG/ ESPABROK, la comisión
          de producción para el primer año de comercialización será del 40%.
          Esta comisión de producción se mantendrá para el segundo año siempre y
          cuando se venda el mínimo de 25 pólizas por correduría. La comisión de
          cartera de estas pólizas es del 20% y se modificará en la segunda
          anualidad en base al siguiente escalado por cada correduría:{" "}
        </p>
        <List bulleted>
          <List.Item>{">"}25 pólizas: 30%</List.Item>
          <List.Item>{">"}50 pólizas: 35%</List.Item>
          <List.Item>{">"}100 pólizas: 40%</List.Item>
        </List>

        <Header as="h3">
          Adicional se establecen dos cláusulas especiales:
        </Header>
        <List bulleted>
          <List.Item>
            <strong>Regla de equidad</strong>: No se aplicará en la tramitación
            de los siniestros, aunque cambien los empleados, siempre y cuando
            sea correcto el número de empleados al comenzar la póliza. Se
            actualizarán las condiciones en el momento del siniestro.
          </List.Item>
          <List.Item>
            <strong>Trámite del siniestro</strong>: Cuando el profesional haya
            sido designado por el Asegurador de conformidad con el Asegurado, de
            acuerdo con lo establecido en el apartado b{")"} del artículo 41,
            <strong>
              ARAG asumirá los honorarios derivados de su actuación
            </strong>
            , satisfaciéndolos directamente al profesional.
          </List.Item>
        </List>

        <Header as="h3">Operativa de contratación:</Header>
        <List bulleted>
          <List.Item>
            Apoyo comercial: 50 comerciales en todo el territorio y SAM.
          </List.Item>
          <List.Item>Ficha de producto personalizada.</List.Item>
          <List.Item>Emisión: AOL.</List.Item>
        </List>
      </div>
    );
  }
}

export default ProteccionempresasArag;
