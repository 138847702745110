import React from "react";
import { Image, Modal } from "semantic-ui-react";
import styled from "styled-components";

import PrivacyPolicy from "./PrivacyPolicy";
import { Container } from "./styled";

import logoEspabrok from "../images/logo-espabrok-white.svg";

const FooterStyled = styled.footer`
  background: #333333;
  color: #dddddd;
  font-size: 1rem;
  padding: 1rem 0;
  @media (min-width: 768px) {
    padding: 1rem 0 3rem 0;
  }
`;
const FooterContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  .footer__logo {
    height: 30px;
  }
  .footer__legal {
    margin-top: 0.2rem;
    cursor: pointer;
    &:hover {
      color: #ffffff;
    }
  }
`;

const Footer = () => {
  return (
    <FooterStyled>
      <Container>
        <FooterContent>
          <Image src={logoEspabrok} className="footer__logo" />
          <Modal
            trigger={
              <div className="footer__legal">Política de privacidad</div>
            }
          >
            <PrivacyPolicy />
          </Modal>
        </FooterContent>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
