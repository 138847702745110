import React, { PureComponent, createContext } from "react";
import axios from "axios";

import { withUserContext } from "./User";

import { APIBASEURL } from "../../globals";

export const ApiContext = createContext();

class ApiProviderWithoutUserContext extends PureComponent {
  state = {
    apiRequest: (type, endpoint, data = {}) => {
      return new Promise((resolve, reject) => {
        axios({
          method: type,
          url: `${APIBASEURL}${endpoint}`,
          responseType: "json",
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
            //this.props.user.logoutUser()
            if (
              error.response &&
              error.response.data &&
              error.response.data.code === 401
            )
              this.props.user.logoutUser();
            reject(error);
          });
      });
    },
    getRequest: (endpoint) => this.state.apiRequest("get", endpoint),
    postRequest: (endpoint, data) =>
      this.state.apiRequest("post", endpoint, data),
    putRequest: (endpoint, data) =>
      this.state.apiRequest("put", endpoint, data),
    deleteRequest: (endpoint) => this.state.apiRequest("delete", endpoint),
  };

  render() {
    return (
      <ApiContext.Provider value={this.state}>
        {this.props.children}
      </ApiContext.Provider>
    );
  }
}

export const ApiProvider = withUserContext(ApiProviderWithoutUserContext);

export function withApiContext(Component) {
  return function WithApiContextComponent(props) {
    return (
      <ApiContext.Consumer>
        {(api) => <Component {...props} api={api} />}
      </ApiContext.Consumer>
    );
  };
}
