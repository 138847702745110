import React, { Component } from "react";
import { List } from "semantic-ui-react";

class BusinessclassChubb extends Component {
  render() {
    return (
      <div>
        <strong>LAS VENTAJAS DE ESTE ACUERDO SON</strong>:
        <List bulleted>
          <List.Item>
            Producto personalizado Espabrok y el más completo del mercado
            actual.
          </List.Item>
          <List.Item>
            Comisión: 25% (la media del mercado para similares productos es del
            15%-20%)
          </List.Item>
          <List.Item>Ámbito de cobertura: Mundial.</List.Item>
          <List.Item>
            Dos opciones de contratación por duración: hasta 90 días
            consecutivos / hasta 180 días en el mismo destino.
          </List.Item>
          <List.Item>
            Coberturas: Accidentes, Gastos médicos ilimitados y asistencia en
            viaje
          </List.Item>
          <List.Item>Producto sin franquicias.</List.Item>
          <List.Item>
            Responsabilidad Civil Privada hasta 7,5 millones € por asegurado y
            año.
          </List.Item>
          <List.Item>
            Repatriación en avión medicalizado desde cualquier lugar del mundo.
          </List.Item>
          <List.Item>
            Modalidad y duración de la póliza: 24 horas y anual renovable
          </List.Item>
          <List.Item>Prima mínima: 400 €</List.Item>
        </List>
        <br />
        <strong>CONTRATACIÓN</strong>:
        <br />
        <br />
        <p>
          El producto es centralizado, pero una vez cumplimentado el contrato os
          facilitarán las claves de acceso a la plataforma de contratación.
          Disponéis de un manual de uso de la herramienta. RECORDAR que para la
          emisión debéis de adjuntar la orden SEPA firmada por el cliente.
        </p>
        <p>
          Para contratar el producto hay que proporcionar los siguientes datos:
        </p>
        <List bulleted>
          <List.Item>Razón social del tomador.</List.Item>
          <List.Item>
            Número de empleados del tomador y número de personas que realizan
            viajes.
          </List.Item>
          <List.Item>
            Actividad que realizan las personas que viajan. Manual / No manual.
          </List.Item>
          <List.Item>
            Zona geográfica de los destinos: España / Europa / Resto del Mundo.
          </List.Item>
        </List>
        <br />
        <strong>DATOS DE CONTACTO</strong>:
        <br />
        <br />
        Envío del contrato cumplimentado para el alta de claves:{" "}
        <a href="mailto:easysolutions.es@chubb.com">
          easysolutions.es@chubb.com
        </a>
        .
        <br />
        <br />
        <br />
        <strong>PERSONAS DE ATENCIÓN DENTRO DE LA COMPAÑÍA</strong>:
        <br />
        <List bulleted>
          <List.Item>
            <strong>Ana María Hoyos</strong>. Directora de Segmento Chubb Easy
            Solutions.
            <br />
            <a href="mailto:anamaria.hoyos@chubb.com">
              anamaria.hoyos@chubb.com
            </a>{" "}
            / Teléfono: 91 084 86 94.
          </List.Item>
          <List.Item>
            <strong>Luisa Lages</strong>. Ejecutiva de Ventas Chubb Easy
            Solutions.
            <br />
            <a href="mailto:luisa.lages@chubb.com">luisa.lages@chubb.com</a> /
            Teléfono: 93 242 15 16.
          </List.Item>
          <List.Item>
            <strong>Cristina Hernández</strong>. Gestora de clientes Chubb Easy
            Solutions.
            <br />
            <a href="mailto:cristina.hernandez@chubb.com">
              cristina.hernandez@chubb.com
            </a>{" "}
            / Teléfono: 91 085 51 87.
          </List.Item>
          <List.Item>
            <strong>Carmen Bermúdez</strong>. Gestora de clientes Chubb Easy
            Solutions.
            <br />
            <a href="mailto:carmen.bermudez@chubb.com">
              carmen.bermudez@chubb.com
            </a>{" "}
            / Teléfono: 91 081 51 25.
          </List.Item>
        </List>
      </div>
    );
  }
}

export default BusinessclassChubb;
