import React, { Component } from "react";
import { List, Table } from "semantic-ui-react";

class SaludReembolsoAdeslas extends Component {
  render() {
    return (
      <div>
        <strong>PRODUCTO CENTRALIZADO</strong>:
        <List bulleted>
          <List.Item>Colectivo abierto.</List.Item>
          <List.Item>
            Características principales del producto Adeslas Reembolso SPBK:
            <List>
              <List.Item>
                Capital máximo de reembolso: <strong>180.000€/año</strong>.
              </List.Item>
              <List.Item>80% en España / 80% en Mundo.</List.Item>
              <List.Item>
                Sublímites ampliados:
                <List bulleted>
                  <List.Item>
                    Ver en los documentos información sobre las primas.
                  </List.Item>
                </List>
                <br />
                <br />
              </List.Item>
              <List.Item>
                Edad máxima de contratación: <strong>65 años</strong>.
              </List.Item>
              <List.Item>Sin reembolso dental.</List.Item>
              <List.Item>
                No se admiten traspasos de pólizas procedente Adeslas NO
                ESPABROK.
              </List.Item>
              <List.Item>
                Se admite traspaso de Adeslas Espabrok a Adeslas Espabrok
                Reembolso, supeditado a valoración completa de cuestionario
                médico.
              </List.Item>
            </List>
          </List.Item>{" "}
        </List>
        <strong>PRIMAS</strong>:
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Table
            unstackable
            collapsing
            compact
            size="small"
            celled
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Edad</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Prima Mensual
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>0 - 19</Table.Cell>
                <Table.Cell textAlign="center">42,60 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>20 - 54</Table.Cell>
                <Table.Cell textAlign="center">54,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>55 - 59</Table.Cell>
                <Table.Cell textAlign="center">95,40 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>60 - 64</Table.Cell>
                <Table.Cell textAlign="center">120,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>65</Table.Cell>
                <Table.Cell textAlign="center">183,60 €</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <strong>OPERATIVA DE CONTRATACIÓN</strong>: Remitir a Pedro Torres{" "}
        <a href="mailto:salud@espabrok.es">salud@espabrok.es</a> los siguientes
        documentos...
        <List bulleted>
          <List.Item>Solicitud y cuestionario reducido.</List.Item>
          <List.Item>Fotocopia DNI.</List.Item>
        </List>
        <br />
        <strong>IMPORTANTE</strong>: Rellenar todas las preguntas del
        cuestionario médico, el cliente debe explicar las patologías declaradas
        y si tiene secuelas de las mismas. Es necesario cumplimentar el teléfono
        y el correo electrónico del cliente o, en caso de no disponer, el del
        corredor.
      </div>
    );
  }
}

export default SaludReembolsoAdeslas;
