export const APIBASEURL = "https://api.intranet.espabrok.es/v1";
export const APIREQUESTOPTIONS = {
  responseType: "json",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const COLOR = "#2C5081";

// Database IDs of users who has beta access.
// Almudena, ITEISA
export const BETA_IDS = [4, 5];

// Widgets polling intervals (in seconds).
export const MAILMESSAGESREFRESH = 60;
export const CLOUDFILESREFRESH = 120;
export const CLOUDCALENDARSREFRESH = 120;

export const PAGESIZE = 25;
export const NRESULTS = 25;

export const MONTHS = {
  1: { sort: "Ene", long: "Enero" },
  2: { sort: "Feb", long: "Febrero" },
  3: { sort: "Mar", long: "Marzo" },
  4: { sort: "Abr", long: "Abril" },
  5: { sort: "May", long: "Mayo" },
  6: { sort: "Jun", long: "Junio" },
  7: { sort: "Jul", long: "Julio" },
  8: { sort: "Ago", long: "Agosto" },
  9: { sort: "Sep", long: "Septiembre" },
  10: { sort: "Oct", long: "Octubre" },
  11: { sort: "Nov", long: "Noviembre" },
  12: { sort: "Dic", long: "Diciembre" },
};

export const GENDERS = {
  male: "hombre",
  female: "mujer",
};

export const PROVINCES = {
  1: "Álava",
  2: "Albacete",
  3: "Alicante",
  4: "Almería",
  5: "Ávila",
  6: "Badajoz",
  7: "Illes Balears",
  8: "Barcelona",
  9: "Burgos",
  10: "Cáceres",
  11: "Cádiz",
  12: "Castellón",
  13: "Ciudad Real",
  14: "Córdoba",
  15: "A Coruña",
  16: "Cuenca",
  17: "Girona",
  18: "Granada",
  19: "Guadalajara",
  20: "Guipúzcoa",
  21: "Huelva",
  22: "Huesca",
  23: "Jaén",
  24: "León",
  25: "Lleida",
  26: "La Rioja",
  27: "Lugo",
  28: "Madrid",
  29: "Málaga",
  30: "Murcia",
  31: "Navarra",
  32: "Ourense",
  33: "Asturias",
  34: "Palencia",
  35: "Las Palmas",
  36: "Pontevedra",
  37: "Salamanca",
  38: "Santa Cruz de Tenerife",
  39: "Cantabria",
  40: "Segovia",
  41: "Sevilla",
  42: "Soria",
  43: "Tarragona",
  44: "Teruel",
  45: "Toledo",
  46: "Valencia",
  47: "Valladolid",
  48: "Vizcaya",
  49: "Zamora",
  50: "Zaragoza",
  51: "Ceuta",
  52: "Melilla",
};

export const TASKSTATES = {
  success: {
    name: "Completado",
    description: "La tarificación se ha completado.",
    hint: "Tu presupuesto fue solicitado y obtenido, y el fichero resultante está a tu disposición.",
    label: "success",
    icon: "tick",
    color: "#5cb85c",
  },
  queued: {
    name: "Pendiente",
    description: "La tarificación está pendiente.",
    hint: "La tarea aún se encuentra esperando a ser tarificada. Tendrás que esperar unos minutos para conocer el desenlace.",
    label: "default",
    icon: "hourglass",
    color: "#999999",
  },
  deferred: {
    name: "Programada",
    description: "La tarificación está programada.",
    hint: "La tarea se ha pospuesto para que se lance automáticamente en una fecha futura.",
    label: "default",
    icon: "clock",
    color: "#999999",
  },
  running: {
    name: "Tarificando",
    description: "La tarificación está en curso.",
    hint: "La tarificación está procesándose en el portal de la compañía en estos momentos. En unos instantes estará completada.",
    label: "info",
    icon: "cogwheel",
    color: "#5bc0de",
  },
  error: {
    name: "Error",
    description: "La tarificación no se ha podido completar.",
    hint: "Tarifespabrok intentó varias veces la tarificación pero no consiguió obtener el presupuesto en el portal de la compañía.",
    label: "danger",
    icon: "bomb",
    color: "#d9534f",
  },
  fatal: {
    name: "Intarificable",
    description: "La tarificación es imposible.",
    hint: "Bien porque el riesgo es intarificable o porque las credenciales para acceder al portal de la compañía son incorrectas.",
    label: "warning",
    icon: "skull",
    color: "#f0ad4e",
  },
};

export const SLACK_CLIENT_ID = "440047770038.1150429299137";
