import React, { PureComponent, useState } from "react";
import * as yup from "yup";

import axios from "axios";
import { Segment, Image, Form, Message } from "semantic-ui-react";
import styled, { keyframes } from "styled-components";

import LoginForm from "@bit/rigobauer.iteisa-components.semantic-ui.login-form";
import { withUserContext } from "../components/Context";
import { Space } from "../components/UI";

import { APIBASEURL, APIREQUESTOPTIONS } from "../globals";

import logoIntranet from "../images/logo-intranet.svg";
import { Link } from "@reach/router";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const Main = styled.main`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const StyledLoginForm = styled(Segment)`
  width: 90%;
  padding: 2rem !important;
  animation: ${fadeIn} 1s ease 0s 1;
  @media (min-width: 376px) {
    width: 400px;
    padding: 3rem !important;
  }
`;
const pwdResetKey = "pwdreset=";

const ResetPasswordForm = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setPassordResetFormErrors] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const getValidationSchema = () => {
    return yup.object().shape({
      password: yup
        .string()
        .required("La contraseña es requerida")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&()_|ˆ/]{5,}$/,
          "Debe tener 5 caracteres, una mayúscula, una minúscula, un número y un caracter especial (@,$,!,%,*,#,?,&)"
        ),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden"),
    });
  };

  const postResetPassword = () => {
    getValidationSchema()
      .validate({ password, passwordConfirmation }, { abortEarly: false })
      .then(({ password, passwordConfirmation }) => {
        const [, token] = props.location.search.split(pwdResetKey);
        axios({
          method: "post",
          url: `${APIBASEURL}/reset`,
          data: { password, password_confirm: passwordConfirmation, token },
          ...APIREQUESTOPTIONS,
        })
          .then((response) => {
            const { data } = response;
            if (data.saved) {
              //Logout user because this response do not contain passwords arr
              //so, it will fail when trying to load slack messages and will logout user
              //sudenaly, it's better to show him that everything went ok, and take him to login again
              props.user.logoutUser();
              setUpdateSuccess(true);
            } else {
              setPassordResetFormErrors(
                "Mmmm... Algo raro ha sucedido. Será mejor que lo vuelvas a intentar."
              );
            }
          })
          .catch((error) => {
            setPassordResetFormErrors(
              "Mmmm... Algo raro ha sucedido. Será mejor que lo vuelvas a intentar."
            );
            console.error(error);
          });
      })
      .catch(({ errors }) => {
        setPassordResetFormErrors(errors.join(" y "));
      });
  };

  return (
    <Main>
      <StyledLoginForm>
        <Image centered src={logoIntranet} width="280px" />
        <Space height="2rem" />
        {updateSuccess ? (
          <React.Fragment>
            <Message
              positive
              header="Ok"
              content="Password actualizado exitosamente"
              onDismiss={() => setPassordResetFormErrors("")}
            />
            <Link to="/">Ir al login</Link>
          </React.Fragment>
        ) : (
          <Form>
            <Form.Input
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="Introduce una nueva contraseña"
              name="password"
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
            />
            <Form.Input
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="Confirma la contraseña"
              name="password_confirm"
              value={passwordConfirmation}
              onChange={({ target: { value } }) =>
                setPasswordConfirmation(value)
              }
            />
            {errors !== "" && (
              <Message
                negative
                header="ERROR"
                content={errors}
                onDismiss={() => setPassordResetFormErrors("")}
              />
            )}
            <Form.Button
              fluid
              size="large"
              style={{ marginTop: "1.6em" }}
              content="Confirmar"
              onClick={postResetPassword}
            />
          </Form>
        )}
      </StyledLoginForm>
    </Main>
  );
};
class ScreensLogin extends PureComponent {
  loginResponseValidation = (response) => {
    const {
      login,
      user: { active },
    } = response.data;
    return login === true && active === true;
  };

  handleLoginSuccess = (response) => this.props.user.setUserInfo(response.data);

  loginReminderResponseValidation = ({ data: { reset } }) => reset === true;

  render() {
    if (this.props.location.search.match(pwdResetKey))
      return (
        <ResetPasswordForm location={this.props.location} {...this.props} />
      );

    return (
      <Main>
        <StyledLoginForm>
          <Image centered src={logoIntranet} width="280px" />
          <Space height="2rem" />
          <LoginForm
            urlLogin={`${APIBASEURL}/login`}
            apiRequestOptions={APIREQUESTOPTIONS}
            loginUrlVar="email"
            pwdUrlVar="password"
            errorMessageLogin={
              "Mmmm... Algo raro ha sucedido. Será mejor que lo vuelvas a intentar."
            }
            loginResponseValidation={this.loginResponseValidation}
            handleLoginSuccess={this.handleLoginSuccess}
            urlLoginReminder={`${APIBASEURL}/recovery`}
            loginReminderResponseValidation={
              this.loginReminderResponseValidation
            }
            successMessageLoginReminder="Recibirás en tu correo electrónico las instrucciones para obtener una nueva contraseña"
          />
        </StyledLoginForm>
      </Main>
    );
  }
}

export default withUserContext(ScreensLogin);
