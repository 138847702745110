import styled from "styled-components";

export const SectionHeaderContainer = styled.div`
  & button {
    font-size: 0.95rem !important;
    margin: 0 !important;
    padding: 0.8rem 1rem !important;
  }
  .title {
    text-align: center;
    margin-bottom: 0.4rem;
  }
  .buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    & > * {
      flex: 1 1 auto;
      margin: 0 !important;
      margin-right: 0.2rem !important;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
  @media (min-width: 600px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
    & button {
      font-size: 1rem !important;
      padding: 0.8rem 1.5rem !important;
    }
    .title {
      text-align: left;
      margin-bottom: 0;
    }
  }
`;
