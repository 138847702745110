import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

import { withUserContext } from "../components/Context";
import WidgetMail from "../components/widgets/Mail";
import WidgetCloudFiles from "../components/widgets/CloudFiles";
import WidgetSlack from "../components/widgets/Slack";
import WidgetNews from "../components/widgets/News";
import WidgetTools from "../components/widgets/Tools";
import WidgetCompanies from "../components/widgets/Companies";
import WidgetProducts from "../components/widgets/Products";
import WidgetCalendar from "../components/widgets/Calendar";
import WidgetTarifEspabrok from "../components/widgets/TarifEspabrok";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const Dashboard = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: "flex-start";
  & > div {
    width: 100%;
    margin-top: 1rem;
    align-self: "stretch";
    animation: ${fadeIn} 1s ease 0s 1;
  }
  & > #w-mail-slack {
    width: 100%;
    & > #w-tools {
      margin-top: 1rem!important;
    }
  }
  & > #w-cloud-calendar {
    height: auto;
    min-height: 500px;
  }
  & > #w-cloud-files {
    width: 100%;
  }
  & > #w-tarifespabrok {
    width: 100%;
  }
  & > #w-companies {
    width: 100%;
  }
  & > #w-slack {
    width: 100%;
  }

  @media (min-width: 768px) {
    & > div {
      width: 49%;
    }
    & > #w-cloud-calendar {
      height: 650px;
      min-height: auto;
    }
    & > #w-cloud-files {
      width: 49%;
    }

    & > #w-companies {
      width: 49%;
    }
    & > #w-tarifespabrok {
      width: 100%;
    }
  }
  @media (min-width: 1280px) {
    & > div {
      width: 32%;
    }
    & > #w-mail-slack {
      width: 66%;
    }
    & > #w-cloud-calendar {
      height: 770px;
    }
    & > #w-cloud-files {
      width: 66%;
    }
    & > #w-tarifespabrok {
      width: 100%;
    }
    & > #w-companies {
      width: 66%;
    }

  }
`;

class ScreensDashboard extends Component {
  render() {
    const { userTarifUser } = this.props.user;
    return (
      <Dashboard>
        <div id="w-mail-slack">
          <WidgetMail id="w-mail" />
          <WidgetTools id="w-tools" />
        </div>
        <WidgetCalendar id="w-cloud-calendar" />
        <WidgetCloudFiles id="w-cloud-files" />
        <WidgetNews />

        <WidgetCompanies id="w-companies" />
        <WidgetProducts />
        {userTarifUser && userTarifUser.active && (
          <WidgetTarifEspabrok id="w-tarifespabrok" />
        )}
        <WidgetSlack id="w-slack" />
      </Dashboard>
    );
  }
}

export default withUserContext(ScreensDashboard);
