import React, { Component } from "react";
import { List } from "semantic-ui-react";

class AdministradoresyaltoscargosMarkel extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO CENTRALIZADO</strong>.
        </List.Item>
        <List.Item>
          <strong>OPERATIVA DE CONTRATACIÓN</strong>: mediante envío de
          cuestionario firmado a Alejandro Conde{" "}
          <a href="mailto:alejandro.conde@espabrok.com">
            alejandro.conde@espabrok.com
          </a>
          .
        </List.Item>
      </List>
    );
  }
}

export default AdministradoresyaltoscargosMarkel;
