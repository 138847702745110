import React, { Component } from "react";
import { Table, List } from "semantic-ui-react";

class AccidentesSurne extends Component {
  render() {
    return (
      <div>
        <strong>COMISIÓN</strong>: 25%.
        <br />
        <br />
        <strong>EDAD MÁXIMA DE ENTRADA</strong>: 60 AÑOS.
        <br />
        <br />
        <strong>FIN DE SEGURO</strong>: A LOS 65 AÑOS.
        <br />
        <br />
        <strong>GRUPOS 1 Y 2 SEGURO 24 HORAS</strong>.
        <br />
        <br />
        <strong>GRUPOS 3 Y 4 SEGURO EXTRAPROFESIONAL</strong>.
        <br />
        <br />
        <strong>CONDICIONADO Y SOLICITUD PROPIOS PARA ESPABROK</strong>.
        <br />
        <br />
        <strong>CENTRALIZADO</strong>:
        <br />
        <p style={{ marginTop: "0.4rem" }}>
          Deberéis mandar la solicitud cumplimentada junto con copia del DNI del
          solicitante/cliente a
          <a href="mailto:suscripcion@espabrok.es">suscripcion@espabrok.es</a>.
          Aquellos que deseéis abrir clave directamente con la Cía. podéis
          solicitarlo a{" "}
          <a href="mailto:comercial@espabrok.es">comercial@espabrok.es</a>.
        </p>
        <strong>TRES OPCIONES DE COBERTURAS Y CAPITALES</strong>:
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Table
            unstackable
            collapsing
            size="small"
            celled
            textAlign="center"
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={4}>
                  COBERTURAS Y CAPITALES POR MÓDULOS
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign="left">Opciones</Table.HeaderCell>
                <Table.HeaderCell>A</Table.HeaderCell>
                <Table.HeaderCell>B</Table.HeaderCell>
                <Table.HeaderCell>C</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Fallecimiento por accidente
                </Table.Cell>
                <Table.Cell>100.000€</Table.Cell>
                <Table.Cell>200.000€</Table.Cell>
                <Table.Cell>300.000€</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Fallecimiento por infarto de miocardio dictaminado como
                  accidente laboral
                </Table.Cell>
                <Table.Cell>100.000€</Table.Cell>
                <Table.Cell>100.000€</Table.Cell>
                <Table.Cell>100.000€</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Invalidez total/absoluta por accidente
                </Table.Cell>
                <Table.Cell>100.000€</Table.Cell>
                <Table.Cell>200.000€</Table.Cell>
                <Table.Cell>300.000€</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Invalidez parcial por accidente, según baremo
                </Table.Cell>
                <Table.Cell>100.000€</Table.Cell>
                <Table.Cell>200.000€</Table.Cell>
                <Table.Cell>300.000€</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">Gastos de sepelio</Table.Cell>
                <Table.Cell>3.000€</Table.Cell>
                <Table.Cell>3.000€</Table.Cell>
                <Table.Cell>3.000€</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Indemnización diaria por hospitalización por accidente
                </Table.Cell>
                <Table.Cell colSpan={3}>50€/día (Máx. 365 días)</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Gastos de reforma de la vivienda en caso de invalidez
                  permanente
                </Table.Cell>
                <Table.Cell colSpan={3}>Máximo 5.000€</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={4} />
              </Table.Row>
              <Table.Row style={{ fontWeight: "bold" }}>
                <Table.Cell textAlign="left">
                  Prima total anual, incluidos impuestos para Grupos I y II
                </Table.Cell>
                <Table.Cell>A</Table.Cell>
                <Table.Cell>B</Table.Cell>
                <Table.Cell>C</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left" />
                <Table.Cell>65€</Table.Cell>
                <Table.Cell>125€</Table.Cell>
                <Table.Cell>185€</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <p>
          Quedan cubiertos los accidentes derivados de{" "}
          <strong>actividades deportivas</strong> no profesionales y de la
          conducción de <strong>ciclomotores y motocicletas</strong> sin límite
          de cilindrada. La garantía de fallecimiento por{" "}
          <strong>infarto</strong> es un <strong>pago único</strong>, por tanto,{" "}
          <strong>no acumulativo</strong> con la garantía de fallecimiento por
          accidente.
        </p>
        <List bulleted>
          <List.Item>
            <strong>Profesiones Grupo de Riesgo I / Invalidez Total</strong>.
          </List.Item>
          <List.Item>
            <strong>Profesiones Grupo de Riesgo II / Invalidez Absoluta</strong>
            .
          </List.Item>
          <List.Item>
            <strong>
              Profesiones Grupo de Riesgo III-IV / Invalidez Absoluta
            </strong>
            : Indicar que quedarán cubiertos única y exclusivamente los
            accidentes NO considerados como Accidente Laboral (no estarán
            cubiertos los accidentes derivados de la actividad laboral que se
            desempeñe).
          </List.Item>
        </List>
      </div>
    );
  }
}

export default AccidentesSurne;
