import React, { Component } from "react";
import { List } from "semantic-ui-react";

class SaludAdeslas extends Component {
  render() {
    return (
      <div>
        <strong>PRODUCTO CENTRALIZADO</strong>:<p>Colectivo Adeslas Espabrok</p>
        <p>Incluye Adeslas Dental Familia con franquicia Óptima.</p>
        <p>Sin copago</p>
        <List bulleted>
          <List.Item>
            Ver en los documentos información sobre las primas.
          </List.Item>
        </List>
        <br />
        <br />
        <strong>CONDICIONES DE CONTRATACIÓN:</strong>
        <List bulleted>
          <List.Item>Edad máxima de contratación 64 años</List.Item>
          <List.Item>No se admiten menores solos</List.Item>
          <List.Item>
            No se admiten traspasos de póliza de Adeslas en activo.
          </List.Item>
          <List.Item>
            No comercializable en Asturias, Cantabria, Navarra y País Vasco
          </List.Item>
        </List>
        <strong>OPERATIVA DE CONTRATACIÓN</strong>: Remitir a Pedro Torres{" "}
        <a href="mailto:salud@espabrok.es">salud@espabrok.es</a> los siguientes
        documentos...
        <List bulleted>
          <List.Item>
            Solicitud y cuestionario médico por ambas caras.
          </List.Item>
          <List.Item>Fotocopia DNI.</List.Item>
          <List.Item>
            Para justificar procedencia: último recibo y tarjeta de la compañía
            actual.
          </List.Item>
          <List.Item>IGP firmado por el cliente.</List.Item>
        </List>
        <br />
        <strong>IMPORTANTE</strong>:
        <p>
          Rellenar todas las preguntas del cuestionario médico, el cliente debe
          explicar las patologías declaradas y si tiene secuelas de las mismas.
          Es necesario cumplimentar el teléfono y el correo electrónico del
          cliente o en caso de no disponer el del corredor.
        </p>
        <br />
        <p>
          <strong>
            Exponer el producto a contratar en la parte inferior de la
            solicitud.
          </strong>
        </p>
        <p>
          Toda la documentación aportada debe ser legible. Toda la documentación
          que carezca de todos los campos rellenos será rechaza. La compañía
          solo valora lo que está expuesto en la documentación oficial.
        </p>
        <p>
          La <u>solicitud y cuestionario</u> tienen una <u>validez</u>, desde su
          firma, <u>un mes</u>.
        </p>
      </div>
    );
  }
}

export default SaludAdeslas;
