import React from "react";

export const FormInputErrors = ({ errors, style, ...rest }) =>
  errors && errors.length > 0 ? (
    <div
      style={{ color: "red", margin: "-0.6rem 0 0.8rem 0", ...style }}
      {...rest}
    >
      {errors.map((v, i) => (
        <div key={`${i}-${v}`}>- {v}</div>
      ))}
    </div>
  ) : null;
