import React from "react";
import { Label } from "semantic-ui-react";

export const Active = ({ active, ...rest }) => (
  <Label
    color={active ? "green" : "red"}
    content={active ? "SÍ" : "NO"}
    {...rest}
  />
);
