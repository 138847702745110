import React, { Component } from "react";
import { Link } from "@reach/router";
import { Image } from "semantic-ui-react";
import styled from "styled-components";

import { detectIE } from "../utils";

import logo from "../images/logo-espabrok.svg";
import isotype from "../images/isotype-espabrok.svg";

const LinkComponent = detectIE() === 11 ? (props) => <Link {...props} /> : Link;

const Logo = styled(LinkComponent)`
  #header-logo {
    display: none;
  }
  #header-isotype {
    display: inline;
  }
  @media (min-width: 768px) {
    #header-logo {
      display: inline;
    }
    #header-isotype {
      display: none;
    }
  }
`;

class HeaderLogo extends Component {
  render() {
    return (
      <Logo to="/">
        <Image src={logo} width="200px" id="header-logo" />
        <Image src={isotype} width="35px" id="header-isotype" />
      </Logo>
    );
  }
}

export default HeaderLogo;
