import { createGlobalStyle } from "styled-components";
import isotypeEspabrok from "../../images/isotype-espabrok-grey.svg";

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    font-size: 14px;
    margin: 0;
    padding: 0;
    background: #efefef !important;
    background-image: url(${isotypeEspabrok}) !important;
    background-repeat: no-repeat !important;
    background-position-y: calc(100% - -300px) !important;
    background-origin: padding-box !important;
    background-position-x: calc(100% - -300px) !important;
    background-size: 1100px !important;
    min-height: 100%;
  }
  h1 {
    font-weight: bold;
    margin: 0;
  }
  a,
  a:hover {
    color: #000;
  }
  button,
  a:hover {
    cursor: pointer;
  }
  a:hover button {
    opacity: 0.85;
  }
  blockquote {
    margin: 0.4rem 0 0 0 !important;
    padding: 0.6rem 0.8rem !important;
    background: #f6f6f6 !important;
    border-left: 4px solid #ccc !important;
  }
  #root {
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
  main {
    flex: 1 1 auto;
  }
  @media (min-width: 768px) {
    html, body {
      font-size: 13px !important;
    }
  }
  /* HACKS */
  /* .ui.modal {
    position: relative !important; 
  } */
  .ui.form .disabled.field {
    opacity: 1 !important;
  }
  .ui.input.disabled input {
    opacity: 1 !important;
    background: #d0d0d0 !important;
  }
  .ck-content {
    min-height: 180px;
  }
  /* @media only screen and (min-width: 1500px) {
    .ui.large.modal {
      width: 75% !important;
    }
  } */
`;
