import React, { Component } from "react";

class JoyeriasTecnicaaseguradora extends Component {
  render() {
    return (
      <div>
        <p>Alternativa de aseguramiento con inclusión de robo.</p>
        <br />
        <strong>OPERATIVA DE CONTRATACIÓN (Producto Centralizado)</strong>:
        <br />
        <br />
        <p>
          Enviar el formulario cumplimentado a Alejandro Conde{" "}
          <a href="mailto:alejandro.conde@espabrok.com">
            alejandro.conde@espabrok.com
          </a>
        </p>
      </div>
    );
  }
}

export default JoyeriasTecnicaaseguradora;
