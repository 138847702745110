import React from "react";
import { List } from "semantic-ui-react";

const EmpresaFlexibleAxa = () => {
  return (
    <React.Fragment>
      <strong>CONDICIONES ESPECIALES:</strong> Serán de aplicación las
      siguientes condiciones especiales en cada oferta/póliza que cada corredor
      asociado a Espabrok realice a través de la página web de Axa.
      <br />
      <br />
      <List bulleted>
        <List.Item>
          <strong>Cláusula seguro "Especial Espabrok".</strong> Inclusión en
          póliza de la nota: “Este seguro está especialmente diseñado para la
          organización de corredores Espabrok”, justo antes de explicar qué
          cubre o qué no cubre el seguro.
        </List.Item>
        <List.Item>
          <strong>Seguro a Valor de Nuevo (50%).</strong> (Contratación directa
          por el corredor, sin retención.) Las coberturas de la póliza, en
          general, se amplían a la diferencia existente entre el valor real de
          los bienes asegurados en el momento del siniestro y su valor en estado
          de nuevo, no pudiendo exceder esta diferencia del 50% del valor de
          nuevo. Incluidas las partes eléctricas y electrónicas de las máquinas.
        </List.Item>
        <List.Item>
          <strong>Seguro a Valor de Nuevo (100%).</strong> (Esta ampliación es
          directa, sin retención, y está condicionada a contratar la cobertura
          Todo Riesgo Daños Materiales.) Las coberturas de la póliza, en
          general, se amplían a la totalidad de la diferencia existente entre el
          valor real de los bienes asegurados en el momento del siniestro y su
          valor en estado de nuevo. Incluidas las partes eléctricas y
          electrónicas de las máquinas.
        </List.Item>
        <List.Item>
          <strong>
            Extensión del Valor de Nuevo a las coberturas de Avería Maquinaría y
            Avería Equipos Electrónicos.
          </strong>{" "}
          Las coberturas de Avería de Maquinaría y Avería de Equipos
          Electrónicos, se consideran contratadas bajo la modalidad Seguro a
          valor de Nuevo.
        </List.Item>
        <List.Item>
          <strong>Mercancías ubicadas a menos de 10 cm del suelo.</strong>{" "}
          Ampliando las coberturas de Daños por Agua e Inundación, se consideran
          incluidos los daños ocasionados a las existencias que por sus
          especiales características (dimensión, peso o similares) no puedan ser
          almacenadas a una altura superior a 10 centímetros del suelo.
        </List.Item>
        <List.Item>
          <strong>Existencias y bienes de terceros.</strong> Se consideran
          incluidos en el Contenido las existencias y bienes de terceros,
          siempre que su ubicación dentro del recinto de la empresa asegurada
          esté justificada por su relación con la actividad desarrollada y su
          importe se haya incorporado en la suma asegurada.
        </List.Item>
        <List.Item>
          <strong>Gastos de desescombro.</strong> Se eleva el límite asegurado
          al 100% de los gastos necesarios, sin que el conjunto de la
          indemnización por todos los conceptos cubiertos por la póliza pueda
          exceder de las sumas aseguradas fijadas para continente y/o contenido.
        </List.Item>
        <List.Item>
          <strong>Honorarios de profesionales y peritos externos.</strong>{" "}
          Ampliando el alcance de esta cobertura, se consideran también
          incluidos los siniestros amparados por el Consorcio de Compensación de
          Seguros, para siniestros estimados en una cuantía superior a 60.000
          euros .
        </List.Item>
        <List.Item>
          <strong>
            Variación de la franquicia en Todo Riesgo Daños Materiales.
          </strong>{" "}
          Posibilidad de reducir o ampliar la franquicia automática
        </List.Item>
        <List.Item>
          <strong>Pérdida Beneficios.</strong> La cobertura inicialmente cubrirá
          la pérdida de beneficios derivada de Incendio, Rayo, Explosión,
          Riesgos Extensivos y Daños Agua (cobertura idéntica a la antigua
          “mejora Espabrok”). <br />
          <br />
          Opcionalmente, en casos concretos, se podrá solicitar la inclusión de
          la pérdida de beneficios derivada de siniestros amparados por la
          póliza distintos a los previstos inicialmente (a concretar en cada
          caso, según el riesgo cubierto), excepto los amparados por el
          Consorcio de Compensación.
        </List.Item>
      </List>
    </React.Fragment>
  );
};

export default EmpresaFlexibleAxa;
