import React, { Component } from "react";
import { List } from "semantic-ui-react";

class RcaltoscargosAig extends Component {
  render() {
    return (
      <div>
        <strong>TARGET</strong>:
        <List bulleted>
          <List.Item>
            Administraciones Públicas pertenecientes a la administración local,
            tales como Ayuntamientos, Diputaciones, Consejos o Cabildos
            Insulares.
          </List.Item>
          <List.Item>
            Adicionalmente y bajo estudio individualizado cualquier otra entidad
            de derecho público que forme parte de la Administración Pública.
          </List.Item>
        </List>
        <br />
        <strong>COBERTURAS</strong>:
        <List bulleted>
          <List.Item>
            Responsabilidad de autoridades y personal frente a la Administración
            Pública.
            <List.List>
              <List.Item>
                Acción de regreso de la Administración Pública.
              </List.Item>
              <List.Item>
                Acción de la Administración Pública por daños y perjuicios a sus
                bienes y derechos.
              </List.Item>
              <List.Item>Acción por responsabilidad contable.</List.Item>
            </List.List>
          </List.Item>
          <List.Item>
            Responsabilidad frente a terceros.
            <List.List>
              <List.Item>
                Acciones de responsabilidad iniciadas por cualquier persona o
                entidad.
              </List.Item>
            </List.List>
          </List.Item>
          <List.Item>
            Responsabilidad por prácticas de empleo indebidas.
          </List.Item>
        </List>
        <br />
        <strong>OPERATIVA DE CONTRATACIÓN (Producto Centralizado)</strong>:
        <br />
        <br />
        <p>
          Enviar el cuestionario cumplimentado y firmado por el cliente a
          Alejandro Conde{" "}
          <a href="mailto:alejandro.conde@espabrok.com">
            alejandro.conde@espabrok.com
          </a>
        </p>
      </div>
    );
  }
}

export default RcaltoscargosAig;
