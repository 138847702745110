import React, { Component } from "react";
import { List } from "semantic-ui-react";

class CiberclearHiscox extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO CENTRALIZADO</strong>.
        </List.Item>
        <List.Item>
          <strong>OPERATIVA DE CONTRATACIÓN</strong>: Cumplimentación del
          precotizado y SEPA y envío a Alejandro Conde,{" "}
          <a href="mailto:alejandro.conde@espabrok.com">
            alejandro.conde@espabrok.com
          </a>
          para su cotización y envío.
        </List.Item>
        <List.Item>
          COMISIÓN: <strong>22,5%</strong>.
        </List.Item>
        <List.Item>
          <strong>MEJORAS</strong> que tenemos respecto al producto estándar
          Hiscox CyberClear:
          <List.List>
            <List.Item>
              Servicio de Respuesta a Incidentes:
              <List.List>
                <List.Item>Servicio de Contención Tecnológica - 100%</List.Item>
                <List.Item>
                  Servicio de asesoramiento jurídico y de comunicación y
                  relaciones públicas - 100%
                </List.Item>
                <List.Item>
                  Gastos de notificación y monitorización - 100%
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Pérdidas del Asegurado
              <List.List>
                <List.Item>
                  Pérdida de Beneficios (100%)
                  <List.List>
                    <List.Item>Periodo de Indemnización - 120 días</List.Item>
                    <List.Item>Franquicia - 8 horas</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  Proveedor Externo Tecnológico 100%
                  <List.List>
                    <List.Item>Periodo de Indemnización - 120 días</List.Item>
                    <List.Item>Franquicia - 8 horas</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>Extorsión Cibernética 100%</List.Item>
                <List.Item>
                  Gastos de recuperación de datos o sistemas 100%
                </List.Item>
                <List.Item>
                  Protección de Equipos (sustitución o reparación de equipos)
                  45.000,00 €
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Fraude Tecnológico
              <List.List>
                <List.Item>
                  Uso fraudulento de su identidad electrónica - 35.000,00 €
                </List.Item>
                <List.Item>Robo Electrónico de Fondos - 35.000,00 €</List.Item>
                <List.Item>
                  Modificación de Precios Online - 35.000,00 €
                </List.Item>
                <List.Item>
                  Fraude en servicios contratados - 35.000,00 €
                </List.Item>
                <List.Item>e. Suplantación de Identidad - 5.000,00 €</List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Responsabilidad Tecnológica
              <List.List>
                <List.Item>
                  Indemnizaciones por responsabilidad ante terceros - 100%
                </List.Item>
                <List.Item>Gastos de defensa - 100%</List.Item>
                <List.Item>Gastos Forenses Privacidad - 100%</List.Item>
                <List.Item>Gastos de Inspección Privacidad - 100%</List.Item>
                <List.Item>
                  Gastos de Asistencia a Juicio - Socio, administrador o
                  directivos hasta 500 € al día; o cualquier empleado hasta 250
                  € al día
                </List.Item>
                <List.Item>Sanciones administrativas - 100%</List.Item>
                <List.Item>
                  Sanciones PCI (normativa tarjetas de pago) - 100.000,00 €
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Gastos de Mitigación
              <List.List>
                <List.Item>Sublimite - 45.000,00 € </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              Condiciones a cumplir por la Red Espabrok para la renovación del
              acuerdo
              <List.List>
                <List.Item>Comisión* - 25%</List.Item>
                <List.Item>Crecimiento de cartera - Positivo</List.Item>
                <List.Item>Número mínimo de pólizas - 25</List.Item>
                <List.Item>Nuevo Negocio - 75.000,00 €</List.Item>
                <List.Item>Siniestralidad - Inferior al 40%</List.Item>
              </List.List>
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          <strong>DOCUMENTACION:</strong>: Se acompaña el precotizado que
          incluye las preguntas de aplicación, limites, primas y franquicias
          disponibles en base al rango de facturación del asegurado, junto a un
          documento de ayuda para cumplimentar dicho cuestionario, e información
          sobre el producto (incluido el comparativo con nuestro anterior
          producto).
        </List.Item>
      </List>
    );
  }
}

export default CiberclearHiscox;
