import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
//import { Link } from '@reach/router'
import axios from "axios";
import {
  Tab,
  Menu,
  Image,
  Icon,
  Input,
  Button,
  Label,
  Header,
  Form,
} from "semantic-ui-react";
import Emoji from "react-emoji-render";
import styled, { keyframes } from "styled-components";

import { withUserContext } from "../Context";
import WidgetLinkButton from "./WidgetLinkButton";
import { FlexRow } from "../styled";
import { GenericLoader } from "../UI";

import { formatDatetime, objectValues } from "../../utils";

import logoSlack from "../../images/slack/logo-slack.svg";
import bgEspabrokIsotype from "../../images/bg-slack-isotype.png";

import { COLOR, APIBASEURL, SLACK_CLIENT_ID } from "../../globals";

/**
 * Component for adding a slack channel.
 *
 * @class SlackNewChannel
 */
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const SlackNewChannelContainer = styled.div`
  animation: ${fadeIn} 0.5s ease 0s 1;
  width: 100%;
  height: 100%;
  background: #eee;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  & .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.2rem !important;
  }
`;
const SlackNewChannelForm = styled.div`
  width: 80%;
  & .title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  & .subtitle {
    color: #666;
    font-size: 0.8rem;
    margin-bottom: 1.4rem;
  }
  & button {
    width: 100%;
  }
  @media (min-width: 600px) {
    width: 60%;
    & .title {
      font-size: 1.6rem;
    }
    & .subtitle {
      font-size: 1rem;
    }
    & button {
      width: auto;
    }
  }
`;
class SlackNewChannel extends Component {
  static propTypes = {
    slackToken: PropTypes.string.isRequired,
    usersList: PropTypes.object,
    onClose: PropTypes.func,
  };
  static defaultProps = {
    onClose: () => null,
    usersList: {},
  };

  state = {
    channelName: "",
  };

  handleFieldChange = (e, { name, value }) => {
    this.setState({ [name]: value.replace(/[^a-z0-9_-]/g, "") });
  };

  handleSubmit = () => {
    this.postCreateChannel(this.state.channelName);
  };

  postCreateChannel = (channelName) => {
    const params = {
      token: this.props.slackToken,
      name: channelName,
    };
    axios({
      method: "post",
      url: `https://slack.com/api/conversations.create`,
      params,
    })
      .then(({ data }) => {
        if (data.ok) {
          const usersToInvite = Object.values(this.props.usersList)
            .filter((user) => !(user.is_bot || user.id === "USLACKBOT"))
            .map((user) => user.id)
            .join(",");

          const params = {
            token: this.props.slackToken,
            channel: data.channel.id,
            users: usersToInvite,
          };

          axios({
            method: "post",
            url: `https://slack.com/api/conversations.invite`,
            params,
          }).catch((error) => {
            console.log(error);
          });

          this.props.onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { channelName } = this.state;
    return (
      <SlackNewChannelContainer>
        <Button
          circular
          icon="close"
          className="close-button"
          onClick={this.props.onClose}
        />
        <SlackNewChannelForm>
          <div className="title">Crear nuevo canal</div>
          <div className="subtitle">
            Los nombres de canal sólo pueden contener letras minúsculas,
            números, - ó _, y un máximo de 21 caracteres.
          </div>
          <Form>
            <Form.Input
              maxLength="21"
              label="Nombre"
              name="channelName"
              value={channelName}
              onChange={this.handleFieldChange}
            />
            <div style={{ textAlign: "right" }}>
              <Form.Button
                type="button"
                primary
                content="Crear canal"
                className="submit-button"
                disabled={!channelName}
                onClick={this.handleSubmit}
              />
            </div>
          </Form>
        </SlackNewChannelForm>
      </SlackNewChannelContainer>
    );
  }
}

/**
 * Component for showing files in messages list.
 *
 * @class SlackFileMessage
 */

const SlackMessageFile = styled.div`
  & .image-name {
    font-size: 0.85rem;
    color: #999999;
    margin: 0.2rem 0;
  }
  & .image-name {
    font-size: 0.85rem;
    color: #999999;
    margin: 0.2rem 0;
  }
`;

const SlackFileMessage = ({ files }) => {
  return files.map((file) => (
    <SlackMessageFile key={file.id}>
      <div className="image-name">{file.name}</div>
      <Image
        className="image-thumb"
        src={file.thumb_360}
        href={file.url_private_download}
      />
    </SlackMessageFile>
  ));
};

/**
 * Slack client widget
 *
 * @class SlackClient
 * @extends {Component}
 */

const SlackContainer = styled.div`
  display: block;
  position: relative;
  height: 312px;
  overflow: hidden;
  @media (min-width: 600px) {
    /* display: flex;
    flex-flow: row nowrap;
    align-items: stretched;
    justify-content: space-between; */
  }
`;
const SlackChannels = styled.div`
  position: absolute;
  width: 220px;
  z-index: 500;
  left: ${(props) => (props.showChannelsList ? "0" : "-190px")};
  top: 0;
  bottom: 0;
  transition: left 0.2s ease, rotate 0.2s ease;
  background: rgb(44, 80, 129);
  color: #bbccdd;
  border: 1px solid transparent;
  /* border-radius: 0.286rem; */
  padding: 1rem 0;
  /* overflow-y: ${(props) => (props.showChannelsList ? "scroll" : "auto")}; */
  overflow-y: auto;
  & .title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0.8rem;
    padding: 0 0.2rem 0 1rem;
  }
  & .chevron {
    display: block;
    cursor: pointer;
    transform: ${(props) =>
      props.showChannelsList ? "none" : "rotate(180deg)"};
  }
  & .chevron:hover {
    color: #ffffff;
  }
  @media (min-width: 600px) {
    /* position: relative; */
    /* width: calc(100% - 220px); */
    height: 100%;
    left: 0;
    transition: none;
    /* flex: 0 0 auto; */
    & .chevron {
      display: none;
    }
  }
`;
const SlackChannelsList = styled.div`
  display: ${(props) => (props.showChannelsList ? "block" : "none")};
  @media (min-width: 600px) {
    display: block;
  }
`;
const SlackChannelName = styled.div`
  padding: 0.3rem 1rem;
  white-space: nowrap;
  color: ${(props) => (props.selected ? "rgb(255, 255, 255)" : undefined)};
  background: ${(props) => (props.selected ? "rgb(9, 45, 94)" : undefined)};
  :hover {
    cursor: pointer;
    background: ${(props) =>
      !props.selected ? "rgb(28, 65, 114)" : undefined};
  }
`;
const SlackMessagesContainer = styled.div`
  height: 100%;
  /* position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretched;
  justify-content: space-between; */
  padding-left: 40px;
  @media (min-width: 600px) {
    /* flex: 1 1 auto; */
    margin-left: 190px;
  }
`;
const SlackChannelMessages = styled.div`
  /* flex: 1 1 auto; */
  font-size: 0.95rem;
  padding-right: 1rem;
  background-image: url(${bgEspabrokIsotype});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 340px;
  height: 86.6%;
  overflow-y: scroll;
  & .message-dt {
    color: #999999;
    font-size: 0.95em;
    margin-left: 0.4em;
  }
  @media (min-width: 768px) {
    background-size: 400px;
    /*background-position-x: calc(100% + 30px);
    background-position-y: -15%; */
  }
`;
const SlackMessageSender = styled.div`
  /* flex: 0 1 auto; */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

class SlackClient extends Component {
  state = {
    isLoadingUsers: true,
    isLoadingChannels: true,
    isLoadingChannelMessages: false,
    isCreatingNewChannel: false,
    showChannelsList: true,
    usersList: {},
    channelsList: {},
    selectedChannelId: "",
    selectedChannelName: "",
    selectedChannelMessages: [],
    message: "",
  };

  messagesRef = React.createRef();
  intervalGetChannels = null;
  intervalGetChannelMessages = null;
  swipeStartX = null;

  header = {
    id: { text: "Id" },
    name: { text: "Canal" },
    members: { text: "Miembros" },
    actions: { text: "", collapsing: true },
  };

  componentDidMount() {
    this.getUsers();
    this.getChannels();
    this.intervalGetChannels = setInterval(this.getChannels, 30000);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.messagesRef.current) {
      const { clientHeight, scrollTop, scrollHeight } =
        this.messagesRef.current;
      return { scrolledUp: clientHeight + scrollTop < scrollHeight };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.state.isCreatingNewChannel &&
      !detectIE() &&
      (prevState.selectedChannelId !== this.state.selectedChannelId ||
        (prevState.selectedChannelMessages !==
          this.state.selectedChannelMessages &&
          !snapshot.scrolledUp))
    ) {
      this.messagesRef.current.scrollTo(
        0,
        this.messagesRef.current.scrollHeight
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalGetChannels);
    clearInterval(this.intervalGetChannelMessages);
  }

  handleToggleChannelsList = () => {
    this.setState((prevState) => ({
      showChannelsList: !prevState.showChannelsList,
    }));
  };

  handleFieldChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleFieldKeys = ({ keyCode }) => {
    if (keyCode === 13) this.handleSubmitMessage();
  };

  handleSelectChannel = (channelId, channelName) => {
    clearInterval(this.intervalGetChannelMessages);
    this.setState({
      showChannelsList: false,
      isLoadingChannelMessages: true,
      selectedChannelId: channelId,
      selectedChannelName: channelName,
    });
    this.getChannelMessages(channelId);
    this.intervalGetChannelMessages = setInterval(
      () => this.getChannelMessages(channelId),
      10000
    );
  };

  handleCreateNewChannel = () => {
    this.setState({ isCreatingNewChannel: true });
  };

  handleCloseNewChannelCreation = () => {
    this.setState({ isCreatingNewChannel: false });
  };

  handleTouchStart = (e) => {
    this.swipeStartX = e.changedTouches[0].pageX;
  };
  handleTouchMove = (e) => {
    // console.log(e)
  };
  handleTouchEnd = (e) => {
    const swipeXDistance = e.changedTouches[0].pageX - this.swipeStartX;
    if (this.state.showChannelsList && swipeXDistance < 0)
      this.setState({ showChannelsList: false });
    else if (!this.state.showChannelsList && swipeXDistance > 0)
      this.setState({ showChannelsList: true });
  };

  handleSubmitMessage = () => {
    this.postMessage(this.state.selectedChannelId, this.state.message);
  };

  getUsers = () => {
    const params = {
      token: this.props.slackToken,
    };
    axios({
      method: "get",
      url: `https://slack.com/api/users.list`,
      params,
    })
      .then(({ data: { ok, members } }) => {
        if (ok)
          this.setState({
            isLoadingUsers: false,
            usersList: members
              .filter((user) => !user.deleted)
              .reduce((t, v) => ({ ...t, [v.id]: v }), {}),
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getChannels = () => {
    const params = {
      token: this.props.slackToken,
      exclude_archived: true,
      limit: 200,
      types: "public_channel,private_channel",
    };
    axios({
      method: "get",
      url: `https://slack.com/api/conversations.list`,
      params,
    })
      .then(({ data: { ok, channels } }) => {
        if (ok) {
          const channelsIsMember = channels.filter((v) => v.is_member);
          channelsIsMember.forEach((channel) => {
            axios({
              method: "get",
              url: `https://slack.com/api/conversations.info`,
              params: {
                token: this.props.slackToken,
                channel: channel.id,
              },
            })
              .then(({ data: { ok, channel } }) => {
                if (ok) {
                  if (this.state.isLoadingChannels)
                    this.setState({ isLoadingChannels: false });

                  if (
                    !this.state.channelsList[channel.id] ||
                    this.state.channelsList[channel.id].unread_count !==
                      channel.unread_count
                  ) {
                    this.setState((prevState) => ({
                      channelsList: {
                        ...prevState.channelsList,
                        [channel.id]: channel,
                      },
                    }));
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getChannelMessages = (channelId) => {
    const params = {
      token: this.props.slackToken,
      channel: channelId,
      count: 25,
    };

    axios({
      method: "get",
      url: `https://slack.com/api/conversations.history`,
      params,
    })
      .then(({ data: { ok, messages } }) => {
        const lastMessageTs =
          messages.length > 0 ? messages[0].ts : new Date().getTime() / 1000;
        messages.reverse();
        if (ok) {
          this.setState({
            isLoadingChannelMessages: false,
            selectedChannelMessages: messages,
          });
          this.postChannelAsRead(channelId, lastMessageTs);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postChannelAsRead = (channelId, lastMessageTs = null) => {
    const params = {
      token: this.props.slackToken,
      channel: channelId,
      ts: lastMessageTs || new Date().getTime() / 1000,
    };

    axios({
      method: "post",
      url: `https://slack.com/api/conversations.mark`,
      params,
    }).catch((error) => {
      console.log(error);
    });
  };

  postMessage = (channelId, message) => {
    const params = {
      token: this.props.slackToken,
      as_user: "FakeUserName",
      channel: channelId,
      text: message,
    };

    axios({
      method: "post",
      url: `https://slack.com/api/chat.postMessage`,
      params,
    })
      .then(({ data: { ok } }) => {
        if (ok) {
          this.setState({ message: "" });
          this.getChannelMessages(channelId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSlackTokenRequestUrl = () => {
    let slackAuthApi = `https://slack.com/oauth/v2/authorize?`;
    slackAuthApi += `user_scope=${this.getSlackScopes()}`;
    slackAuthApi += `&redirect_uri=${APIBASEURL}/passwords/slack.json`;
    slackAuthApi += `&client_id=${SLACK_CLIENT_ID}`;
    slackAuthApi += `&state=${this.props.userId}`;
    return slackAuthApi;
  };

  getSlackScopes = () => {
    const scopes = [
      "channels:read",
      "channels:write",
      "channels:history",
      "chat:write",
      "users:read",
      "groups:write",
      "mpim:write",
      "im:write",
      "identify",
      "groups:read",
      "mpim:read",
      "im:read",
      "groups:history",
    ];
    return scopes.join(",");
  };

  render() {
    const slackToken = this.props.slackToken;
    const {
      isLoadingUsers,
      isLoadingChannels,
      isLoadingChannelMessages,
      isCreatingNewChannel,
      showChannelsList,
      channelsList,
      usersList,
      selectedChannelId,
      selectedChannelName,
      selectedChannelMessages,
      message,
    } = this.state;

    if (!slackToken)
      return (
        <SlackContainer
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
          }}
        >
          <Header as="h3" textAlign="center" icon>
            <Icon name="cogs" />
            ¡Vaya! parece que aún no has brindado permisos a Slack
            <Header.Subheader>
              <Button
                fluid
                as="a"
                href={this.getSlackTokenRequestUrl()}
                rel="noopener noreferrer"
                style={{
                  color: "#fff",
                  background: COLOR,
                  fontWeight: "normal",
                  marginBottom: "0.4rem",
                  padding: "0.8rem 0",
                }}
                content="Dar permisos a Slack"
              />
            </Header.Subheader>
          </Header>
        </SlackContainer>
      );

    if (isCreatingNewChannel)
      return (
        <SlackContainer>
          <SlackNewChannel
            slackToken={this.props.slackToken}
            usersList={usersList}
            onClose={this.handleCloseNewChannelCreation}
          />
        </SlackContainer>
      );

    const currentDate = new Date().toDateString();

    return (
      <SlackContainer>
        <SlackChannels
          showChannelsList={showChannelsList}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
        >
          <FlexRow justifyContent="space-between" className="title">
            <div>Canales Espabrok</div>
            <Icon
              name="chevron left"
              className="chevron"
              onClick={this.handleToggleChannelsList}
            />
          </FlexRow>
          {isLoadingUsers || isLoadingChannels ? (
            <GenericLoader style={{ marginTop: "2rem" }} />
          ) : (
            <Fragment>
              <div style={{ padding: "0 2.2rem 1rem 1rem" }}>
                <Button
                  fluid
                  compact
                  size="tiny"
                  content="Crear canal"
                  onClick={this.handleCreateNewChannel}
                />
              </div>
              <SlackChannelsList showChannelsList={showChannelsList}>
                {objectValues(channelsList)
                  .sort((a, b) => (b.unread_count || 0) - (a.unread_count || 0))
                  .map((channelData) => {
                    return (
                      <SlackChannelName
                        key={channelData.id}
                        selected={channelData.id === selectedChannelId}
                        onClick={() =>
                          this.handleSelectChannel(
                            channelData.id,
                            channelData.name
                          )
                        }
                      >
                        <Icon
                          name={channelData.is_private ? "lock" : "hashtag"}
                        />{" "}
                        {channelData.name}
                        {channelData.unread_count > 0 && (
                          <Label
                            color="red"
                            size="tiny"
                            style={{ marginLeft: "0.6rem" }}
                            content={channelData.unread_count}
                          />
                        )}
                      </SlackChannelName>
                    );
                  })}
              </SlackChannelsList>
            </Fragment>
          )}
        </SlackChannels>
        <SlackMessagesContainer>
          <SlackChannelMessages ref={this.messagesRef}>
            {isLoadingChannelMessages ? (
              <GenericLoader style={{ marginTop: "3rem" }} />
            ) : (
              selectedChannelMessages.map((message) => {
                const ts = 1000 * parseFloat(message.ts);
                const messageDate = new Date(ts).toDateString();
                let userName = null;
                let userAvatarUrl = null;
                if (message.user) {
                  userName = usersList[message.user].profile.display_name
                    ? usersList[message.user].profile.display_name
                    : usersList[message.user].profile.real_name
                    ? usersList[message.user].profile.real_name
                    : null;
                  userAvatarUrl =
                    usersList[message.user].profile.image_32 ||
                    "https://a.slack-edge.com/7f1a0/plugins/slackbot/assets/service_32.png";
                } else if (message.username) {
                  userName = message.username;
                  userAvatarUrl =
                    "https://a.slack-edge.com/7f1a0/plugins/slackbot/assets/service_32.png";
                }

                return (
                  <div
                    key={message.ts}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "1rem",
                    }}
                  >
                    <Image
                      src={userAvatarUrl}
                      style={{ marginRight: "1rem", borderRadius: "0.2rem" }}
                    />
                    <div>
                      <div>
                        <strong>{userName}</strong>
                        <span className="message-dt">
                          {formatDatetime(
                            ts,
                            true,
                            messageDate !== currentDate
                          )}
                        </span>
                      </div>
                      <div>
                        {message.files && message.files.length > 0 ? (
                          <SlackFileMessage files={message.files} />
                        ) : !detectIE() ? (
                          <Emoji
                            text={
                              message.text.replace(
                                /<@(\w*)>/g,
                                (str, userId) => `@${usersList[userId].name}`
                              )
                              // .replace(/<http([^>]*)>/g, (str, link) => {
                              //    return '[enlace]'
                              // })
                            }
                          />
                        ) : (
                          message.text.replace(
                            /<@(\w*)>/g,
                            (str, userId) => `@${usersList[userId].name}`
                          )
                          // .replace(/<http([^>]*)>/g, (str, link) => {
                          //   return '[enlace]'
                          // })
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </SlackChannelMessages>
          <SlackMessageSender>
            <Input
              style={{ flex: "1 1 auto", marginRight: "1rem" }}
              placeholder={`Enviar mensaje${
                selectedChannelName ? ` a #${selectedChannelName}` : ""
              }`}
              name="message"
              disabled={!selectedChannelId}
              value={message}
              onChange={this.handleFieldChange}
              onKeyUp={this.handleFieldKeys}
            />
            <Button
              style={{ margin: "0" }}
              icon="send"
              disabled={!selectedChannelId}
              onClick={this.handleSubmitMessage}
            />
          </SlackMessageSender>
        </SlackMessagesContainer>
      </SlackContainer>
    );
  }
}

class WidgetSlack extends Component {
  componentDidMount() {
    this.props.user.checkUserStatus();
  }

  panes = [
    {
      menuItem: (
        <Menu.Item key="slack" style={{ padding: "0.5rem 1rem 0.4rem 1.6rem" }}>
          Foro Slack{" "}
          <Image
            inline
            src={logoSlack}
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane style={{ padding: "1.4rem" }}>
          <SlackClient
            slackToken={this.props.user.slackToken}
            userId={this.props.user.userId}
          />
        </Tab.Pane>
      ),
    },
  ];

  render() {
    const { id, ...restProps } = this.props;
    return (
      <div style={{ position: "relative" }} id={id}>
        <WidgetLinkButton
          link="https://espabrok.slack.com"
          text="Ir a Slack"
          style={{ marginTop: "0.4rem" }}
          icon="slack"
        />
        <Tab panes={this.panes} {...restProps} />
      </div>
    );
  }
}

export default withUserContext(WidgetSlack);

function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  var edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
}
