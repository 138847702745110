import React, { Component } from "react";
import { List } from "semantic-ui-react";

class HogarPlusultra extends Component {
  render() {
    return (
      <div>
        <strong>PRODUCTO DESCENTRALIZADO</strong>.
        <br />
        <br />
        <strong>OPERATIVA DE CONTRATACIÓN</strong>: On-line.
        <br />
        <br />
        <strong>VENTAJAS DEL PRODUCTO ESPABROK</strong>:
        <List bulleted>
          <List.Item>
            Posibilidad de incluir el AJUSTE DE PRIMAS y el descuento de VENTA
            CRUZADA que tenéis disponible en vuestro portal de mediadores.
          </List.Item>
          <List.Item>
            Resumen de mejoras más importantes con respecto al producto Hogar
            Optima de Plus Ultra.
            <List.List>
              <List.Item>
                Mejora en los capitales máximos en daños por agua:
                <List.List>
                  <List.Item>
                    500€ para filtraciones por defecto en el sellado de juntas
                    de aparatos sanitarios.
                  </List.Item>
                  <List.Item>
                    1500€ para localización y reparación de tuberías sin daños.
                  </List.Item>
                  <List.Item>1000€ para exceso de consumo de agua.</List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Deterioro de Jardín:
                <List.List>
                  <List.Item>(Es una garantía optativa).</List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Roturas:
                <List.List>
                  <List.Item>
                    Opción de contratar la rotura de los cristales de las placas
                    solares.
                  </List.Item>
                  <List.Item>
                    Opción de contratar la rotura de la pantalla de los
                    teléfonos móviles.
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Robo:
                <List.List>
                  <List.Item>
                    Regla de equidad en caso de robo, en caso de no disponer de
                    las medidas declaradas en el seguro.
                  </List.Item>
                  <List.Item>
                    Opción de contratar el robo del mobiliario del jardín.
                  </List.Item>
                  <List.Item>
                    Opción de contratar el robo de Placas Solares antenas y
                    otras instalaciones del continente al exterior.
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Atraco fuera del hogar:
                <List.List>
                  <List.Item>
                    Límite de 1500€ para ropa y objetos de uso personal.
                  </List.Item>
                  <List.Item>Límite de 300€ en dinero en efectivo.</List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Responsabilidad Civil:
                <List.List>
                  <List.Item>Límite de 600.000€.</List.Item>
                  <List.Item>
                    Inclusión de la Responsabilidad Civil Patronal.
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Daños Accidentales:
                <List.List>
                  <List.Item>
                    Opción de contratación de la garantía, siempre y cuando se
                    contraten las siguientes garantías.
                  </List.Item>
                  <List.Item>Asistencia informática telefónica.</List.Item>
                  <List.Item>Asistencia informática a domicilio.</List.Item>
                </List.List>
              </List.Item>
              <List.Item>
                Alquiler:
                <List.List>
                  <List.Item>
                    Daños por agua: localización y reparación de tuberías sin
                    daños, exceso consumo de agua, desatasco sin daños:
                    optativas.
                  </List.Item>
                  <List.Item>Daño estético limite 2.000€.</List.Item>
                  <List.Item>Deterioro de jardín: optativa.</List.Item>
                  <List.Item>
                    Rotura de mármoles, placas vitrocerámicas, placas solares:
                    optativa.
                  </List.Item>
                  <List.Item>
                    Robo y hurto de mobiliario de jardín, placas solares,
                    antenas y otras instalaciones del continente exterior:
                    optativa
                  </List.Item>
                </List.List>
              </List.Item>
            </List.List>
          </List.Item>
        </List>
        <br />
        (Ver fichero anexo cuadro de diferencias y mejoras).
      </div>
    );
  }
}

export default HogarPlusultra;
