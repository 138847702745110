import React from "react";
import { Table } from "semantic-ui-react";

export const TableView = ({ data, ...rest }) => (
  <Table compact {...rest}>
    <Table.Body>
      {data.map((v) => (
        <Table.Row key={v.name}>
          <Table.Cell active collapsing>
            {v.name}
          </Table.Cell>
          <Table.Cell>{v.value}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
