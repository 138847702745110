import React, { Component } from "react";
import { List, Table } from "semantic-ui-react";

class AccidentesAig extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO CENTRALIZADO</strong>.
        </List.Item>
        <List.Item>
          COMISIÓN: <strong>25%</strong>.
        </List.Item>
        <List.Item>
          PROFESIONES: Pensados para riesgos de categoría 1 con uso de vehículo
          (Profesiones <strong>sin trabajo manual</strong>).
        </List.Item>
        <List.Item>
          <strong>CENTRALIZADO</strong>, deberéis mandar la solicitud
          cumplimentada <strong>junto con copia del DNI</strong> del
          solicitante/cliente a
          <a href="mailto:alejandro.conde@espabrok.com">
            alejandro.conde@espabrok.com
          </a>
          .
        </List.Item>
        <List.Item>
          TRES OPCIONES DE COBERTURAS Y CAPITALES.
          <Table
            unstackable
            compact="very"
            size="small"
            celled
            structured
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">CLASSIC</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>COBERTURAS</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">LÍMITE</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Fallecimiento por accidente</Table.Cell>
                <Table.Cell textAlign="right">150.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Fallecimiento simultáneo de ambos cónyuges por accidente de
                  circulación *
                </Table.Cell>
                <Table.Cell textAlign="right">50.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Fallecimiento por infarto de miocardio por accidente laboral
                </Table.Cell>
                <Table.Cell textAlign="right">100.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente absoluta por accidente
                </Table.Cell>
                <Table.Cell textAlign="right">150.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente parcial por accidente (según baremo)
                </Table.Cell>
                <Table.Cell textAlign="right">150.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente absoluta por accidente de circulación *
                </Table.Cell>
                <Table.Cell textAlign="right">200.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Indemnización por hijos dependientes</Table.Cell>
                <Table.Cell textAlign="right">2.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Indemnización diaria por hospitalización
                </Table.Cell>
                <Table.Cell textAlign="right">50,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Gastos de reforma de la vivienda en caso de invalidez
                  permanente por accidente
                </Table.Cell>
                <Table.Cell textAlign="right">5.000,00 €</Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="2" textAlign="right">
                  <strong>PRIMA TOTAL ANUAL: 92 €</strong>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Table
            unstackable
            compact="very"
            size="small"
            celled
            structured
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">PLUS</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>COBERTURAS</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">LÍMITE</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Fallecimiento por accidente</Table.Cell>
                <Table.Cell textAlign="right">250.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Fallecimiento simultáneo de ambos cónyuges por accidente de
                  circulación *
                </Table.Cell>
                <Table.Cell textAlign="right">50.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Fallecimiento por infarto de miocardio por accidente laboral
                </Table.Cell>
                <Table.Cell textAlign="right">100.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente absoluta por accidente
                </Table.Cell>
                <Table.Cell textAlign="right">250.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente parcial por accidente (según baremo)
                </Table.Cell>
                <Table.Cell textAlign="right">250.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente absoluta por accidente de circulación *
                </Table.Cell>
                <Table.Cell textAlign="right">300.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Indemnización por hijos dependientes</Table.Cell>
                <Table.Cell textAlign="right">2.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Indemnización diaria por hospitalización
                </Table.Cell>
                <Table.Cell textAlign="right">50,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Gastos de reforma de la vivienda en caso de invalidez
                  permanente por accidente
                </Table.Cell>
                <Table.Cell textAlign="right">5.000,00 €</Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="2" textAlign="right">
                  <strong>PRIMA TOTAL ANUAL: 173 €</strong>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Table
            unstackable
            compact="very"
            size="small"
            celled
            structured
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">PREMIER</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>COBERTURAS</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">LÍMITE</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Fallecimiento por accidente</Table.Cell>
                <Table.Cell textAlign="right">350.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Fallecimiento simultáneo de ambos cónyuges por accidente de
                  circulación *
                </Table.Cell>
                <Table.Cell textAlign="right">50.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Fallecimiento por infarto de miocardio por accidente laboral
                </Table.Cell>
                <Table.Cell textAlign="right">100.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente absoluta por accidente
                </Table.Cell>
                <Table.Cell textAlign="right">350.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente parcial por accidente (según baremo)
                </Table.Cell>
                <Table.Cell textAlign="right">350.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Invalidez permanente absoluta por accidente de circulación *
                </Table.Cell>
                <Table.Cell textAlign="right">400.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Indemnización por hijos dependientes</Table.Cell>
                <Table.Cell textAlign="right">2.000,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Indemnización diaria por hospitalización
                </Table.Cell>
                <Table.Cell textAlign="right">50,00 €</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Gastos de reforma de la vivienda en caso de invalidez
                  permanente por accidente
                </Table.Cell>
                <Table.Cell textAlign="right">5.000,00 €</Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="2" textAlign="right">
                  <strong>PRIMA TOTAL ANUAL: 265 €</strong>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <List.List style={{ fontStyle: "italic" }}>
            <List.Item>
              Quedan incluidos los accidentes en ciclomotores y motocicletas de
              cualquier cilindrada.
            </List.Item>
            <List.Item>
              Quedan incluido la práctica de cualquier deporte excepto cuando
              éste se practica como profesional.
            </List.Item>
            <List.Item>Siempre está incluida la invalidez parcial.</List.Item>
            <List.Item>Capitales de invalidez no acumulativos.</List.Item>
            <List.Item>Comisión 25%.</List.Item>
          </List.List>
        </List.Item>
      </List>
    );
  }
}

export default AccidentesAig;
