import React, { Component } from "react";
import { List, Table } from "semantic-ui-react";

class AutonomoSurne extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO DESCENTRALIZADO</strong>.
        </List.Item>
        <List.Item>
          <strong>COMISIÓN</strong>.
          <List.List>
            <List.Item>Vida: 40% lineal.</List.Item>
            <List.Item>Accidentes: 30% lineal.</List.Item>
            <List.Item>Baja Diaria: 25% NP – 22.5% Cartera.</List.Item>
          </List.List>
          <Table
            unstackable
            compact
            celled
            structured
            textAlign="center"
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">
                  VIDA ESPABROK
                </Table.HeaderCell>
                <Table.HeaderCell>BASIC</Table.HeaderCell>
                <Table.HeaderCell>LIBRE</Table.HeaderCell>
                <Table.HeaderCell>PREMIUM</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Fallecimiento por enfermedad y accidentes
                </Table.Cell>
                <Table.Cell>30.000</Table.Cell>
                <Table.Cell>50.000</Table.Cell>
                <Table.Cell>90.000</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Fallecimiento por enfermedad y accidentes
                </Table.Cell>
                <Table.Cell>30.000</Table.Cell>
                <Table.Cell>50.000</Table.Cell>
                <Table.Cell>90.000</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table
            unstackable
            compact
            celled
            structured
            textAlign="center"
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">ACCIDENTES</Table.HeaderCell>
                <Table.HeaderCell>BASIC</Table.HeaderCell>
                <Table.HeaderCell>LIBRE</Table.HeaderCell>
                <Table.HeaderCell>PREMIUM</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Fallecimiento por accidente
                </Table.Cell>
                <Table.Cell>30.000</Table.Cell>
                <Table.Cell>50.000</Table.Cell>
                <Table.Cell>90.000</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Invalidez permanente absoluta por accidente
                </Table.Cell>
                <Table.Cell>30.000</Table.Cell>
                <Table.Cell>50.000</Table.Cell>
                <Table.Cell>90.000</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Asistencia sanitaria por accidente
                </Table.Cell>
                <Table.Cell>3.000</Table.Cell>
                <Table.Cell>3.000</Table.Cell>
                <Table.Cell>3.000</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table
            unstackable
            compact
            celled
            structured
            textAlign="center"
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">
                  BAJA DIARIA
                </Table.HeaderCell>
                <Table.HeaderCell>BASIC</Table.HeaderCell>
                <Table.HeaderCell>LIBRE</Table.HeaderCell>
                <Table.HeaderCell>PREMIUM</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="left">
                  Franquicia 7 días (Período máximo: 1 AÑO)
                </Table.Cell>
                <Table.Cell>30€ / día</Table.Cell>
                <Table.Cell>50€ / día</Table.Cell>
                <Table.Cell>60€ / día</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </List.Item>
      </List>
    );
  }
}

export default AutonomoSurne;
