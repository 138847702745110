import React, { Component } from "react";
import { Link } from "@reach/router";
import { Icon, Button, Dropdown } from "semantic-ui-react";
import styled from "styled-components";

import { withUserContext } from "./Context";

const User = styled.span`
  & span {
    display: none;
  }
  @media (min-width: 1024px) {
    & span {
      display: inline;
    }
  }
`;

class HeaderMenu extends Component {
  handleLogOut = () => {
    this.props.user.logoutUser();
  };

  render() {
    const { userEmail, userName, userIsAdmin } = this.props.user;

    return (
      <Dropdown
        trigger={
          <User>
            <Icon name="user circle" size="big" />
            <span>{userName}</span>
          </User>
        }
        pointing="top right"
      >
        <Dropdown.Menu
          direction="left"
          style={{ minWidth: "170px", zIndex: "2000" }}
        >
          <Dropdown.Header
            style={{
              textTransform: "none",
              fontSize: "0.9em",
              fontWeight: "normal",
            }}
          >
            <strong>{userName}</strong>
            <br />
            {userEmail}
          </Dropdown.Header>
          <Dropdown.Item as={Link} icon="user" text="Perfil" to="/perfil" />
          <Dropdown.Divider />
          <Dropdown.Item
            as={Link}
            icon="building"
            text="Corredurías"
            to="/corredurias"
          />
          <Dropdown.Item
            as={Link}
            icon="users"
            text="Usuarios"
            to="/usuarios"
          />
          {userIsAdmin() && (
            <Dropdown.Item
              as={Link}
              icon="calendar alternate outline"
              text="Eventos"
              to="/eventos"
            />
          )}
          <Dropdown.Divider />
          <Dropdown.Item>
            <Button
              size="small"
              fluid
              color={"blue"}
              icon="log out"
              content="Salir"
              onClick={this.handleLogOut}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default withUserContext(HeaderMenu);
