import React, { Component } from "react";
import { List } from "semantic-ui-react";

class PolizaPYSolunion extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO CENTRALIZADO</strong>.
        </List.Item>

        <br />
        <List.Item>
          <p>
            <strong>COMISIÓN: </strong>
            12 %.
          </p>
        </List.Item>

        <br />
        <List.Item>
          <strong>TARGET OFERTA:</strong>
          <br />
          <p>Pequeña empresa que busca crecer con seguridad</p>
        </List.Item>

        <br />
        <List.Item>
          <p>
            <strong>COBERTURA: </strong>
            del 85%.
          </p>
        </List.Item>

        <br />
        <List.Item>
          <strong>CONDICIONES DE CONTRATACIÓN: </strong>
          <List>
            <List.Item>
              A. Sectores no asegurables con la Póliza PY:
              <List.List>
                <List.Item>
                  Los Clientes pertenecientes a los siguientes sectores y
                  actividades no podrán ser asegurados en esta modalidad:
                  construcción, metal, minoristas, textil, factoring y las
                  actividades de alquiler, joyería y software.
                </List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              B. Cobertura de grade para incrementar la protección en el momento
              de acordar nuevas ventas a crédito para los riesgos más sensibles.
            </List.Item>
          </List>
        </List.Item>

        <br />

        <List.Item>
          <strong>OPERATIVA DE CONTRATACIÓN</strong>:
          <br />
          <p>
            El proceso que se debe seguir para solicitar la emisión de una
            póliza PY, es el siguiente:
          </p>
          <div>
            <p>
              <strong>1.</strong> Completar el cuestionario Excel con todos los
              campos en formato digital, para que así pueda procesarse
              automáticamente. Si no se envía de esta manera o no está completo
              no podremos gestionarla.
            </p>
            <p>
              <strong>2.</strong> Una vez cumplimentado, debe firmarse por el
              cliente y escanearse en PDF.
            </p>
            <p>
              <strong>3.</strong> Enviar a{" "}
              <a href="mailto:alejandro.conde@espabrok.com">
                alejandro.conde@espabrok.com
              </a>{" "}
              tanto el Excel cumplimentado, como el PDF firmado.
            </p>
          </div>
        </List.Item>
      </List>
    );
  }
}

export default PolizaPYSolunion;
