import React from "react";
import { List, Table, Header } from "semantic-ui-react";

const RCConstruccionAllianz = () => {
  return (
    <React.Fragment>
      <Header as="h3">MARCO DE SUSCRIPCIÓN</Header>
      Las presentes condiciones de suscripción resultarán de aplicación para:
      <br />
      <List bulleted>
        <List.Item>Nueva Producción.</List.Item>
        <List.Item>
          Modalidad de Seguro: R. Civil para empresas del sector de la
          Construcción
        </List.Item>
        <List.Item>
          Segmento de Negocio: MidCorp bajo. Empresas con volumen de obra
          inferior a 25.000.000 Euros
        </List.Item>
        <List.Item>Situación Riesgo: Territorio Nacional</List.Item>
        <List.Item>
          La Compañía se reserva la facultad de limitar su Participación en cada
          Riesgo
        </List.Item>
        <List.Item>Duración: Anual</List.Item>
        <List.Item>
          <strong>ACTIVIDADES NO ASEGURABLES.</strong> No son objeto de
          contratación los siguientes riesgos:
          <List bulleted>
            <List.Item>
              Obras de reforma afectando a elementos de carga.
            </List.Item>
            <List.Item>Obras Off-shore (fuera de costa)</List.Item>
            <List.Item>Obras que incluyan trabajos en agua.</List.Item>
            <List.Item>Emisarios submarinos.</List.Item>
            <List.Item>
              Obras realizadas mediante procesos experimentales o usando
              prototipos.
            </List.Item>
            <List.Item>Obras en puertos y aeropuertos.</List.Item>
            <List.Item>Vías férreas.</List.Item>
            <List.Item>
              Construcción y reparación de presas y embalses.
            </List.Item>
            <List.Item>La construcción y reparación de puentes.</List.Item>
            <List.Item>La construcción y reparación de túneles.</List.Item>
            <List.Item>Derribos.</List.Item>
          </List>
        </List.Item>
        <List.Item>
          El <strong>LÍMITE GLOBAL</strong> para el Conjunto de Prestaciones del
          Asegurador para todo tipo de responsabilidades será el siguiente:
          <List>
            <List.Item>Por siniestro: 3.000.000.</List.Item>
            <List.Item>Por anualidad: 3.000.000.</List.Item>
            <List.Item>
              Por víctima: según lo especificado en cada garantía.
            </List.Item>
          </List>
        </List.Item>
      </List>
      <Header as="h3">CUADRO DE GARANTIAS, LIMITES Y FRANQUICIAS</Header>
      <Header as="h3">GARANTÍAS BÁSICAS</Header>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell>LÍMITES SIN/AÑO</Table.HeaderCell>
            <Table.HeaderCell>SUBLÍMITES VICT</Table.HeaderCell>
            <Table.HeaderCell>FRANQUICIA</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>RC Explotación</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>600,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Inmobiliaria</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>600,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              RC Subsidiaria Subcontratistas y vehículos a motor
            </Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>600,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Daños a Colindantes / Conducciones</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>1.500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Patronal</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>SIN FRANQUICIA</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Cruzada</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>SIN FRANQUICIA</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Post Trabajos</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>1.500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Trabajos en caliente</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>1.500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Transporte de la carga*</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>GENERAL</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Defensa y fianzas</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Conflicto de intereses</Table.Cell>
            <Table.Cell>10.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <strong>*Excepto mercancías peligrosas (PERM)</strong>
      <br />
      <br />
      <Header as="h3">GARANTÍAS OPCIONALES</Header>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell>LÍMITES SIN/AÑO</Table.HeaderCell>
            <Table.HeaderCell>SUBLÍMITES VICT</Table.HeaderCell>
            <Table.HeaderCell>FRANQUICIA</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>RC Locativa</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>600,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Contaminación súbita / accidental</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>600,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Daños a bienes preexistentes*</Table.Cell>
            <Table.Cell>300.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Productos</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>1.500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Unión y mezcla</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>1.500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Sustitución</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>1.500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Profesional (técnicos en plantilla)**</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>1.500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Derribo y demoliciones***</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>RC Daños a bienes de empleados</Table.Cell>
            <Table.Cell>3.000,00 €</Table.Cell>
            <Table.Cell>-</Table.Cell>
            <Table.Cell>300,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>UTES</Table.Cell>
            <Table.Cell>3.000.000,00 €</Table.Cell>
            <Table.Cell>600.000,00 €</Table.Cell>
            <Table.Cell>GENERAL</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <strong>
        *Su contratación está supeditada a que tenga contratada póliza en la
        Compañía de TRC
      </strong>
      <br />
      <strong>**Excluidos Daños Patrimoniales Primarios</strong>
      <br />
      <strong>***Con requisitos específicos</strong>
      <br />
      <br />
      <Header as="h3">GARANTÍAS NO CONTRATABLES</Header>
      <List bulleted>
        <List.Item>RC Infidelidad de empleados.</List.Item>
        <List.Item>RC Protección de datos.</List.Item>
        <List.Item>Sanciones LOPD.</List.Item>
        <List.Item>Gastos de retirada de productos.</List.Item>
        <List.Item>Daños a la propia obra / Propios productos.</List.Item>
        <List.Item>RC Probadores.</List.Item>
      </List>
      <Header as="h3">CONDICIONES ECONÓMICAS</Header>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ASEGURADO</Table.HeaderCell>
            <Table.HeaderCell>VALOR DE OBRA</Table.HeaderCell>
            <Table.HeaderCell>TASA POR MIL</Table.HeaderCell>
            <Table.HeaderCell>PN MÍNIMA</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell rowSpan="3">Promotor</Table.Cell>
            <Table.Cell>0-3.000.000 €</Table.Cell>
            <Table.Cell>1,28</Table.Cell>
            <Table.Cell rowSpan="3">500,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3-10.000.000 €</Table.Cell>
            <Table.Cell>1,06</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>10-25.000.000 €</Table.Cell>
            <Table.Cell>0,97</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell rowSpan="3">Contratista</Table.Cell>
            <Table.Cell>0-3.000.000 €</Table.Cell>
            <Table.Cell>1,6</Table.Cell>
            <Table.Cell rowSpan="3">750,00 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3-10.000.000 €</Table.Cell>
            <Table.Cell>1,33</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>10-25.000.000 €</Table.Cell>
            <Table.Cell>1,21</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <strong>
        *Estas tasas y primas corresponden a las garantías básicas y por tanto
        podrán incrementar con la contratación de garantías opcionales cuyo
        importe se determinará caso por caso.
      </strong>
    </React.Fragment>
  );
};

export default RCConstruccionAllianz;
