import React, { Component } from 'react'
import { List } from 'semantic-ui-react'

class RcHiscox extends Component {
  render() {
    return (
      <div>
        <strong>MEJORAS PARA ESPABROK</strong>:
        <List bulleted>
          <List.Item>
            RC General y RC Patronal según el límite elegido por el cliente.
          </List.Item>
          <List.Item>
            No incremento de sobreprima si el cliente contrata por nuestro
            E-Quote la opción de inclusión de la RC General de Explotación,
            Patronal Y Productos/Postrabajos. La no elección de las coberturas
            anteriores no implica ninguna rebaja de prima neta.
          </List.Item>
          <List.Item>
            Inhabilitación profesional: 30.000€ (máximo 2.500€/mes por un
            periodo máximo de 12 meses).
          </List.Item>
          <List.Item>
            Infidelidad de Empleados: 250.000€ por reclamación y anualidad de
            seguro.
          </List.Item>
          <List.Item>Franquicia según facturación.</List.Item>
          <List.Item>
            Gastos de Asistencia Psicológica: sublimite de 80.000€. No se aplica
            franquicia a esta cobertura.
          </List.Item>
          <List.Item>
            Compensación por asistencia a juicio. No se aplica franquicia a esta
            cobertura.
          </List.Item>
          <List.Item>
            Pérdida de la persona clave durante un mes tras dicha pérdida. No se
            aplica franquicia a esta cobertura.
          </List.Item>
          <List.Item>
            Ámbito Territorial y Jurisdiccional: Todo el mundo excepto
            USA/Canadá.
          </List.Item>
        </List>
        <br />
        <strong>COMISIÓN</strong>: 25%.
        <br />
        <br />
        <br />
        <strong>CONTRATACIÓN</strong>:
        <List bulleted>
          <List.Item>
            Mediante{' '}
            <a
              href="https://myhiscox.es"
              target="_blank"
              rel="noopener noreferrer"
            >
              E-Quote
            </a>
            . Se realiza la solicitud por la herramienta y en menos de 48h
            emitirán la póliza.
          </List.Item>
          <List.Item>Renovación tácita en el 90% de los casos.</List.Item>
          <List.Item>
            Si no disponéis de usuario podéis solicitarlo directamente a través
            de la{' '}
            <a
              href="https://myhiscox.es/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              web de Hiscox
            </a>
            .
          </List.Item>
        </List>
        <br />
        <strong>APOYO A LA VENTA</strong>:
        <br />
        <br />
        Disponéis de más información sobre este producto en la{' '}
        <a
          href="https://www.hiscox.es/hiscox-rc-profesional"
          target="_blank"
          rel="noopener noreferrer"
        >
          Web de la Cía
        </a>
        . En breve dispondremos de toda esta documentación personalizada para
        Espabrok.
      </div>
    )
  }
}

export default RcHiscox
