import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class AccidentesseniorSurne extends Component {
  render() {
    return (
      <div>
        <strong>COMISIÓN</strong>: 20%.
        <br />
        <br />
        <strong>EDAD MÁXIMA DE ENTRADA</strong>: 75 AÑOS.
        <br />
        <br />
        <strong>EDAD MÁXIMA DE PERMANENCIA</strong>: HASTA LOS 85 AÑOS.
        <br />
        <br />
        <strong>CONDICIONADO Y SOLICITUD PROPIOS PARA ESPABROK</strong>.
        <br />
        <br />
        <strong>CENTRALIZADO</strong>:
        <br />
        <p style={{ marginTop: "0.4rem" }}>
          Deberéis mandar la solicitud cumplimentada junto con copia del DNI del
          solicitante/cliente a
          <a href="mailto:alejandro.conde@espabrok.com">
            alejandro.conde@espabrok.com
          </a>
          . Aquellos que deseéis abrir clave directamente con la Cía. podéis
          solicitarlo a{" "}
          <a href="mailto:comercial@espabrok.es">comercial@espabrok.es</a>.
        </p>
        <strong>TRES OPCIONES DE COBERTURAS Y CAPITALES</strong>:
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Table
            unstackable
            collapsing
            size="small"
            celled
            style={{ margin: "1rem 0" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={5}>
                  COBERTURA SOLICITADA
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>OPCIÓN 25</Table.Cell>
                <Table.Cell>
                  FALLECIMIENTO POR ACCIDENTE
                  <br />
                  PRIMA TOTAL ANUAL
                </Table.Cell>
                <Table.Cell textAlign="right">
                  25.000,00€
                  <br />
                  146€
                </Table.Cell>
                <Table.Cell>
                  Hospitalización por accidente
                  <br />
                  Otras fracturas según baremo
                </Table.Cell>
                <Table.Cell>25€ NOCHE*</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>OPCIÓN 50</Table.Cell>
                <Table.Cell>
                  FALLECIMIENTO POR ACCIDENTE
                  <br />
                  PRIMA TOTAL ANUAL
                </Table.Cell>
                <Table.Cell textAlign="right">
                  50.000,00€
                  <br />
                  292€
                </Table.Cell>
                <Table.Cell>
                  Hospitalización por accidente
                  <br />
                  Otras fracturas según baremo
                </Table.Cell>
                <Table.Cell>50€ NOCHE*</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>OPCIÓN 75</Table.Cell>
                <Table.Cell>
                  FALLECIMIENTO POR ACCIDENTE
                  <br />
                  PRIMA TOTAL ANUAL
                </Table.Cell>
                <Table.Cell textAlign="right">
                  75.000,00€
                  <br />
                  438€
                </Table.Cell>
                <Table.Cell>
                  Hospitalización por accidente
                  <br />
                  Otras fracturas según baremo
                </Table.Cell>
                <Table.Cell>75€ NOCHE*</Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={5}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>FORMA DE PAGO: Anual/Semestral/Trimestral</div>
                    <div>*Límite hospitalización 60 noches</div>
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    );
  }
}

export default AccidentesseniorSurne;
