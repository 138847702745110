import React from "react";
import { List } from "semantic-ui-react";

const CiberseguroTelefonicaEspabrok = () => {
  return (
    <List>
      <List.Item>
        <strong>PRODUCTO CENTRALIZADO</strong>.
      </List.Item>
      <List.Item>
        <strong>OPERATIVA DE CONTRATACIÓN:</strong>
        <br />
        <p>
          Durante finales de 05/2021 hasta principios de 06/2021 desde
          Telefónica se remiten a las Corredurías asociadas a Espabrok (a los
          emails dominio @espabrok) los usuarios de cada Correduría
        </p>
        <p>
          Debéis acceder a{" "}
          <a href="https://seguros.movistar.es/area-privada/">
            https://seguros.movistar.es/area-privada/
          </a>{" "}
          indicando vuestro usuario y debéis habilitar vuestra contraseña
          individual e intransferible.
        </p>
        <p>
          A partir de este momento podéis tarificar y emitir pólizas de
          ciberriesgo con Telefónica.
        </p>
        <p>
          Tanto para los proyectos como para las emisiones podéis elegir que la
          documentación se remita directamente al email de vuestro cliente.
        </p>
        <p>
          En el caso de emisión, cuando recibáis la documentación de la póliza
          emitida debéis remitir a{" "}
          <a href="mailto:mariajose.sanchez@espabrok.com">
            mariajose.sanchez@espabrok.com
          </a>
          /{" "}
          <a href="mailto:nerea.ortiz@espabrok.com">nerea.ortiz@espabrok.com</a>
          , el SEPA cumplimentado y firmado por el cliente (para que Telefónica
          pueda girar el recibo) y la copia de la póliza firmada (para su
          traslado a la aseguradora y grabación de los datos en el sistema
          Segelevia).
        </p>
      </List.Item>
      <List.Item>
        <strong>COMISIÓN: 20%</strong>
      </List.Item>
      <List.Item>
        <strong>MEJORAS: </strong>
        <List bulleted>
          <List.Item>Primera Asociación con esta opción de seguro.</List.Item>
          <List.Item>
            Producto con escasa Medicación actualmente (hasta ahora Telefónica
            oferta directamente a cliente a través de web)
          </List.Item>
          <List.Item>
            Producto realizado a través de reaseguro con la aseguradora líder
            Beazley en condiciones únicas para Telefónica.
          </List.Item>
          <List.Item>
            Telefónica realiza el servicio de asistencia en caso de incidencia
            (garantía de empresa líder)
          </List.Item>
          <List.Item>
            El límite contratado es individual para cada una de las garantías de
            la póliza, no se trata de límite total para todas las garantías.
          </List.Item>
          <List.Item>No se aplica franquicia de ningún tipo.</List.Item>
        </List>
      </List.Item>
      <List.Item>
        <strong>ACTIVIDADES EXCLUIDAS:</strong>
        <List bulleted>
          <List.Item>Transporte aéreo de pasajeros</List.Item>
          <List.Item>Telecomunicaciones</List.Item>
          <List.Item>Internet Service providers</List.Item>
          <List.Item>Redes Sociales</List.Item>
          <List.Item>Webs de intercambio de ficheros</List.Item>
          <List.Item>Webs de adultos (pornografía y citas)</List.Item>
          <List.Item>Actividades de juegos y apuestas</List.Item>
        </List>
      </List.Item>
      <List.Item>
        <strong>DETALLE DE COBERTURAS</strong> <br />
        <br />
        <i>
          Es obligatorio facilitar a la hora de emitir una póliza, los datos
          personales (nombre, cargo, teléfono y email) del contacto o
          responsable técnico del Tomador, a fin de que los servicios de
          asistencia en caso de incidencia puedan contactar con el cliente y dar
          el pertinente servicio.
        </i>
        <br />
        <br />
        Queda cubierto automáticamente:
        <br />
        <br />
        <strong>Respuesta a incidentes cibernéticos:</strong>
        <List bulleted>
          <List.Item>Costes de Respuesta ante Incidentes</List.Item>
          <List.Item>Costes de Respuesta ante Incidentes</List.Item>
          <List.Item>
            Costes por Procedimientos Legales y Regulatorios
          </List.Item>
          <List.Item>Costes Forenses y de Seguridad Informática</List.Item>
          <List.Item>Costes de Comunicación de Crisis</List.Item>
          <List.Item>Costes de Gestión por Vulneración de Privacidad</List.Item>
          <List.Item>
            Costes de Gestión por Vulneración de Privacidad de Terceros
          </List.Item>
          <List.Item>
            Costes de Remediación posteriores a la Vulneración de Privacidad
          </List.Item>
        </List>
        <strong>
          Responsabilidad por Seguridad en las Redes y Privacidad:
        </strong>
        <List bulleted>
          <List.Item>Responsabilidad por Seguridad en las Redes</List.Item>
          <List.Item>Responsabilidad por Privacidad</List.Item>
          <List.Item>Responsabilidad de Gestión</List.Item>
          <List.Item>Sanciones Administrativas</List.Item>
          <List.Item>Multas, Sanciones y Cargos PCI</List.Item>
          <List.Item>Difamación</List.Item>
          <List.Item>
            Vulneración de los Derechos de Propiedad Intelectual
          </List.Item>
        </List>
        <strong>Daños a los Sistemas e Interrupción de Negocio</strong>
        <List bulleted>
          <List.Item>Daños a los Sistemas y Costes de Rectificación</List.Item>
          <List.Item>Interrupción del Negocio</List.Item>
          <List.Item>Daño Reputacional Consecuente</List.Item>
          <List.Item>Gastos de Ajuste de Siniestro</List.Item>
        </List>
        <strong>Ciberdelincuencia:</strong>
        <List bulleted>
          <List.Item>Extorsión Cibernética</List.Item>
          <List.Item>Fraude por Trasferencia de Fondos</List.Item>
          <List.Item>Robo de Identidad Digital</List.Item>
        </List>
        <strong>Extensiones opcionales (con sobreprima):</strong>
        <List bulleted>
          <List.Item>Robo de fondos de la cuenta fiduciaria</List.Item>
          <List.Item>Robo de fondos Personales</List.Item>
          <List.Item>Hacking telefónico</List.Item>
          <List.Item>Phising</List.Item>
        </List>
      </List.Item>
      <List.Item>
        <strong>DOCUMENTACION:</strong> Se acompaña el listado de actividades
        asegurables, ejemplo de condiciones particulares, documento informativo
        de la póliza, detalle de la extensión de coberturas,{" "}
        <strong>
          <i>
            cuestionario a cumplimentar en los casos en los que no sea posible
            la contratación por la plataforma
          </i>
        </strong>{" "}
        y el obligatorio IPID
      </List.Item>
    </List>
  );
};

export default CiberseguroTelefonicaEspabrok;
