import React, { Component, useContext } from "react";
import { Tab, Image } from "semantic-ui-react";
import styled from "styled-components";

import { UserContext } from "../Context/User";

import logoElevia from "../../images/tools/logo_elevia.png";
import logoAvant2 from "../../images/tools/logo_avant2.png";
import logoEspabrok from "../../images/tools/logo_espabrok.svg";
import iconWhatsapp from "../../images/tools/icon_whatsapp.svg";
import logoPildoras from "../../images/tools/pildoras_elevia.png";
//import logoSegNet from "../../images/tools/logo_segnet.png";
//import logoWebinars from "../../images/tools/logo_webinars.png";
import logoELearning from "../../images/tools/logo_elearning.png";
import logoPortaSigma from "../../images/tools/logo_portasigma.png";
import logoRedesSociales from "../../images/tools/logo_rrss.png";
import logoEleviaCentral from "../../images/tools/logo_elevia_central.png"
import logoJubilame from "../../images/tools/logo_jubilame.png"
import logoCotizador from "../../images/tools/logo_segcotizador_blue.png"
import logoMensagia from "../../images/tools/logo_mensagia.png"

/* ITEMS */
const items = [
  {
    id: "elearning",
    image: logoELearning,
    text: "Inst. e-Learning",
    url: "https://e-campusformacion.com/",
  },
  {
    id: "rrss",
    image: logoRedesSociales,
    text: "Redes sociales",
    url: "https://rrss.espabrok.es/login",
  },
  {
    id: "whatsapp",
    image: iconWhatsapp,
    text: "WhatsApp",
    url: "https://web.whatsapp.com",
  },
  {
    id: "brand",
    image: logoEspabrok,
    text: "Imagen de Marca",
    url: "http://marca.espabrok.es",
  },
  {
    id: "rgpd",
    image: logoPildoras,
    text: "Píldoras Elevia MPM",
    url: "https://cloud.espabrok.es/index.php/apps/files/?dir=/P%C3%8DLDORAS%20ELEVIA_MPM&fileid=334418",
  },
  {
    id: "segnet",
    image: logoCotizador,
    text: "Cotizador",
    url: " https://3359.segelevia.com/SegElevia3359/PageRender.mvc/ecCollaborator.segProducts?ec_Company_Code=3359",
  },
  {
    id: "portasigma",
    image: logoPortaSigma,
    text: "PortaSigma",
    url: "https://app.portasigma.com/login.jsp",
  },
  {
    id: "jubilame",
    image: logoJubilame,
    text: "Jubilame",
    url: "https://espabrokinversiones.jubilame.com/",
  },
  {
    id: "segelevia",
    image: logoEleviaCentral,
    text: "SegElevia Espabrok Central",
    url: "https://3359.segelevia.com",
  },
  {
    id: "mensagia",
    image: logoMensagia,
    text: "Mensagia",
    url: "https://mensagia.com/login",
  },
  
  /*{
    id: "webinars",
    image: logoWebinars,
    text: "Webinars",
    url: "https://cloud.espabrok.es/index.php/f/15651",
  },*/
];

const ToolsTabPane = styled(Tab.Pane)`
  height: auto;
  @media (min-width: 768px) {
    height: auto;
  }
  @media (min-width: 1280px) {
    height: 350px;
  }
`;
const Items = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: -0.65%;
`;
const Item = styled.div`
  width: 45%;
  margin: 0.65%;
  padding: 0.8rem 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (min-width: 768px) {
    width: 32%;
  }

  @media (min-width: 1280px) {
    width: 23%;
  }


  & a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  & .item-img {
    min-width: 40px !important;
    max-width: 90px !important;
    max-height: 40px !important;
  }
  & .item-text {
    font-size: 0.95rem;
    margin-top: 0.6rem;
    text-align: center;
    white-space: nowrap;
  }
  & > a:hover {
    transition: all 0.3s ease;
    transform: scale(1.05);
  }
`;

const LinksGrid = ({ items }) => {
  const { userSegEleviaLink, userAvantLink } = useContext(UserContext);

  return (
    <Items>
      {userSegEleviaLink && (
        <Item text="SEG Elevia">
          <a href={userSegEleviaLink} target="_blank" rel="noopener noreferrer">
            <Image src={logoElevia} className="item-img" align="center" />
          </a>
          <div className="item-text">SEG Elevia</div>
        </Item>
      )}
      {items.map((item) => {
        // Show webinars link only to users with 'GERENTE' tag.
        // if (item.text === 'Webinars' && !userFlags.includes('GERENTE')) return null

        return (
          <Item key={item.id} text={item.text}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <Image src={item.image} className="item-img" align="center" />
            </a>
            {item.text && <div className="item-text">{item.text}</div>}
          </Item>
        );
      })}
      {userAvantLink && (
        <Item text="Avant2">
          <a href={userAvantLink} target="_blank" rel="noopener noreferrer">
            <Image src={logoAvant2} className="item-img" align="center" />
          </a>
          <div className="item-text">Avant2</div>
        </Item>
      )}
    </Items>
  );
};

const panes = [
  {
    menuItem: "Herramientas",
    render: () => (
      <ToolsTabPane style={{ paddingBottom: "2rem" }}>
        <LinksGrid items={items} />
      </ToolsTabPane>
    ),
  },
];

/*const WidgetTools = () => {
  const { id } = this.props;
  return <div id={id}><Tab panes={panes} /></div>;
};*/

class WidgetTools extends Component {
  render() {
    const { id } = this.props;
    return (
      <div id={id}><Tab panes={panes}/></div>
    )
  }
}

export default WidgetTools;
