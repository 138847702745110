import React from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";

const PrivacyPolicyStyled = styled.div`
  padding: 3rem 2rem;
  .header {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  .section-title {
    text-align: center;
    font-size: 1.6em;
    font-weight: bold;
    margin: 3rem 0 2.6rem 0;
  }
  .title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 1.8rem 0 1rem 0;
  }
  @media (min-width: 768px) {
    .table {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyStyled>
      <div className="header">POLÍTICA DE PRIVACIDAD</div>
      <p>
        En ESPABROK CORREDURIA DE SEGUROS, SA, nos preocupamos por la privacidad
        y la transparencia.
      </p>
      <p>
        A continuación, le indicamos en detalle los tratamientos de datos
        personales que realizamos, así como toda la información relativa a los
        mismos. De acuerdo con lo establecido por el REGLAMENTO (UE) 2016/679,
        de 27 de abril de 2016 del Parlamento Europeo, y del Consejo relativo a
        la Protección de las personas físicas en lo que respecta al tratamiento
        de sus datos personales y por la Ley Orgánica 3/2018, de 5 de diciembre,
        de Protección de Datos Personales y garantía de los derechos digitales.
      </p>

      <div className="table">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2} textAlign="center">
                Información básica sobre protección de datos
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <strong>Responsable:</strong>
              </Table.Cell>
              <Table.Cell>ESPABROK CORREDURIA DE SEGUROS, S.A.</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <strong>Finalidad:</strong>
              </Table.Cell>
              <Table.Cell>
                Realizar la gestión para los asociados, aseguradora,
                administrativa, contable y fiscal, así como enviarle
                comunicaciones comerciales sobre nuestros productos y/o
                servicios contratados.{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <strong>Derechos:</strong>
              </Table.Cell>
              <Table.Cell>
                Tiene derecho a acceder, rectificar y suprimir los datos, así
                como otros derechos, indicados en la información adicional.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <strong>Procedencia:</strong>
              </Table.Cell>
              <Table.Cell>El propio interesado.</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <div className="section-title">
        Información completa sobre Protección de Datos
      </div>

      <div className="title">
        1. ¿Quién es el responsable del tratamiento de sus datos?
      </div>
      <p>
        ESPABROK CORREDURIA DE SEGUROS, SA.
        <br />
        CIF: A79162087
        <br />
        C/ MENORCA, N.º 3, 3º 3ª - 28009 - MADRID.
        <br />
        913147230 / asesoriajuridica@espabrok.es
      </p>
      <p>
        <strong>
          Datos de contacto del Delegado de Protección de Datos (DPD):
        </strong>
        <br />
        C/ Irlanda, 7, Local, 08030 - BARCELONA
        <br />
        dpd@allins4b.com
      </p>

      <div className="title">
        2. ¿Con qué finalidad tratamos sus datos personales?
      </div>
      <p>
        En ESPABROK CORREDURIA DE SEGUROS, SA tratamos la información que nos
        facilitan los asociados con el fin de realizar la gestión aseguradora,
        administrativa, contable y fiscal, así como enviarle comunicaciones
        comerciales sobre nuestros productos y/o servicios. Tratamiento
        necesario para el cumplimiento de las obligaciones derivadas de los
        contratos.
      </p>

      <div className="title">
        3. ¿Por cuánto tiempo conservaremos sus datos?
      </div>
      <p>
        Durante 5 años o cuando ya no sean necesarios para la finalidad para la
        cual se recogieron.
      </p>

      <div className="title">
        4. ¿Cuál es la legitimación para el tratamiento de sus datos?
      </div>
      <p>
        Le indicamos la base legal para el tratamiento de sus datos: Ley34/2002,
        de 11 de julio, de Servicios de la Sociedad de la Información y Comercio
        Electrónico; la Ley 56/2007 de 28 de diciembre de Medidas de Impulso a
        la Sociedad de la Información; el Real decreto legislativo 1/2007, de 16
        de noviembre, por el cual se aprueba el Texto Refundido de la Ley
        General de Defensa de los Consumidores y Usuarios.
      </p>
      <p>
        Consentimiento del interesado: REGLAMENTO (UE) 2016/679, de 27 de abril
        de 2016 del Parlamento Europeo, y del Consejo relativo a la Protección
        de las personas físicas en lo que respecta al tratamiento de sus datos
        personales y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de
        Datos Personales y garantía de los derechos digitales.
      </p>
      <p>
        Ley 26/2006, de 17 de julio, de mediación de seguros y reaseguros
        privados.
      </p>
      <p>
        Tratamientos cuya legitimación se basa en el cumplimiento de
        obligaciones legales contenidas en la normativa vigente en materia de
        Prevención del Blanqueo de Capitales y de la Financiación del
        Terrorismo, en la de la Protección del Cliente de Servicios de Mediación
        de Seguros.
      </p>
      <p>
        Para la cesión de los datos a las Entidades Aseguradoras y/o otras
        Sociedades de Correduría, ya sean del Grupo de la CORREDURIA o ajenos a
        esta, para la emisión de los contratos de seguros pertinentes y las
        correctas prestaciones derivadas de los mismos se basan en el
        consentimiento. Los datos de carácter personal solicitados deben ser
        facilitados obligatoriamente para que puedas acceder a los referidos
        servicios, por considerarse necesarios para su mantenimiento y
        cumplimiento
      </p>

      <div className="title">
        5. ¿A qué destinatarios se comunicarán sus datos?
      </div>
      <p>No se cederán datos a terceros, salvo obligación legal.</p>

      <div className="title">6. Transferencias de datos a terceros países</div>
      <p>No están previstas transferencias de datos a terceros países.</p>

      <div className="title">
        7. ¿Cuáles son sus derechos cuando nos facilita sus datos?
      </div>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si estamos
        tratando, o no, datos personales que les conciernan.
      </p>
      <p>
        Las personas interesadas tienen derecho a acceder a sus datos
        personales, así como a solicitar la rectificación de los datos inexactos
        o, en su caso, solicitar su supresión cuando, entre otros motivos, los
        datos ya no sean necesarios para los fines que fueron recogidos.
        Igualmente tiene derecho a la portabilidad de sus datos.
      </p>
      <p>
        En determinadas circunstancias, los interesados podrán solicitar la
        limitación del tratamiento de sus datos, en cuyo caso únicamente los
        conservaremos para el ejercicio o la defensa de reclamaciones.
      </p>
      <p>
        En determinadas circunstancias y por motivos relacionados con su
        situación particular, los interesados podrán oponerse al tratamiento de
        sus datos. En este caso se dejará de tratar los datos, salvo por motivos
        legítimos imperiosos, o el ejercicio o la defensa de posibles
        reclamaciones.
      </p>
      <p>
        Cuando se realice el envío de comunicaciones comerciales utilizando como
        base jurídica el interés legítimo del responsable, el interesado podrá
        oponerse al tratamiento de sus datos con ese fin.
      </p>
      <p>
        Si ha otorgado su consentimiento para alguna finalidad concreta, tiene
        derecho a retirar el consentimiento otorgado en cualquier momento, sin
        que ello afecte a la licitud del tratamiento basado en el consentimiento
        previo a su retirada.
      </p>
      <p>
        En caso de que sienta vulnerados sus derechos en lo concerniente a la
        protección de sus datos personales, especialmente cuando no haya
        obtenido satisfacción en el ejercicio de sus derechos, puede presentar
        una reclamación ante la Autoridad de Control en materia de Protección de
        Datos competente a través de su sitio web: www.agpd.es.
      </p>

      <div className="title">8. ¿Cómo hemos obtenido sus datos?</div>
      <p>
        Los datos personales que tratamos proceden de: El propio interesado.
      </p>
      <p>Las categorías de datos que se tratan son:</p>
      <ul>
        <li>Datos identificativos.</li>
        <li>Direcciones postales y electrónicas.</li>
        <li>Información comercial.</li>
      </ul>
      <p>
        Se podrán tratar categorías especiales de datos personales (son aquellos
        datos que revelen el origen étnico o racial, las opiniones políticas,
        las convicciones religiosas o filosóficas, o la afiliación sindical,
        datos genéticos, datos biométricos dirigidos a identificar de manera
        unívoca a una persona física, datos relativos a la salud o datos
        relativos a la vida sexual o la orientación sexual de una persona
        física) para el cumplimiento del servicio contratado.
      </p>
    </PrivacyPolicyStyled>
  );
};

export default PrivacyPolicy;
