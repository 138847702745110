import format from "date-fns/format";

export const formatDatetime = (dtSource, hasTime = true, hasDate = true) => {
  if (!dtSource) return null;

  return format(
    dtSource,
    `${hasDate ? "dd/MM/yyyy" : ""}${hasDate && hasTime ? " " : ""}${
      hasTime ? "HH:mm" : ""
    }`
  );
};

export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat("es-ES").format(number);
};

export const formatCurrency = (amount = 0) => {
  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
};

export const objectValues = (obj) => Object.keys(obj).map((v) => obj[v]);

export const flattenObject = (obj, prefix = "") =>
  Object.keys(obj).reduce((t, k) => {
    const item = obj[k];
    const element =
      item && typeof item === "object"
        ? flattenObject(item, `${prefix}${k}/`)
        : [`${prefix}${k}: ${item}`];
    return [...t, ...element];
  }, []);

export const toSnakeCase = (str) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/\s+/g, "_");

export const detectIE = () => {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  var edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
};

// Reads the data in a file and returns it using promises.
export const readFile = (file, output = "text", encoding = "UTF-8") => {
  return new Promise((resolve, reject) => {
    if (!file) reject(new Error("No se encuentra el fichero"));

    var reader = new FileReader();
    reader.onload = (fileContent) => {
      resolve(fileContent.target.result);
    };
    reader.onerror = (e) => {
      reject(e.target.error);
    };
    if (output === "text") reader.readAsText(file, encoding);
    else if (output === "urldata") reader.readAsDataURL(file);
    else if (output === "binary") reader.readAsBinaryString(file);
    else if (output === "arraybuffer") reader.readAsArrayBuffer(file);
    else reject(new Error("Formato de salida de fichero desconocido"));
  });
};

export const extractUrlParameters = (urlParametersStr) => {
  if (!urlParametersStr) return {};

  const cleanUrlParametersStr = ["#", "?"].includes(urlParametersStr.charAt(0))
    ? urlParametersStr.slice(1)
    : urlParametersStr;

  return cleanUrlParametersStr.split("&").reduce((t, urlParameter) => {
    const [name, value] = urlParameter.split("=");
    t[name] = value;
    return t;
  }, {});
};
