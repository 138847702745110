import React, { Component } from "react";
import { Image, Input } from "semantic-ui-react";
import styled from "styled-components";

import googleIcon from "../images/icon-google.svg";

const HeaderSearcherContainer = styled.div`
  position: relative;
  width: 60%;
  & .searcher-input input {
    padding-left: 45px !important;
  }
  & .searcher-google-icon {
    width: 20px;
    position: absolute !important;
    z-index: 300;
    margin: 0.6rem 0 0 1rem;
  }
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

class HeaderSearcher extends Component {
  state = {
    googleSearch: "",
  };

  globalSearcherRef = React.createRef();

  componentDidMount() {
    this.globalSearcherRef.current.focus();
  }

  handleChangeGoogleSearch = (e, { value }) => {
    this.setState({ googleSearch: value });
  };

  handleGoogleSearchKeyUp = ({ keyCode }) => {
    if (keyCode === 13) {
      this.setState({ googleSearch: "" });
      window.open(
        `https://google.es/search?q=${this.state.googleSearch}&oq=${this.state.googleSearch}`,
        "_blank"
      );
    }
  };

  render() {
    const { googleSearch } = this.state;

    return (
      <HeaderSearcherContainer>
        <Image src={googleIcon} className="searcher-google-icon" />
        <Input
          ref={this.globalSearcherRef}
          className="searcher-input"
          fluid
          icon="arrow right"
          placeholder="Buscar en la Web"
          value={googleSearch}
          onChange={this.handleChangeGoogleSearch}
          onKeyUp={this.handleGoogleSearchKeyUp}
        />
      </HeaderSearcherContainer>
    );
  }
}

export default HeaderSearcher;
