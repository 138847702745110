import React, { Component } from "react";
import { List } from "semantic-ui-react";

class SaludAsisa extends Component {
  render() {
    return (
      <div>
        <strong>PRODUCTO CENTRALIZADO</strong>:
        <List bulleted>
          <List.Item>
            Colectivo 20886: Miembros de la Red Espabrok (socios, empleados,
            colaboradores) de las corredurías y sus familiares directos. +
            Dental Plus
          </List.Item>
          <List.Item>
            Colectivo 22588: Clientes de la red Espabrok asegurados actualmente
            a través de otras compañías de salud.
          </List.Item>
        </List>
        Con copago:
        <List bulleted>
          <List.Item>8€ RMN/TAC/PET</List.Item>
          <List.Item>9€ Psicoterapia/Sesión</List.Item>
        </List>
        <br />
        <br />
        <strong>PRIMAS NETAS</strong>:
        <List bulleted>
          <List.Item>
            Ver en los documentos información sobre las primas.
          </List.Item>
        </List>
        <br />
        <br />
        <strong>CONDICIONES DE CONTRATACIÓN</strong>
        <List bulleted>
          <List.Item>Edad máxima de contratación: 64 años.</List.Item>
          <List.Item>No se admiten menores solos.</List.Item>
          <List.Item>
            No contratable en: Cantabria, Navarra y Menorca.
          </List.Item>
          <List.Item>
            Bonificación por pronto pago:
            <List bulleted>
              <List.Item>1,33% Forma Semestral</List.Item>
              <List.Item>3,33% Forma Anual</List.Item>
            </List>
          </List.Item>
        </List>
        <br />
        <strong>OPERATIVA DE CONTRATACIÓN</strong>: Remitir a Pedro Torres{" "}
        <a href="mailto:salud@espabrok.es">salud@espabrok.es</a> los siguientes
        documentos:
        <List bulleted>
          <List.Item>
            Solicitud y cuestionario médico por ambas caras.
          </List.Item>
          <List.Item>Fotocopia DNI.</List.Item>
          <List.Item>
            Para justificar procedencia: último recibo y tarjeta de la compañía
            actual.
          </List.Item>
          <List.Item>IGP firmado por el cliente.</List.Item>
          <List.Item>
            Justificante de que el cliente ha recibido las notas informativas
            del producto a contratar (DIP o NI).
          </List.Item>
          <List.Item>
            Certificado de pertenencia al colectivo (solo colectivo 20886).
          </List.Item>
        </List>
        <br />
        <strong>
          PAUTAS PARA LA COMPLIMENTACION DEL CUESTIONARIO Y LA SOLICITUD
        </strong>
        :
        <ol style={{ lineHeight: "17px" }}>
          <li>
            <strong>
              Contestar todas las preguntas y campos, en toda la documentación.
            </strong>
          </li>
          <li>
            <strong>
              Exponer el producto a contratar en la parte superior de la
              solicitud.
            </strong>
          </li>
          <li>
            Si en alguna de las preguntas del cuestionario se declara alguna
            respuesta positiva, por favor es{" "}
            <u>
              necesario exponer detalladamente la patología o medicación junto
              con año de ocurrencia y secuelas de la misma.
            </u>
          </li>
          <li>
            En la pregunta 5, si se declara alguna respuesta positiva,{" "}
            <u>
              describir la patología en fragmento de texto libre del final de la
              pregunta. Aclarar año de ocurrencia y secuelas
            </u>
            .
          </li>
          <li>
            Para todas aquellas <u>patologías</u> tipo operaciones o
            tratamientos, acaecidos <u>hace menos de 5 años</u>, se debe{" "}
            <u>aportar último informe médico.</u>
          </li>
          <li>
            Para <u>niños menores de 14 años</u> informe actual del pediatra
            (informe donde el médico refleja el historial médico del niño)
          </li>
          <li>
            Para <u>recién nacidos: informe de alta hospitalaria</u>
          </li>
        </ol>
        <strong>IMPORTANTE</strong>
        <p>
          Toda la documentación aportada debe ser legible. Toda la documentación
          que carezca de todos los campos rellenos será rechaza. La compañía
          solo valora lo que está expuesto en la documentación oficial.
        </p>
        <p>
          La <u>solicitud y cuestionario</u> tienen una <u>validez</u>, desde su
          firma, <u>un mes</u>.
        </p>
      </div>
    );
  }
}

export default SaludAsisa;
