import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { COLOR } from "../../globals";

class ComunidadesPlusUltra extends Component {
  render() {
    const blueTextStyle = { color: COLOR };
    return (
      <div>
        <strong style={blueTextStyle}>
          Producto Descentralizado. Exclusivo de Comunidades Espabrok.
        </strong>{" "}
        Este producto es una evolución sobre el “Comunidades Óptima Comunes” y
        el “Comunidades Elite Privativas” y ha sido diseñado conjuntamente con
        los <strong>especialistas de Espabrok</strong> para ofrecer un
        <strong>producto completo y competitivo.</strong>
        <br />
        <br />
        <strong>OPERATIVA DE CONTRATACION,</strong> Online, a través de la
        página Web de la compañía. Entra para ver las novedades que te
        ofrecemos.
        <List bulleted>
          <List.Item>Variedad de coberturas</List.Item>
          <List.Item>Contratación flexible</List.Item>
          <List.Item>Comunidad o propietario único</List.Item>
        </List>
        <br />
        <strong style={blueTextStyle}>Ventajas del producto:</strong>
        <List bulleted>
          <List.Item>
            <strong>DAÑOS ATMOSFÉRICOS:</strong>
            <List bulleted>
              <List.Item>
                Filtraciones por terraza y fachada: límite 5.000€.
              </List.Item>
              <List.Item>
                RC por daños filtraciones lluvia: límite 1.200.000€
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            <strong>DAÑOS POR AGUA EN TUBERIAS COMUNITARIAS:</strong>
            <List bulleted>
              <List.Item>
                RC aguas comunitarias (tuberías de distribución y evacuación)
                límite 1.200.000€.
              </List.Item>
              <List.Item>
                Gastos desatasco tuberías comunitarias por riesgo. Limite 750€.
              </List.Item>
              <List.Item>Servicio de desatasco preventivo.</List.Item>
            </List>
          </List.Item>
          <List.Item>
            <strong>DAÑOS POR AGUA EN TUBERÍAS PRIVATIVAS. </strong>
          </List.Item>
          <List.Item>
            <strong>GASTOS DERIVADOS DE LOS SINIESTROS:</strong>
            <List bulleted>
              <List.Item>Gastos de extinción o aminoración.</List.Item>
              <List.Item>Gastos de demolición y desescombro.</List.Item>
              <List.Item>Gastos desalojo forzoso.</List.Item>
              <List.Item>Pérdida de alquileres.</List.Item>
            </List>
          </List.Item>
          <List.Item>
            <strong>GASTOS RESTITUCIÓN ESTÉTICA AMPLIADOS:</strong> hasta
            10.000€
          </List.Item>
          <List.Item>
            <strong>DAÑOS ELECTRICOS</strong>
          </List.Item>
          <List.Item>
            <strong>ROBO, HURTO Y ATRACO</strong>
          </List.Item>
          <List.Item>
            <strong>ROTURA PLACAS SOLARES.</strong>
          </List.Item>
          <List.Item>
            <strong>RC:</strong> mejora en límites.
          </List.Item>
        </List>
        <br />
        <br />
        <strong style={blueTextStyle}>SERVICIOS DEL PRODUCTO:</strong>
        <List bulleted>
          <List.Item>
            <strong>ASISTENCIA COMUNIDADES.</strong>
          </List.Item>
          <List.Item>
            <strong>SERVICIO DE PLAGAS.</strong>
          </List.Item>
          <List.Item>
            <strong>ASESORAMIENTO TECNICO DE EDIFICIOS.</strong>
          </List.Item>
          <List.Item>
            <strong>SERVICIO TELEFONICO DE ADMINISTRACIÓN DE FINCAS.</strong>
          </List.Item>
          <List.Item>
            <strong>RECLAMACION Y DEFENSA JURIDICA BASICA Y AMPLIA.</strong>
          </List.Item>
        </List>
        <br />
        <br />
        <i>
          Esperamos que este nuevo producto sirva para contribuir a la
          consecución de tus objetivos, que también son los nuestros.
        </i>
      </div>
    );
  }
}

export default ComunidadesPlusUltra;
