import React, { Component } from "react";
import { List } from "semantic-ui-react";

class TurismoactivoMdc extends Component {
  render() {
    return (
      <div>
        <strong>TARGET</strong>:
        <br />
        <br />
        <p>
          Dirigida a las empresas que organizan actividades para sus clientes
          y/o usuarios y que tienen como protagonista la naturaleza. Ejemplo.
          Alpinismo, Barranquismo, Rafting, etc.
        </p>
        <br />
        <strong>COBERTURAS</strong>:
        <List bulleted>
          <List.Item>
            <strong>Responsabilidad civil.</strong>
            <List.List>
              <List.Item>
                Explotación y Patronal. Desde 600.000 € hasta 1.500.000 €.
              </List.Item>
            </List.List>
          </List.Item>
          <List.Item>
            <strong>Accidentes.</strong>
            <List.List>
              <List.Item>Fallecimiento.</List.Item>
              <List.Item>Invalidez.</List.Item>
              <List.Item>Asistencia sanitaria.</List.Item>
              <List.Item>Rescate (10.000 €).</List.Item>
            </List.List>
          </List.Item>
        </List>
        <br />
        <strong>COMISIONES</strong>:
        <List bulleted>
          <List.Item>NUEVA PRODUCCIÓN: 20 %.</List.Item>
          <List.Item>CARTERA: 20 %.</List.Item>
        </List>
        <br />
        <strong>OPERATIVA DE CONTRATACIÓN (Producto Centralizado)</strong>:
        <br />
        <br />
        <p>
          Enviar la siguiente información a Alejandro Conde{" "}
          <a href="mailto:alejandro.conde@espabrok.com">
            alejandro.conde@espabrok.com
          </a>
        </p>
      </div>
    );
  }
}

export default TurismoactivoMdc;
