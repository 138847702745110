import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Confirm } from "semantic-ui-react";

export const ButtonWithConfirm = ({
  confirmHeader,
  confirmContent,
  onAccept,
  onCancel,
  onClick,
  ...restProps
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowConfirm = () => {
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    onAccept();
  };

  const handleCancel = () => {
    setShowConfirm(false);
    onCancel();
  };

  return (
    <>
      <Button onClick={handleShowConfirm} {...restProps} />
      <Confirm
        open={showConfirm}
        {...(confirmHeader ? { header: confirmHeader } : {})}
        content={confirmContent}
        confirmButton={{ content: "Aceptar", color: "blue" }}
        cancelButton="Cancelar"
        dimmer="blurring"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </>
  );
};

ButtonWithConfirm.propTypes = {
  confirmHeader: PropTypes.string,
  confirmContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

ButtonWithConfirm.defaultProps = {
  onAccept: () => null,
  onCancel: () => null,
};
