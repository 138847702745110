import React from "react";
import { List, Header } from "semantic-ui-react";

const ProtocoloEmpresasAllianz = () => {
  return (
    <React.Fragment>
      <List bulleted>
        <List.Item>
          <strong>Nueva producción.</strong>
        </List.Item>
        <List.Item>Riesgos en España solamente.</List.Item>
        <List.Item>
          <strong>Duración: Anual.</strong>
        </List.Item>
        <List.Item>
          Allianz se reserva la facultad de limitar{" "}
          <strong>su participación en cada Riesgo</strong> según políticas de
          suscripción.
        </List.Item>
      </List>
      <Header as="h3">
        COBERTURAS: Variaciones respecto a nuestro condicionado standard.
      </Header>
      <List bulleted>
        <List.Item>
          <strong>VALOR DE REPOSICIÓN A NUEVO</strong>, SIN TENER EN CUENTA UN
          VALOR REAL INFERIOR AL 40% PARA EDIFICACIÓN Y AJUAR.
        </List.Item>
        <List.Item>
          <strong>INCREMENTO GASTOS DERIVADOS:</strong>
          <List bulleted>
            <List.Item>PROFESIONALES TÉCNICOS HASTA 60.000 EUROS.</List.Item>
            <List.Item>PERITOS INCLUIDOS SINIESTROS CONSORCIABLES.</List.Item>
            <List.Item>HASTA 60.000 EUROS.</List.Item>
            <List.Item>HASTA 20% DE EDIFICACIÓN, AJUAR Y MERCANCIAS.</List.Item>
          </List>
        </List.Item>
        <List.Item>
          <strong>BIENES DE CLIENTES Y/O VISITANTES</strong>, INCREMENTO A 1.000
          EUROS CLILENTE / VISITANTES Y 12.000 EUROS SINIESTRO / AÑO.
        </List.Item>
        <List.Item>
          <strong>ROBO</strong> ELEMENTOS DE LA EDIFICACIÓN INCREMENTO A{" "}
          <strong>15.000 EUROS</strong>.
        </List.Item>
        <List.Item>
          <strong>OBRAS MENORES</strong> CON LÍMITE DE{" "}
          <strong>300.000 EUROS</strong> POR SINIESTRO Y AÑO.
        </List.Item>
        <List.Item>
          <strong>INHABITABILIDAD O DESALOJO FORZOSO</strong> LÍMITE{" "}
          <strong>200.000 EUROS</strong> POR SINIESTRO Y AÑO.
        </List.Item>
        <List.Item>
          <strong>ROTURA DE CRISTALES</strong>, CON LÍMITE POR SINIESTRO/AÑO/
          VALOR UNITARIO <strong>10.000 EUROS</strong>.
        </List.Item>
      </List>
      <Header as="h3">INCORPORACIÓN NUEVAS GARANTÍAS</Header>
      <List bulleted>
        <List.Item>
          <strong>SETENTA Y DOS HORAS.</strong>
        </List.Item>
        <List.Item>
          <strong>BIENES PERSONAL DE LA EMPRESA.</strong>
        </List.Item>
        <List.Item>
          <strong>
            MERCANCÍAS INDISTINTAS EN CUALQUIERA DE LAS SITUACIONES, HASTA EL
            20%.
          </strong>
        </List.Item>
        <List.Item>
          <strong>
            BIENES EN SITUACIONES INDETERMINADAS, HASTA 150.000 EUROS.
          </strong>
        </List.Item>
        <List.Item>
          <strong>BIENES PROPIEDAD DE TERCEROS.</strong>
        </List.Item>
        <List.Item>
          <strong>NUEVAS ADQUISICIONES, LÍMITE 600.000 EUROS.</strong>
        </List.Item>
        <List.Item>
          <strong>CIMENTACIONES.</strong>
        </List.Item>
        <List.Item>
          <strong>
            INFRASEGURO/DEROGACIÓN REGLA PROPORCIONAL HASTA 20% DE DIFERENCIA.
          </strong>
        </List.Item>
        <List.Item>
          <strong>EXTENSIÓN DE LUCRO CESANTE A CAUSA DE:</strong>
          <List bulleted>
            <List.Item>ROTURA DE MAQUINARIA.</List.Item>
            <List.Item>DAÑO ELÉCTRICO.</List.Item>
            <List.Item>AVERIA DE EQUIPOS ELECTRÓNICOS.</List.Item>
          </List>
        </List.Item>
      </List>
    </React.Fragment>
  );
};

export default ProtocoloEmpresasAllianz;
