import React from "react";

export const SpaceBetween = ({ wrapper, children, style, ...rest }) => {
  const Wrapper = wrapper || "div";
  return (
    <Wrapper
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...style,
      }}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};
