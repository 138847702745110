import React, { Component } from 'react'
import { List } from 'semantic-ui-react'

class ComunidadesSantalucia extends Component {
  render() {
    return (
      <List bulleted>
        <List.Item>
          <strong>PRODUCTO DESCENTRALIZADO</strong>. En caso de no tener código
          con la Cía. y estar interesado debéis comunicaros con el comercial de
          zona. Encontrareis los contactos en la documentación.
        </List.Item>
        <List.Item>
          COMISIÓN: <strong>35% Np y 25% CA</strong>.
        </List.Item>
        <List.Item>
          <strong>VENTAJAS</strong>:
          <List.List>
            <List.Item>
              La compañía no perita las comunidades, emite sin peritación.
            </List.Item>
            <List.Item>
              En comunidades entre 35 y 50 prima muy competitiva y aceptación
              del riesgo. Solo se aplica franquicia por daños por agua si no hay
              obras de reforma.
            </List.Item>
            <List.Item>
              Comunidades con más de un 20% de estructura de madera se acepta el
              riesgo.
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          <strong>OPERATIVA DE CONTRATACION,</strong>. Online, a través de la
          página Web de la compañía.
        </List.Item>
        <List.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wwwssl.santalucia.es/cs/CNC.ejecutarEvento?cidPN=1233767479465&cid=1331034004311&c=Page&lang=es&_ga=2.31736118.926341147.1521102774-737145040.1521102774"
          >
            LINK AL TARIFICADOR
          </a>
          .
        </List.Item>
      </List>
    )
  }
}

export default ComunidadesSantalucia
