import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Form } from "semantic-ui-react";

import { objectValues } from "../../utils";

export class DropdownDbList extends PureComponent {
  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    url: PropTypes.string.isRequired,
    apiRequestOptions: PropTypes.object,
  };
  static defaultProps = {
    component: Form.Dropdown,
    apiRequestOptions: {
      responseType: "json",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  };

  state = {
    status: "loading",
    data: [],
  };

  componentDidMount() {
    this.getData(this.props.url, this.props.apiRequestOptions);
  }

  getData = (url, apiRequestOptions) => {
    axios({
      method: "get",
      url,
      ...apiRequestOptions,
    })
      .then((response) => {
        const listObj = objectValues(response.data)[0];
        const list = [
          { key: 0, text: "", value: "" },
          ...Object.keys(listObj).reduce(
            (t, k) => [...t, { key: k, text: listObj[k], value: k.toString() }],
            []
          ),
        ];
        this.setState({
          status: "ok",
          data: list,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ status: "error" });
      });
  };

  render() {
    const {
      component: Component,
      url,
      apiRequestOptions,
      ...rest
    } = this.props;
    const { status, data } = this.state;

    return (
      <Component loading={status === "loading"} options={data} {...rest} />
    );
  }
}
