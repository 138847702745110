import React from "react";
import { List, Table, Header } from "semantic-ui-react";

const TRCConstruccionAllianz = () => {
  return (
    <React.Fragment>
      <Header as="h3">¿Qué tipo de riesgos se cubren?</Header>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell colSpan="2">Tipo de bienes</Table.HeaderCell>
            <Table.HeaderCell>Período construcción máx.</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Suma asegurada máx.: 3 mill €</Table.Cell>
            <Table.Cell>
              <List bulleted>
                <List.Item>Edificación.</List.Item>
                <List.Item>Reformas.</List.Item>
                <List.Item>Naves industriales.</List.Item>
              </List>
            </Table.Cell>
            <Table.Cell>
              <List bulleted>
                <List.Item>Máx. 11 Alturas y 3 sótanos.</List.Item>
                <List.Item>Sin afectar a estructuras.</List.Item>
                <List.Item>Luz máx. 30 m.</List.Item>
              </List>
            </Table.Cell>
            <Table.Cell>24 meses</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> </Table.Cell>
            <Table.Cell colSpan="2">
              <List bulleted>
                <List.Item>Obras de urbanización.</List.Item>
                <List.Item>Canalizaciones.</List.Item>
                <List.Item>Trabajos de pavimentación.</List.Item>
              </List>
            </Table.Cell>
            <Table.Cell>12 meses</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Header as="h3">Coberturas y límites</Header>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Coberturas</Table.HeaderCell>
            <Table.HeaderCell>Límites</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <List bulleted>
                <List.Item>
                  Bienes en Construcción:
                  <List.List>
                    <List.Item>
                      Riesgos convencionales:
                      <List.List>
                        <List.Item>
                          Incendio, explosión y caída del rayo.
                        </List.Item>
                        <List.Item>Robo y expoliación.</List.Item>
                        <List.Item>
                          Caída de aeronaves, choque de vehículos e impactos.
                        </List.Item>
                      </List.List>
                    </List.Item>
                    <List.Item>
                      Riesgos de fuerza mayor o de la naturaleza.
                    </List.Item>
                    <List.Item>
                      Consecuencias de error de diseño, defectos de materiales,
                      mano de obra defectuosa.
                    </List.Item>
                    <List.Item>
                      Cualquier otra causa accidental e imprevisible no excluida
                      expresamente en póliza.
                    </List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  Gastos derivados de un siniestro:
                  <List.List>
                    <List.Item>
                      Gastos de extinción, intervención de bomberos.
                    </List.Item>
                    <List.Item>
                      Salvamento, traslado, depósito de bienes.
                    </List.Item>
                    <List.Item>Desembarre y vertido de lodos.</List.Item>
                  </List.List>
                </List.Item>
              </List>
            </Table.Cell>
            <Table.Cell>100 %</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Gastos de Demolición, desescombro y desgüace.
            </Table.Cell>
            <Table.Cell>10% s.a. (máx.300.000 €)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Trabajos Extras y Transporte urgente.</Table.Cell>
            <Table.Cell>20% s.a. (máx.300.000 €)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Honorarios Profesionales.</Table.Cell>
            <Table.Cell>15% s.a. (máx.150.000 €)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Político Social.</Table.Cell>
            <Table.Cell>15% s.a. (máx.600.000 €)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Terrorismo.</Table.Cell>
            <Table.Cell>20% s.a. (máx.600.000 €)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Prevención y disminución de pérdidas.</Table.Cell>
            <Table.Cell>15% s.a. (máx.300.000 €)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Periodo de Mantenimiento (amplio).</Table.Cell>
            <Table.Cell>12 meses</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Efectos personales.</Table.Cell>
            <Table.Cell>Máx.15.000€</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Bienes Preexistentes.</Table.Cell>
            <Table.Cell>20% s.a.</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Equipos de construcción.</Table.Cell>
            <Table.Cell>Máx.60.000 €(1º riesgo)</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Maquinaria de construcción.</Table.Cell>
            <Table.Cell>Opcional</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Cl.72h.</Table.Cell>
            <Table.Cell>Incluido</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Cobertura automática.</Table.Cell>
            <Table.Cell>20%</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Prórroga</Table.Cell>
            <Table.Cell>
              a 6 meses (prorrata) &gt; 6 meses (caso a caso)
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Header as="h3">¿Qué tipo de riesgos se cubren?</Header>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tasa neta (&#8240;)</Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
            <Table.HeaderCell>Franquicias</Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Tipo de Riesgo</Table.HeaderCell>
            <Table.HeaderCell>Tasa (&#8240;)</Table.HeaderCell>
            <Table.HeaderCell>Riesgo</Table.HeaderCell>
            <Table.HeaderCell>Franquicia (€)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Edificación</Table.Cell>
            <Table.Cell>0,52</Table.Cell>
            <Table.Cell>General</Table.Cell>
            <Table.Cell>450 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Reformas</Table.Cell>
            <Table.Cell>0,70</Table.Cell>
            <Table.Cell>Robo</Table.Cell>
            <Table.Cell>20%sin, mín.450, máx.5.000</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Naves Industriales</Table.Cell>
            <Table.Cell>0,65</Table.Cell>
            <Table.Cell>.Nat./P.Mant/B.Preex.</Table.Cell>
            <Table.Cell>3.000 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Ob.Urban./Pavimentación</Table.Cell>
            <Table.Cell>1,0</Table.Cell>
            <Table.Cell>Maq.construcción</Table.Cell>
            <Table.Cell>1.500 €</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Canalizaciones</Table.Cell>
            <Table.Cell>1,1</Table.Cell>
            <Table.Cell> </Table.Cell>
            <Table.Cell> </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default TRCConstruccionAllianz;
