import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Header, Image } from "semantic-ui-react";
import styled from "styled-components";

import CloudFilesExplorer from "../CloudFilesExplorer";

import { COLOR } from "../../globals";

const ProductHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 1.2rem 0;
  margin: 2rem 0;
  & .product-header-logo {
    margin-right: 0;
    width: auto;
    height: 45px;
  }
  & .product-header-text {
    margin-top: 1rem;
    margin-left: 0;
    text-align: center;
  }
  & .product-header-text .product-header-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: ${COLOR};
    margin-bottom: 0.4rem;
  }
  & .product-header-text .product-header-subtitle {
    font-size: 1.1rem;
    font-weight: bold;
    color: #666;
  }
  @media (min-width: 768px) {
    flex-flow: row nowrap;
    padding: 1.6rem 0;
    & .product-header-logo {
      margin-right: 1rem;
      width: auto;
      height: 45px;
    }
    & .product-header-text {
      margin-top: 0;
      margin-left: 1rem;
      text-align: left;
    }
    & .product-header-text .product-header-title {
      font-size: 1.8rem;
    }
    & .product-header-text .product-header-subtitle {
      font-size: 1.3rem;
    }
  }
`;

const ProductDataContainer = styled.div`
  & .documents-container {
    min-height: 250px;
    margin-top: 2.6rem;
  }
  @media (min-width: 1500px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    & .product-info-container {
      flex: 0 1 auto;
      width: 55%;
      &.full {
        width: 100%;
      }
    }
    & .divider {
      flex: 0 1 auto;
      align-self: stretch;
      border-left: 1px solid #ddd;
      margin-left: 2rem;
    }
    & .documents-container {
      flex: 1 1 auto;
      min-height: auto;
      margin-top: 0;
      margin-left: 2rem;
      & * {
        font-size: 0.9rem;
      }
    }
  }
`;

class ProductPage extends Component {
  static propTypes = {
    productData: PropTypes.object,
  };
  static defaultProps = {
    productData: {},
  };

  render() {
    const { logo, name, productType, folder } = this.props.productData;

    return (
      <Fragment>
        <ProductHeader>
          <Image src={logo} className="product-header-logo" />
          <div className="product-header-text">
            <div className="product-header-title">{productType}</div>
            <div className="product-header-subtitle">
              <strong>{name}</strong>
            </div>
          </div>
        </ProductHeader>
        <ProductDataContainer>
          <div className={`product-info-container${folder ? "" : " full"}`}>
            {this.props.children}
          </div>
          {folder && (
            <>
              <div className="divider" />
              <div className="documents-container">
                <Header as="h3" className="documents-header">
                  Documentos
                </Header>
                <CloudFilesExplorer baseFolder={`Productos/${folder}/`} />
              </div>
            </>
          )}
        </ProductDataContainer>
      </Fragment>
    );
  }
}

export default ProductPage;
