import React, { Component } from "react";
import { List } from "semantic-ui-react";

class RcBerkley extends Component {
  render() {
    return (
      <div>
        <strong>MEJORAS PARA ESPABROK</strong>:
        <List bulleted>
          <List.Item>
            Única clave de Intermediario que disfruta en Berkley de un
            precotizado de RC Profesional.
          </List.Item>
          <List.Item>
            Posibilidad aseguradora para asesorías, gestorías, oficinas
            administrativas y empresas tecnológicas hasta un máximo de
            facturación de 1.500.000 €.
          </List.Item>
          <List.Item>Fácil y rápida cotización y emisión.</List.Item>
          <List.Item>
            Límite de cobertura a elegir hasta un máximo de 2.000.000 €.
          </List.Item>
          <List.Item>
            Inhabilitación profesional, Infidelidad de empleados, RC Protección
            de datos (LOPD), Daños a expedientes y documentos.
          </List.Item>
        </List>
        <br />
        <strong>COMISIÓN</strong>: 25%.
        <br />
        <br />
        <br />
        <strong>CONTRATACIÓN</strong>:
        <br />
        <br />
        Producto centralizado, remisión de la solicitud/precotizado a Alejandro
        Conde,{" "}
        <a href="mailto:alejandro.conde@espabrok.com">
          alejandro.conde@espabrok.com
        </a>
        .
        <br />
      </div>
    );
  }
}

export default RcBerkley;
