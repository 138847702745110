import React, { Component } from "react";
import { Link } from "@reach/router";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { Tab, Dropdown, Icon, Button, Header, Popup } from "semantic-ui-react";
import styled from "styled-components";

import { withApiContext } from "../Context";
import WidgetLinkButton from "./WidgetLinkButton";
import { GenericLoader } from "../UI";

import { MAILMESSAGESREFRESH, COLOR } from "../../globals";

// https://webmail.espabrok.es/?_task=mail&_action=show&_uid=21&_mbox=INBOX&_extwin=1

const KNOWNFOLDERS = {
  INBOX: "Bandeja de entrada",
  "INBOX.Sent": "Enviados",
  "INBOX.Drafts": "Borradores",
  "INBOX.Spam": "Spam",
  "INBOX.Trash": "Papelera",
};

/* IMAP */
const ImapContainer = styled.div`
  height: 300px;
  padding: 1rem 0.4rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }
`;
const ImapMailboxes = styled.div`
  flex: 0 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0.4rem;
  & #mailboxes-as-select {
    display: block;
  }
  & #mailboxes-as-list {
    display: none;
  }
  @media (min-width: 768px) {
    max-width: 30%;
    margin-right: 0.6rem;
    & #mailboxes-as-select {
      display: none;
    }
    & #mailboxes-as-list {
      display: block;
      flex: 1 1 auto;
      border-top: 1px solid #999;
      padding-top: 0.2rem;
    }
  }
`;
const ImapMailbox = styled.div`
  background: ${(props) => (props.isSelected ? "#ccc" : undefined)};
  font-weight: ${(props) => (props.isSelected ? "bold" : "normal")};
  padding: 0.5rem 1rem 0.5rem 1rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    background: #eee;
  }
`;
const ImapMessages = styled.div`
  flex: 1 1 0%;
  font-size: 0.95rem;
  overflow-y: auto;
`;
const ImapMessagesList = styled.div``;
const ImapMessageListItem = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 0.4rem 1rem 0.4rem 1.6rem;
  margin-right: 0.4rem;
  &:hover {
    cursor: pointer;
    background: #eee;
  }
  & .first-line {
    display: flex;
    justify-content: space-between;
  }
  & .first-line .from {
    font-size: 0.95rem;
    font-weight: bold;
  }
  & .first-line .date {
    color: #aaa;
  }
  & .subject {
  }
  & .content {
    color: #aaa;
  }
`;
const ImapMessage = styled.div`
  padding: 0 0.6rem 0 0;
  & .message-header {
    background: #eee;
    padding: 0.8rem 0.8rem 0.8rem 1rem;
  }
  & .message-header .message-header-buttons {
    text-align: right;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.6rem;
    margin-bottom: 0.6rem;
  }
  & .message-header .message-header-content {
  }
  & .message-header .message-header-content .first-line {
    display: block;
  }
  & .message-header .message-header-content .first-line .from {
    font-weight: bold;
  }
  & .message-header .message-header-content .first-line .date {
    color: #999;
  }
  & .message-header .message-header-content .to span {
    color: #999;
    margin-left: 0.4rem;
  }
  & .message-header .message-header-content .subject {
    font-size: 0.95rem;
  }
  & .message-content {
    padding: 1rem;
  }
  @media (min-width: 768px) {
    padding: 0 0.6rem;
    & .message-header .message-header-content .first-line {
      display: flex;
      justify-content: space-between;
    }
  }
`;

class Imap extends Component {
  state = {
    noAuthData: false,
    isLoadingMailboxes: true,
    mailboxes: [],
    selectedMailbox: null,
    isLoadingMessages: false,
    messages: [],
    selectedMessage: null,
  };

  messagesRefreshInterval = null;

  componentDidMount() {
    this.loadMailboxes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedMailbox !== this.state.selectedMailbox) {
      this.setState({ isLoadingMessages: true });
      this.loadMessages(this.state.selectedMailbox);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.messagesRefreshInterval);
  }

  handleSelectMailbox = (e, { value }) => {
    if (this.state.selectedMailbox === value && this.state.selectedMessage)
      this.setState({ selectedMessage: null });
    else this.setState({ selectedMailbox: value, selectedMessage: null });
  };

  handleSelectMessage = (messageNumber) => {
    this.loadMessage(this.state.selectedMailbox, messageNumber);
  };

  handleCloseMessage = () => {
    this.setState({ selectedMessage: null });
  };

  loadMailboxes = () => {
    const { getRequest } = this.props.api;

    getRequest(`/mailboxes`)
      .then(({ data }) => {
        this.setState({
          isLoadingMailboxes: false,
          mailboxes: data.data,
          selectedMailbox: data.data[0].name,
          selectedMessage: null,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingMailboxes: false,
          noAuthData:
            error.response &&
            error.response.data &&
            error.response.data.code === 403
              ? true
              : false,
        });
      });
  };

  loadMessages = (mailbox) => {
    // In case is a mailbox change, cancel the next automatic refresh.
    clearTimeout(this.messagesRefreshInterval);

    this.props.api
      .getRequest(`/mailboxes/${mailbox}/messages`)
      .then(({ data }) => {
        this.setState({
          isLoadingMessages: false,
          messages: data.data,
          // selectedMessage: null,
        });

        // Set the timeout for the next automatic refresh.
        this.messagesRefreshInterval = setTimeout(
          () => this.loadMessages(this.state.selectedMailbox),
          MAILMESSAGESREFRESH * 1000
        );
      })
      .catch((error) => {
        this.setState({ isLoadingMessages: false });
      });
  };

  loadMessage = (mailbox, messageCount) => {
    const { getRequest } = this.props.api;

    this.setState({ isLoadingMessages: true });
    getRequest(`/mailboxes/${mailbox}/messages/${messageCount}`)
      .then(({ data }) => {
        this.setState({
          isLoadingMessages: false,
          selectedMessage: data.data,
        });
      })
      .catch((error) => {
        this.setState({ isLoadingMessages: false });
      });
  };

  render() {
    const {
      noAuthData,
      isLoadingMailboxes,
      mailboxes,
      selectedMailbox,
      isLoadingMessages,
      messages,
      selectedMessage,
    } = this.state;

    if (isLoadingMailboxes)
      return (
        <ImapContainer>
          <GenericLoader />
        </ImapContainer>
      );

    if (noAuthData)
      return (
        <ImapContainer
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
          }}
        >
          <Header as="h3" textAlign="center" icon>
            <Icon name="exclamation" />
            Error de autenticación en Webmail
            <Header.Subheader>
              Revisa <Link to="/perfil">tu perfil</Link> y verifica que tu
              nombre de usuario y contraseña de Webmail son correctos
            </Header.Subheader>
          </Header>
        </ImapContainer>
      );

    return (
      <ImapContainer>
        <ImapMailboxes>
          <Button
            fluid
            as="a"
            href={`https://webmail.espabrok.es/?_task=mail&_action=compose&_extwin=1`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#fff",
              background: COLOR,
              fontWeight: "normal",
              marginBottom: "0.4rem",
              padding: "0.8rem 0",
            }}
            icon="add"
            content="Redactar correo"
          />
          <div />
          <div id="mailboxes-as-list">
            {mailboxes.map((mailbox) => (
              <Popup
                key={mailbox.name}
                size="tiny"
                content={
                  KNOWNFOLDERS[mailbox.name] ||
                  mailbox.name.replace("INBOX.", "")
                }
                trigger={
                  <ImapMailbox
                    isSelected={mailbox.name === selectedMailbox}
                    onClick={(e) =>
                      this.handleSelectMailbox(e, { value: mailbox.name })
                    }
                  >
                    <Icon
                      name="folder"
                      style={{
                        color:
                          mailbox.name === selectedMailbox ? undefined : "#999",
                      }}
                    />{" "}
                    {KNOWNFOLDERS[mailbox.name] ||
                      mailbox.name.replace("INBOX.", "")}
                  </ImapMailbox>
                }
              />
            ))}
          </div>
          <div id="mailboxes-as-select">
            <Dropdown
              fluid
              selection
              style={{ marginBottom: "1rem", zIndex: "500" }}
              options={mailboxes.map((mailbox) => ({
                key: mailbox.name,
                value: mailbox.name,
                icon: "folder",
                text: mailbox.name,
              }))}
              value={selectedMailbox}
              onChange={this.handleSelectMailbox}
            />
          </div>
        </ImapMailboxes>
        <ImapMessages>
          {isLoadingMessages ? (
            <GenericLoader />
          ) : selectedMessage ? (
            <ImapMessage>
              <div className="message-header">
                <div className="message-header-buttons">
                  <Button
                    as="a"
                    href={`https://webmail.espabrok.es/?_task=mail&_action=show&_uid=${selectedMessage.number}&_mbox=INBOX&_extwin=1`}
                    target="_blank"
                    rel="noopener noreferrer"
                    compact
                    size="small"
                    color="grey"
                    icon="window restore"
                    content="Abrir en nueva pestaña"
                  />
                  <Button
                    compact
                    size="small"
                    color="grey"
                    icon="close"
                    style={{ marginRight: "0" }}
                    content="Cerrar"
                    onClick={this.handleCloseMessage}
                  />
                </div>
                <div className="message-header-content">
                  <div className="first-line">
                    <div className="from">
                      {selectedMessage.from.name ||
                        selectedMessage.from.address ||
                        "Sin remitente"}
                    </div>
                    <div className="date">
                      {selectedMessage.date
                        ? format(
                            parseISO(selectedMessage.date.date),
                            "dd/MM/yyyy HH:mm"
                          )
                        : "Sin fecha"}
                    </div>
                  </div>
                  <div className="subject">{selectedMessage.subject}</div>
                  <div className="to">
                    Para:
                    <span>
                      {selectedMessage.to.length > 0
                        ? selectedMessage.to
                            .map((v) => v.name || v.address)
                            .join(", ")
                        : "Sin destinatarios"}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="message-content"
                dangerouslySetInnerHTML={{
                  __html: selectedMessage.html
                    ? selectedMessage.html.replace(/javascript|script/gi, "")
                    : selectedMessage.text
                    ? selectedMessage.text
                        .replace(/javascript|script/gi, "")
                        .replace(/\n/g, "<br/>")
                    : '<div align="center"><i>El mensaje está vacío</i></div>',
                }}
              />
            </ImapMessage>
          ) : !messages || messages.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              Esta carpeta no contiene mensajes
            </div>
          ) : (
            <ImapMessagesList>
              {messages.map((message, i) => (
                <ImapMessageListItem
                  key={message.id + i}
                  onClick={() => this.handleSelectMessage(message.number)}
                >
                  <div className="first-line">
                    <div>
                      {message.seen === false && (
                        <Icon
                          name="circle"
                          color="blue"
                          style={{
                            marginLeft: "-1.45rem",
                            marginRight: "0.4rem",
                          }}
                        />
                      )}
                      <span className="from">
                        {message.from.name || "Sin remitente"}
                      </span>
                    </div>
                    <div className="date">
                      {message.date
                        ? format(
                            parseISO(message.date.date),
                            "dd/MM/yyyy HH:mm"
                          )
                        : "Sin fecha"}
                    </div>
                  </div>
                  <div className="subject">{message.subject}</div>
                </ImapMessageListItem>
              ))}
            </ImapMessagesList>
          )}
        </ImapMessages>
      </ImapContainer>
    );
  }
}

class WidgetMail extends Component {
  panes = [
    {
      menuItem: { key: "mail", content: "Webmail" },
      render: () => (
        <Tab.Pane style={{ paddingBottom: "1rem" }}>
          <Imap api={this.props.api} />
        </Tab.Pane>
      ),
    },
  ];

  render() {
    const { id, ...restProps } = this.props;
    return (
      <div style={{ position: "relative" }} id={id}>
        <WidgetLinkButton
          link="https://webmail.espabrok.es"
          text="Ir a Webmail"
          icon="mail"
        />
        <Tab panes={this.panes} {...restProps} />
      </div>
    );
  }
}

export default withApiContext(WidgetMail);
